import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../services/firebase';
import { withRouter } from 'react-router-dom';
import { Col, Row, Table, Tabs } from 'antd';
import Search from 'antd/lib/input/Search';
import { IoIosAddCircleOutline } from 'react-icons/io';
import moment from 'moment';
import { BsExclamationTriangle, BsFilter } from 'react-icons/bs';
import '../jsa.css';
import '../../users/groups/groups.css';
import { isEmpty } from '../../../utils/activity-logs';
import loader from '../../../assets/imgs/loader.gif';
import { checkWritePermissions } from '../../../utils/native';

const { TabPane } = Tabs;
class JASListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'losSafety'),
			safetyForms: [],
			archivedForms: [],
			selectedTab: 1,
			isLoading: false,
			columns: [
				{
					title: 'Title',
					dataIndex: 'title',
					key: 'title'
				},
				{
					title: 'Assigned To',
					dataIndex: 'assignedTo',
					key: 'assignedTo'
				},
				{
					title: 'Created By',
					dataIndex: 'createdBy',
					key: 'createdBy',
					render: (_, record) => !isEmpty(record.createdBy) && this.getCreatedByUser(record.createdBy)
				},
				{
					title: 'Administered By',
					dataIndex: 'administeredBy',
					key: 'administeredBy',
					render: (_, record) => !isEmpty(record.administeredBy) && this.avatarFunction(record)
				},
				{
					title: 'Date Created',
					dataIndex: 'createdAt',
					key: 'createdAt',
					render: (createdAt) => createdAt && moment(createdAt).format('MMM DD, YYYY')
				},
				{
					title: 'View',
					dataIndex: 'view',
					key: 'view',
					render: (_, record) => record && (
						<p className="table-action-link box-link" onClick={() => this.props.history.push(`/jsaList/${record.id}`)}>View</p>
					)
				}
			],
			admins: [],
			users: [],
			searchedUsers: [],
			searchInput: ''
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'losSafety') });
		}
	}

	componentDidMount() {
		this.getAllUsers();
	}

	getAllUsers() {
		this.setState({ isLoading: true });
		const db = firebase.firestore();
		const users = db.collection('users');
		users.get().then((querySnapshot) => {
			let temp = [], adminList = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					let data = doc.data();
					if (data.userType === 'admin') {
						adminList.push(data);
						temp.push(data);
					}
					if (data.userType === 'LOS') {
						temp.push(data);
					}
				}
			});
			this.setState({ users: temp, admins: adminList });
			this.getSafetyForms();
		});
	}

	getSafetyForms() {
		const db = firebase.firestore();
		const safetyFormsRef = db.collection('safetyForm').orderBy('createdAt', 'desc');
		safetyFormsRef.get().then((querySnapshot) => {
			let temp = [];
			let archived = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					let data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
					if (data.archive === true) {
						archived.push(data);
					}
				}
			});
			this.setState({ safetyForms: temp, archivedForms: archived, isLoading: false });
		});
	}

	onChange = (key) => {
		this.setState({ selectedTab: parseInt(key) });
	};

	userHeader = () => {
		const { isPermitted } = this.state;
		return (
			<>
				<Row className="search-rig-header">
					<Col span={4}>
						<button className="site-btn rounded">
							<BsFilter size={40} />
						</button>
					</Col>
					<Col span={20} className="search-btn-part">
						<Search
							placeholder="Search"
							onSearch={value => this.safetyFormSearch(value)}
							style={{ width: '100%' }}
							className="rigSearch"
							allowClear={true}
							onChange={data => this.safetyFormSearch(data.target.value)}
						/>
						<button className="site-btn export-btn">Export</button>
						{isPermitted &&
							<button className="site-btn addusers-btn" onClick={() => this.props.history.push('/jsaList/add')}>
								<IoIosAddCircleOutline />
								<span>Add new</span>
							</button>
						}
					</Col>
				</Row>
			</>
		);
	}

	safetyFormSearch(value) {
		const { selectedTab, safetyForms, archivedForms } = this.state;
		let searched = [];
		if (selectedTab === 1) {
			searched = safetyForms;
		}
		if (selectedTab === 2) {
			searched = archivedForms;
		}
		let string = value.toLowerCase();
		let temp = [];

		for (let i = 0; i < searched.length; i++) {
			let data = searched[i].title.toLowerCase().match(string);
			if (data !== null) {
				temp.push(searched[i]);
			}
		}
		this.setState({ searchedUsers: temp, searchInput: value, loaded: true });
	}

	getCreatedByUser = (createdBy) => {
		const { admins } = this.state;
		let userIndex = admins.map(admin => admin && admin.uid).indexOf(createdBy);
		if(userIndex !== -1) {
			return admins[userIndex].firstName + ' ' + admins[userIndex].lastName;
		}
	}

	avatarFunction(record) {
		const administeredUsers = this.getAdministeredUsers(record);
		if (!isEmpty(administeredUsers)) {
			return (
				<div className='avatar-table-part customed-avatar'>
					{administeredUsers.map((administeredUser, index) => {
						return (
							index < 3 ?
								<div className='avatar-table' key={index.toString()}>{administeredUser}</div> :
								index === 3 &&
								<div className='avatar-table number' key={index.toString()}>+ {administeredUsers.length - 3}</div>
						);
					})
					}
				</div>
			);
		} else {
			return null;
		}
	}

	getAdministeredUsers = (record) => {
		const { admins } = this.state;
		let administeredUsers = [];
		record.administeredBy.map((administered) => {
			let userIndex = admins.map(admin => admin.uid).indexOf(administered);
			if (admins[userIndex] !== undefined) {
				administeredUsers.push(admins[userIndex].firstName[0].toUpperCase() + admins[userIndex].lastName[0].toUpperCase());
			}
			return administeredUsers;
		});
		return(administeredUsers);
	}

	jsaLists(safetyLists) {
		const { columns } = this.state;
		return (
			<div>
				<Table dataSource={safetyLists} columns={columns} rowKey={(record, index) => record.$key + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
				</Table>
			</div>
		);
	}

	render() {
		const { safetyForms, archivedForms, selectedTab, isLoading, searchedUsers, searchInput } = this.state;

		if (isLoading === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
		return (
			<>
				<div className="warning-headline no-mb">
					<BsExclamationTriangle style={{ fontSize: '30px' }} />
					<h4 className="no-mb">Safety Forms</h4>
				</div>
				<div className="card-tab-body">
					<div className="custom-tabs no-mt">
						<Tabs type="card" defaultActiveKey="1" onChange={this.onChange}>
							<TabPane tab={`Active (${safetyForms.length})`} key="1">
								{this.userHeader()}
								{selectedTab === 1 && searchedUsers.length === 0 && searchInput === '' ? this.jsaLists(safetyForms) : this.jsaLists(searchedUsers)}
							</TabPane>
							<TabPane tab={`Archived (${archivedForms.length})`} key="2">
								{this.userHeader()}
								{selectedTab === 2 && searchedUsers.length === 0 && searchInput === '' ? this.jsaLists(archivedForms) : this.jsaLists(searchedUsers)}
							</TabPane>
						</Tabs>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(JASListing));