import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Input, Row, Col, DatePicker, Table } from 'antd';
import './home.css';
import 'antd/dist/antd.css';
import firebase from '../../services/firebase';
import Moment from 'react-moment';
import isLoading from '../../assets/imgs/loader.gif';
// import rig from '../rig/rig';
import * as moment from 'moment';
import profile from '../../assets/imgs/add-users-icon.png';
import crown from '../../assets/imgs/add-admin-icon.png';
import rigImage from '../../assets/imgs/rig.png';
// import document from '../../assets/imgs/document.png';
import { Link } from 'react-router-dom';


const { Search } = Input;
const { Column } = Table;

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataSource: [
				{
					key: '0',
					name: '',
					age: '',
					address: '',
				},
				{
					key: '1',
					name: 'Edward King 1',
					age: '32',
					address: 'London, Park Lane no. 1',
				},
			],
			count: 2,
			//rigs
			rigs: [],
			scheduled: [],
			completed: [],
			active: [],
			loaded: false,
			startDate: '',
			date: '',
			//report
			reportList: [],
			reportLoad: false,
			from: '',
			to: '',
			activityLogs: [],
			isAdmin: localStorage.getItem('admin') === 'true' ? true : false,
			user: []
		};
		this.columns = [
			{
				title: 'name',
				dataIndex: 'name',
				width: '30%',
				editable: true,
			},
			{
				title: 'age',
				dataIndex: 'age',
				editable: true
			},
			{
				title: 'address',
				dataIndex: 'address',
				editable: true
			},
		];

	}

	componentDidMount() {
		this.getUsers();
		/* Commented out this as below function we are already calling on getUsers*/
		// this.getRigs();
		this.getReports();
		this.getActivityLogs();
	}

	getUsers() {
		const db = firebase.firestore();
		const rigs = db.collection('users');
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					temp.push(doc.data());
				}
			});
			this.setState({
				users: temp
			});
			this.getRigs(false);
		});
	}

	async getRigs() {
		const db = firebase.firestore();
		const rigsRef = db.collection('rigs').orderBy('updatedAt', 'desc');
		const querySnapshot = await rigsRef.get();

		const temp = [], s = [], c = [], a = [];
		querySnapshot.forEach((doc) => {
			if (doc.exists) {
				const data = doc.data();
				data.$key = doc.id;
				if (data.archive !== true) {
					temp.push(data);
					if (data.status === 'Scheduled') {
						s.push(data);
					} else if (data.status === 'Completed') {
						c.push(data);
					} else if (data.status === 'Active') {
						a.push(data);
					}
				}
			}
		});
		this.setState({ rigs: temp, scheduled: s, completed: c, active: a, loaded: true });
	}

	getReports() {

		const database = firebase.firestore();
		const rigs = database.collection('rigReports').orderBy('generatedOn', 'desc').limit(12);
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			this.setState({
				reportList: temp, reportLoad: true
			});
		});
	}

	rigSearch(value) {
		const { rigs } = this.state;
		const string = value.toLowerCase();
		const s = [], c = [], a = [];

		for (let i = 0; i < rigs.length; i++) {
			const data = rigs[i].name.toLowerCase().match(string) || rigs[i].number.toLowerCase().match(string);

			if (data !== null) {
				if (rigs[i].status === 'Scheduled') {
					s.push(rigs[i]);
				} else if (rigs[i].status === 'Completed') {
					c.push(rigs[i]);
				} else if (rigs[i].status === 'Active') {
					a.push(rigs[i]);
				}
			}
		}
		this.setState({ scheduled: s, completed: c, active: a, loaded: true });
	}

	searchRigs(rigsRef) {
		rigsRef.get().then((querySnapshot) => {
			const s = [], c = [], a = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.status === 'Scheduled') {
						s.push(data);
					} else if (data.status === 'Completed') {
						c.push(data);
					} else if (data.status === 'Active') {
						a.push(data);
					}
				}
			});
			this.setState({ scheduled: s, completed: c, active: a, loaded: true });
		});
	}

	onChange(date) {

		this.setState({ startDate: moment(date).valueOf() });

		if (date !== null) {
			const db = firebase.firestore();
			const rigsRef = db.collection('rigs').where('updatedAt', '>=', moment(date).valueOf()).orderBy('updatedAt', 'desc');
			this.searchRigs(rigsRef);
		} else {
			this.getRigs();
		}
	}

	onChangeDate(date) {

		if (this.state.startDate < moment(date).valueOf()) {
			this.setState({ endDate: moment(date).valueOf() });
		}

		if (date !== null) {
			const db = firebase.firestore();
			const rigsRef = db.collection('rigs').where('updatedAt', '>=', this.state.startDate).where('updatedAt', '<=', moment(date).valueOf()).orderBy('updatedAt', 'desc');
			this.searchRigs(rigsRef);
		}

		if (date === null && this.state.startDate === null) {
			this.getRigs();
		}
	}

	reportSearch(value) {
		if (value !== '') {
			const { reportList } = this.state;
			const string = value.toLowerCase();
			const temp = [];

			for (let i = 0; i < reportList.length; i++) {
				const data = reportList[i].title !== undefined && reportList[i].title.toLowerCase().match(string);

				if (data !== null && Array.isArray(data)) {
					temp.push(reportList[i]);
				}
			}
			this.setState({ reportList: temp });
		} else {
			this.getReports();
		}
	}

	searchReports(reportRef) {

		reportRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					temp.push(data);
				}
			});
			this.setState({
				reportList: temp, reportLoad: true
			});
		});
	}

	fromDate(date) {
		this.setState({ from: moment(date).format('YYYY-MM-DD') });
		if (date !== null) {
			const database = firebase.firestore();
			const reportRef = database.collection('rigReports').where('date', '>=', moment(date).format('YYYY-MM-DD'));
			this.searchReports(reportRef);
		} else {
			this.getReports();
		}
	}

	toDate(date) {
		if (this.state.from < moment(date).format('YYYY-MM-DD')) {
			this.setState({ to: moment(date).format('YYYY-MM-DD') });
		}
		if (date !== null) {
			const database = firebase.firestore();
			const reportRef = database.collection('rigReports').where('date', '>=', this.state.from).where('date', '<=', moment(date).format('YYYY-MM-DD'));
			this.searchReports(reportRef);
		}

		if (date === null && this.state.from === null) {
			this.getReports();
		}
	}

	getActivityLogs() {
		const db = firebase.firestore();
		const activityRef = db.collection('activity-logs').orderBy('createdAt', 'desc').limit(20);
		activityRef.get().then((querySnapshot) => {
			const temp = [];
			const time = moment().subtract(3, 'day').valueOf();
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.createdAt > time) {
						temp.push(data);
						this.getUserDetails(data);
					}
				}
			});
			this.setState({ activityLogs: temp });
		});
	}

	getUserDetails(data) {
		const { users } = this.state;
		const userIndex = users.map(user => user.uid).indexOf(data.uid);
		if (userIndex !== -1) {
			data.updatedByUser = users[userIndex].firstName + ' ' + users[userIndex].lastName;
		}
	}

	viewReport() {
		this.props.history.push('/reports');
	}

	getReportType(report) {
		const type = report.reportType === 'update_report' ? 'Rig Update Report' : report.reportType === 'customer_report' ? 'Customer Rig Report' :
			report.reportType === 'permit_report' ? 'Permit Report' : report.reportType === 'los_report' ? 'LOS Rig Report' :
				report.reportType === 'load_list_report' ? 'Load List Report' : 'Truck Pusher Report';
		return `${type} - `;
	}

	getRigNumberForReport(report) {
		const { rigs } = this.state;
		const index = rigs.map(option => option.$key).indexOf(report.rigId);
		if (index !== -1) {
			return rigs[index].number;
		}
	}

	render() {
		const { scheduled, completed, active, loaded, reportList, reportLoad, activityLogs, isAdmin } = this.state;
		return (
			<div className="mainHomeSection">
				<div className="card-body">
					<h4 className="card-title">Quick Actions</h4>
					{isAdmin === true ?
						<div className="user-action-part">
							<div className="user-boxes">
								<Link to={{ pathname: '/users/los/add' }}>
									{/* <img src={loader} alt="img" /> */}
									<img src={profile} alt="img" />
									<p>Add Users</p>
								</Link>
							</div>
							<div className="user-boxes">
								<Link to={{ pathname: '/users/admin/add' }}>
									{/* <img src={loader} alt="img" /> */}
									<img src={crown} alt="img" />
									<p>Add Admins</p>
								</Link>
							</div>
							<div className="user-boxes">
								<Link to={{ pathname: '/rigs/add' }}>
									{/* <img src={loader} alt="img" /> */}
									<img src={rigImage} alt="img" />
									<p>New Rig</p>
								</Link>
							</div>
						</div>
						:
						<div className="user-action-part">
							<div className="user-boxes">
								<Link to={{ pathname: '/rigs/add' }}>
									{/* <img src={loader} alt="img" /> */}
									<img src={rigImage} alt="img" />
									<p>New Rig</p>
								</Link>
							</div>
						</div>
					}
				</div>

				{/*  -----------------------------   Rigs Details   ----------------------------- */}
				{loaded ?
					<div className={isAdmin === true ? 'rigs-card-part card-body' : 'rigs-card-part card-body'}>
						{/* <h4 className="card-title">Rigs</h4> */}
						<div className="containText">
							<Row className={isAdmin === true ? 'rowContent' : 'rowContent1'}>
								<Col span={12} className={isAdmin === true ? 'searchInput' : 'searchInput1'}>
									<span className="small-title">Search Rigs info:</span>
									<Search
										placeholder="Search..."
										onSearch={value => this.rigSearch(value)}
										className="rigSearch custom-search"
										allowClear={true}
										onChange={data => this.rigSearch(data.target.value)}
									/>
								</Col>
								{/* <Col span={1} className={isAdmin === true ? 'dateLabel' : 'dateLabel1'}>
								</Col> */}
								<Col className="custom-date-picker" span={6} id="DropdownFixPostion">
									<span className="small-title">From:</span>
									<DatePicker
										getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
										onChange={(data) => this.onChange(data)}
										className={['date_picker', isAdmin === false && 'date_picker1']}
									/>
								</Col>
								{/* <Col span={1} className={isAdmin === true ? 'dateLabel' : 'dateLabel1'}>
								</Col> */}
								<Col className="custom-date-picker" span={6} id="DropdownFixPostion">
									<span className="small-title">To:</span>
									<DatePicker
										getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
										onChange={(data) => this.onChangeDate(data)}
										className={['date_picker', isAdmin === false && 'date_picker1']}
									/>
								</Col>
							</Row>
						</div>

						<div className="rigs-info-block">
							<h4 className="card-title shade">Rig Info</h4>
							<Row>
								{scheduled.length >= active.length ?
									<>
										<Col span={12} className="riginfo-boxes border-r-dash">
											<h5 className="card-subtitle">Scheduled</h5>
											{scheduled.map((rig, index) => {
												return (
													index <= 3 &&
													<p className="riginfo-details" key={index.toString()}>
														<Moment format="MMM DD, YYYY">{rig.updatedAt}</Moment>
														<span className="number">{rig.number}</span>
														<span className="name">{rig.name}</span>
													</p>
												);
											})}
										</Col>
										<Col span={12} className="riginfo-boxes">
											<h5 className="card-subtitle">Completed</h5>
											{completed.map((rig, index) => {
												return (
													index <= 3 &&
													<p className="riginfo-details" key={index.toString()}>
														<Moment format="MMM DD, YYYY">{rig.updatedAt}</Moment>
														<span className="number">{rig.number}</span>
														<span className="name">{rig.name}</span>
													</p>
												);
											})}
										</Col>
									</>
									:
									<>
										<Col span={12} className="riginfo-boxes border-r-dash">
											<h5 className="card-subtitle">Scheduled</h5>
											{scheduled.map((rig, index) => {
												return (
													index <= 3 &&
													<p className="riginfo-details" key={index.toString()}>
														<Moment format="MMM DD, YYYY">{rig.updatedAt}</Moment>
														<span className="number">{rig.number}</span>
														<span className="name">{rig.name}</span>
													</p>
												);
											})}
										</Col>
										<Col span={12} className="riginfo-boxes">
											<h5 className="card-subtitle">Completed</h5>
											{completed.map((rig, index) => {
												return (
													index <= 3 &&
													<p className="riginfo-details" key={index.toString()}>
														<Moment format="MMM DD, YYYY">{rig.updatedAt}</Moment>
														<span className="number">{rig.number}</span>
														<span className="name">{rig.name}</span>
													</p>
												);
											})}
										</Col>
									</>
								}
							</Row>
							<Row>
								<>
									<Col span={12} className="riginfo-boxes">
										<h5 className="card-subtitle">Active</h5>
										{active.map((rig, index) => {
											return (
												index <= 3 &&
												<p className="riginfo-details" key={index.toString()}>
													<Moment format="MMM DD, YYYY">{rig.updatedAt}</Moment>
													<span className="number">{rig.number}</span>
													<span className="name">{rig.name}</span>
												</p>
											);
										})}
									</Col>
								</>
							</Row>
						</div>
					</div>
					:
					<div className="loader">
						<img src={isLoading} alt="loader" />
					</div>
				}

				{/*  -----------------------------   Report Details   ----------------------------- */}
				{reportLoad ?
					<div className="report-details-card card-body">
						<div className="containText">
							<Row className="rowContent">
								<Col span={12} className="searchInput">
									<span className="small-title cursorTip">All Rig Reports - <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => this.viewReport()}>Click Here</span></span>
									<Search
										placeholder="Enter title"
										className="rigSearch custom-search"
										allowClear={true}
										onChange={(data) => this.reportSearch(data.target.value)}
									/>
								</Col>
								<Col className="custom-date-picker" span={6} id="DropdownFixPostion">
									<span className="small-title">From:</span>
									<DatePicker
										getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
										onChange={(value) => this.fromDate(value)}
										className="date_picker"
									/>
								</Col>
								<Col className="custom-date-picker" span={6} id="DropdownFixPostion">
									<span className="small-title">To:</span>
									<DatePicker
										getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
										onChange={(value) => this.toDate(value)}
										className="date_picker"
									/>
								</Col>
							</Row>
						</div>
						<div className="most-recent-rig-cards">
							<h4 className="card-title shade">Most Recent Rig Reports</h4>
							<Row>
								{reportList.map((report, index) => {
									return (
										<Col className="boxes" key={index.toString()}>
											<div className="inner-area">
												<h5>{this.getReportType(report) + 'Rig #' + this.getRigNumberForReport(report)}</h5>
												<h5>{report.title}</h5>
												<p><span>Created Date:</span> <Moment format="MMM DD, YYYY">{report.generatedOn}</Moment> @ <Moment format="hh:mm a">{report.generatedOn}</Moment></p>
												{report.endDate ?
													<p><span>Report Date:</span> <Moment format="MMM DD, YYYY">{report.date}</Moment> to <Moment format="MMM DD, YYYY">{report.endDate}</Moment></p>
													:
													<p><span>Report Date:</span> <Moment format="MMM DD, YYYY">{report.date}</Moment></p>
												}
											</div>
										</Col>
									);
								})}
							</Row>
						</div>
					</div>
					:
					<div className="loader">
						<img src={isLoading} alt="loader" />
					</div>
				}


				{/*  -----------------------------   Activity Log   ----------------------------- */}
				{/* {activityLogs.length > 0 && */}
				{reportLoad && loaded &&
					<div className="card-body quickContain">
						<h4 className="card-title no-mb">Activity Logs</h4>
						<p className="card-subtitle">Recent Activity</p>
						<>
							<Table dataSource={activityLogs} rowKey={(record, index) => record.uid + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
								<Column title="Type" dataIndex="type" key="type" />
								<Column title="Name" dataIndex="name" key="name" />
								<Column title="Number" dataIndex="number" key="number" />
								<Column title="Type of Action" dataIndex="type_of_action" key="type_of_action" />
								<Column title="Updated By" dataIndex="updatedByUser" key="updatedByUser" />
								<Column title="Date & Time" dataIndex="time" render={(_, record) => moment(record.createdAt).format('MMM DD, YYYY HH:mm')} />
							</Table>
						</>
					</div>
				}
			</div>
		);
	}
}
export default withRouter(Home);