import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Layout, Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import styles from './styles.css';
import { isEmpty } from '../utils/activity-logs';
import loader from '../assets/imgs/loader.gif';
import { connect } from 'react-redux';
import { checkPermissions } from '../utils/native';
import * as authActions from '../redux/actions/authActions';
import { firestore } from 'firebase';

const { Sider } = Layout;
class SiderLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			isAdmin: localStorage.getItem('admin') === null ? false : true,
			isOwner: localStorage.getItem('owner') === null ? false : true,
			selectedMenu: '',
			currentUser: props.auth.currentUser,
			permissions: props.auth.currentUser.userType !== 'owner' ? props.auth.currentUser.modulePermissions.modules : null
		};
	}

	componentDidMount() {
		const { isOwner } = this.state;
		this.updateSelectedMenu();
		if (isOwner === false) {
			this.userUpdateQuery();
		}
	}

	componentDidUpdate() {
		this.updateSelectedMenu();
	}

	componentWillUnmount() {
		if (!isEmpty(this.unsubscribe)) {
			this.unsubscribe();
		}
	}

	userUpdateQuery = () => {
		let { currentUser } = this.props.auth;
		if (!isEmpty(currentUser)) {
			return new Promise((resolve) => {
				this.unsubscribe = firestore().collection('users').where('uid', '==', currentUser.uid)
					.onSnapshot((querySnapshot) => {
						if (!querySnapshot.empty) {
							this.props.dispatchUserSubscribeStart();
							querySnapshot.forEach((doc) => {
								if (doc.exists && currentUser.$key === doc.id) {
									const data = doc.data();
									data.$key = doc.id;
									this.props.dispatchUser(data);
									this.props.dispatchUserSubscribeSuccess();
									resolve(true);
								}
							});
						}
					});
				resolve(true);
			});
		}
	};

	updateSelectedMenu() {
		let menu1 = this.props.location.pathname.split('/');
		let menu;
		if (menu1.length > 3 && menu1[menu1.length - 2] !== 'edit') {
			menu = menu1[menu1.length - 2];
		} else {
			menu = menu1[1];
		}
		if (menu !== this.state.selectedMenu) {
			this.setState({ selectedMenu: menu });
		}
	}

	onCollapse = () => {
		this.setState({ collapsed: !this.state.collapsed });
		this.setState({ open: !this.state.open });
	}

	handleMenuChange = (value) => {
		this.setState({ selectedMenu: value.keyPath });
	}

	render() {
		const { collapsed, isAdmin, selectedMenu, isOwner, currentUser } = this.state;
		if (isEmpty(currentUser)) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
		return (
			<div>
				<Sider
					width={250}
					breakpoint="lg"
					collapsible
					trigger={null}
					collapsedWidth={70}
					className={`${styles.sidebar_class} sidebar-part sidebar_section`}
					onCollapse={this.onCollapse}
					collapsed={collapsed}
					onBreakpoint={() => {
					}}
				>
					<div className="sidebar-inner-area aside-children-under">
						<Menu className="sidebar-menu" selectedKeys={selectedMenu} onClick={(value) => this.handleMenuChange(value)}>
							{isAdmin &&
								<Menu.Item key="dashboard">
									<NavLink to="/dashboard">
										<Icon type="appstore" className="menuIconStyle" />
										<span className="menuTextStyle">Overview</span>
									</NavLink>
								</Menu.Item>
							}
							{checkPermissions(this.props, 'smartGroups') &&
								<Menu.Item key="groups" >
									<NavLink to="/groups">
										<Icon type="team" className="menuIconStyle" />
										<span className="menuTextStyle">Smart groups</span>
									</NavLink>
								</Menu.Item>
							}
							{checkPermissions(this.props, 'users') &&
								<Menu.Item key="users" >
									<NavLink to="/users">
										<Icon type="user" className="menuIconStyle" />
										<span className="menuTextStyle">Users</span>
									</NavLink>
								</Menu.Item>
							}
							{isOwner &&
								<Menu.Item key="permissions">
									<NavLink to="/permissions">
										<Icon type="setting" className="menuIconStyle" />
										<span className="menuTextStyle">Permissions</span>
									</NavLink>
								</Menu.Item>
							}
							{(selectedMenu === 'next' || selectedMenu === 'optional' || selectedMenu === 'create-report') ?
								checkPermissions(this.props, 'rigs') &&
								<Menu.Item key={selectedMenu}>
									<NavLink to="/rigs">
										<Icon type="team" className="menuIconStyle" />
										<span className="menuTextStyle">Rigs</span>
									</NavLink>
								</Menu.Item>
								:
								checkPermissions(this.props, 'rigs') &&
								<Menu.Item key="rigs">
									<NavLink to="/rigs">
										<Icon type="team" className="menuIconStyle" />
										<span className="menuTextStyle">Rigs</span>
									</NavLink>
								</Menu.Item>
							}
							{(selectedMenu === 'pic') ?
								checkPermissions(this.props, 'pusher') &&
								<Menu.Item key={selectedMenu}>
									<NavLink to="/pics">
										<Icon type="picture" className="menuIconStyle" />
										<span className="menuTextStyle">Rig Pics</span>
									</NavLink>
								</Menu.Item>
								:
								(checkPermissions(this.props, 'rigPics') || checkPermissions(this.props, 'pusher')) &&
								<Menu.Item key="pics">
									<NavLink to="/pics">
										<Icon type="picture" className="menuIconStyle" />
										<span className="menuTextStyle">Rig Pics</span>
									</NavLink>
								</Menu.Item>
							}
							{(selectedMenu === 'los_report' || selectedMenu === 'report') ?
								checkPermissions(this.props, 'reports') &&
								<Menu.Item key={selectedMenu} >
									<NavLink to="/reports">
										<Icon type="copy" className="menuIconStyle" />
										<span className="menuTextStyle">Rig Reports</span>
									</NavLink>
								</Menu.Item>
								:
								checkPermissions(this.props, 'reports') &&
								<Menu.Item key="reports" >
									<NavLink to="/reports">
										<Icon type="copy" className="menuIconStyle" />
										<span className="menuTextStyle">Rig Reports</span>
									</NavLink>
								</Menu.Item>
							}
							{checkPermissions(this.props, 'reports') &&
								<Menu.Item key="pusher" >
									<NavLink to="/pusher">
										<Icon type="copy" className="menuIconStyle" />
										<span className="menuTextStyle">Truck Pusher Reports</span>
									</NavLink>
								</Menu.Item>
							}
							{checkPermissions(this.props, 'losSafety') &&
								<Menu.Item key="jsaList" >
									<NavLink to="/jsaList">
										<Icon type="safety" className="menuIconStyle" />
										<span className="menuTextStyle">Safety Form</span>
									</NavLink>
								</Menu.Item>
							}
							{checkPermissions(this.props, 'losSafety') &&
								<Menu.Item key="jsaReport" >
									<NavLink to="/jsaReport">
										<Icon type="safety" className="menuIconStyle" />
										<span className="menuTextStyle">Safety Report</span>
									</NavLink>
								</Menu.Item>
							}
							{checkPermissions(this.props, 'assets') &&
								<Menu.Item key="assets" >
									<NavLink to="/assets">
										<Icon type="picture" className="menuIconStyle" />
										<span className="menuTextStyle">Assets</span>
									</NavLink>
								</Menu.Item>
							}
							{/* <Menu.Item key="load_list" >
							<NavLink to="/load_list">
								<Icon type="picture" className="menuIconStyle" />
								<span className="menuTextStyle">Load List</span>
							</NavLink>
							</Menu.Item> */}
							{checkPermissions(this.props, 'individualLoads') &&
								<Menu.Item key="individual_truck_loads">
									<NavLink to="/individual_truck_loads">
										<Icon type="copy" className="menuIconStyle" />
										<span className="menuTextStyle">Individual Truck Loads</span>
									</NavLink>
								</Menu.Item>
							}
							{checkPermissions(this.props, 'reports') &&
								<Menu.Item key="load_report" >
									<NavLink to="/load_report">
										<Icon type="picture" className="menuIconStyle" />
										<span className="menuTextStyle">Load List Report</span>
									</NavLink>
								</Menu.Item>
							}
						</Menu>
					</div>
					<div className={`${collapsed ? 'sidebar-small' : ''} sidebar-toggle-part`}>
						<button onClick={() => this.onCollapse()} className="sidebar-toggle-btn">
							<Icon type="double-left" />
						</button>
					</div>
				</Sider>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

const mapDispatchToProps = {
	dispatchUser: (data) => authActions.logInSuccess(data),
	dispatchUserSubscribeStart: () => authActions.userSubscribeStart(),
	dispatchUserSubscribeSuccess: () => authActions.userSubscribeSuccess()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SiderLayout));