import React, { Component } from 'react';
import { DatePicker, TimePicker } from 'antd';
import './mobile-preview.css';
class MobileData extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { details } = this.props;
		return (
			<>
				<div className="mobile-field">
					<div className="m-box-header">
						<p className="title">
							{details.required &&
								<span className="require-symbol">*</span>
							}
							{details.label}
							<span className="subtitle">{details.description}</span>
						</p>
					</div>
					<div className="m-box-content">
						{details.dateFormat &&
							<DatePicker format="DD/MMM/YY" placeholder="Set Date" />
						}
						{details.timeFormat &&
							<TimePicker format="HH:mm" placeholder="Set Time" />
						}
					</div>
				</div>
			</>
		);
	}
}
export default MobileData;