import React, { Component } from 'react';
import { Radio } from 'antd';
import './mobile-preview.css';

class MobileYesNo extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { details } = this.props;
		return (
			<>
				<div className="mobile-field">
					<div className="m-box-header">
						<p className="title">
							{details.required &&
								<span className="require-symbol">*</span>
							}
							{details.label}
							<span className="subtitle">{details.description}</span>
						</p>
					</div>
					<div className="m-box-content">
						<Radio.Group className="yes-no-switch" defaultValue="" size="small" buttonStyle="solid">
							<Radio.Button value="yes">{details.startLabel}</Radio.Button>
							<Radio.Button value="no">{details.endLabel}</Radio.Button>
						</Radio.Group>
					</div>
				</div>
			</>
		);
	}
}
export default MobileYesNo;
