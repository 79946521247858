import React, { Component } from 'react';
import { Checkbox } from 'antd';
import './mobile-preview.css';

class MobileDropDown extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { details } = this.props;
		// console.log("details::: ", details);
		return (
			<>
				<div className="mobile-field">
					<div className="m-box-header">
						<p className="title">
							{details.required &&
								<span className="require-symbol">*</span>
							}
							{details.label}
							<span className="subtitle">{details.description}</span>
						</p>
					</div>
					<div className="m-box-content">
						{details.items.map((detail, index) => {
							return (
								<Checkbox key={index} className="custom-checkbox round">{detail.label}</Checkbox>
							);
						})}
					</div>
				</div>
			</>
		);
	}
}
export default MobileDropDown;