import React, { Component } from 'react';
import { Checkbox, Form, Input, InputNumber, Modal, Radio, Spin, Icon } from 'antd';
import { TiDocumentText } from 'react-icons/ti';
import { AiFillPrinter, AiFillPicture, AiFillEdit, AiFillLayout, AiOutlineEnvironment, AiOutlineLine } from 'react-icons/ai';
import './safety-form.css';
import './mobile-preview/mobile-preview.css';
// import IphoneMobile from '../../../assets/imgs/iphone.png';
import IphoneMobile from '../../../assets/imgs/iphone-blackscreen.png';
import DragDropComponent from './drag-drop-component';
import MobileDropDown from '../add-jsa/mobile-preview/mobile-dropdown';
import MobileTask from '../add-jsa/mobile-preview/mobile-task';
import MobileNumber from '../add-jsa/mobile-preview/mobile-number';
import MobileData from '../add-jsa/mobile-preview/mobile-date';
import MobileOpenEnded from '../add-jsa/mobile-preview/mobile-open-ended';
import MobileRating from '../add-jsa/mobile-preview/mobile-rating';
import MobileYesNo from '../add-jsa/mobile-preview/mobile-yes-no';
import MobileImageUpload from '../add-jsa/mobile-preview/mobile-image-upload';
import MobileScanner from '../add-jsa/mobile-preview/mobile-scanner';
import MobileSignature from '../add-jsa/mobile-preview/mobile-signature';
import MobileImageSelection from './mobile-preview/mobile-image-selection';
import MobileFileUpload from '../add-jsa/mobile-preview/mobile-file-upload';
import MobileLocation from '../add-jsa/mobile-preview/mobile-location';
import MobileAudioRecording from '../add-jsa/mobile-preview/mobile-audio-recording';
import MobileNumbersSlider from '../add-jsa/mobile-preview/mobile-numbers-slider';
import { isEmpty } from '../../../utils/activity-logs';
// START - Editor
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import MobileDescription from './mobile-preview/mobile-description';

class FormContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentState: '',
			editorState: '',
			displayModal: isEmpty(props.formTitle) ? true : false,
			formTitle: isEmpty(props.formTitle) ? '' : props.formTitle,
			items: [
				{
					id: '0',
					label: ''
				},
				{
					id: '1',
					label: ''
				}
			],
			deleteModal: false,
			editorModal: false,
			showButton: false,
			isLoading: false,
			deleteIndex: null,
			isUpdating: false
		};
	}

	displayFormInputModal() {
		const { formTitle, formValues } = this.state;
		const { form: { getFieldDecorator }, validateTrigger } = this.props;
		return (
			<Form layout="vertical" className="theme-form">
				<Form.Item label="Title">
					{formTitle === '' ?
						getFieldDecorator('formTitle', {
							validateTrigger: validateTrigger ? 'onChange' : '',
							initialValue: !isEmpty(formValues) ? formValues.formTitle : '',
							rules: [
								{ required: true, message: 'Please enter form value' },
							],
						})(
							<Input />
						)
						:
						getFieldDecorator('formTitle', {
							validateTrigger: validateTrigger ? 'onChange' : '',
							initialValue: !isEmpty(formValues) ? formValues.formTitle : ''
						})(
							<Input />
						)
					}
				</Form.Item>
			</Form>
		);
	}

	handleFormTitleSubmit = (e) => {
		e.preventDefault();
		const { form, handleValidator, getFormTitle } = this.props;
		form.validateFields(async (err, values) => {
			handleValidator(true);
			if (!err) {
				this.setState({ displayModal: false, formTitle: values.formTitle });
				getFormTitle(values.formTitle);
			}
		});
	};


	handleFormTitleClose = () => {
		const { getSelectedObject } = this.props;
		this.setState({ displayModal: false });
		getSelectedObject('selectedObject', '');
		getSelectedObject('selectedTemplate', '');
		getSelectedObject('formAction', 'add');
	};


	handleFormFieldsSubmit = (e) => {
		e.preventDefault();
		const { fieldSelected, documentList, form, addFormField, handleValidator, handleClose, formValues } = this.props;
		const { items } = this.state;
		form.validateFields(async (err, values) => {
			handleValidator(true);
			if (!err) {
				handleClose();
				this.setState({ isLoading: true });
				if (fieldSelected === 'dropdown') {
					let data = {
						type: 'dropdown',
						label: values.label,
						description: values.description,
						required: values.required,
						location: values.location,
						multiple_selection: values.multiple_selection
					};
					for (let i = 0; i < items.length; i++) {
						items[i].label = values[`items${items[i].id}`];
					}
					data.items = items;
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'task') {
					let data = {
						type: 'task',
						label: values.label,
						description: values.description,
						required: values.required,
						location: values.location
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'number') {
					let data = {
						type: 'number',
						label: values.label,
						description: values.description,
						required: values.required,
						location: values.location
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'date') {
					let data = {
						type: 'date',
						label: values.label,
						description: values.description,
						dateFormat: values.dateFormat,
						timeFormat: values.timeFormat,
						required: values.required,
						location: values.location
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'openEnded') {
					let data = {
						type: 'openEnded',
						label: values.label,
						description: values.description,
						required: values.required,
						location: values.location
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'rating') {
					let data = {
						type: 'rating',
						label: values.label,
						description: values.description,
						rateRange: parseInt(values.rateRange),
						required: values.required,
						location: values.location
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'boolean') {
					let data = {
						type: 'boolean',
						label: values.label,
						description: values.description,
						startLabel: values.startLabel,
						endLabel: values.endLabel,
						required: values.required,
						location: values.location
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'imageUpload') {
					let data = {
						type: 'imageUpload',
						label: values.label,
						description: values.description,
						required: values.required,
						location: values.location,
						multipleUploads: values.multipleUploads,
						imageSource: values.imageSource
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'scanner') {
					let data = {
						type: 'scanner',
						label: values.label,
						description: values.description,
						required: values.required,
						location: values.location,
						multipleUploads: values.multipleUploads,
						imageSource: values.imageSource
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'signature') {
					let data = {
						type: 'signature',
						label: values.label,
						description: values.description,
						required: values.required,
						location: values.location
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'imageSelection') {
					let data = {
						type: 'imageSelection',
						label: values.label,
						description: values.description,
						required: values.required,
						location: values.location
					};
					for (let i = 0; i < items.length; i++) {
						items[i].label = values[`items${items[i].id}`];
					}
					data.items = items;
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'fileUpload') {
					let data = {
						type: 'fileUpload',
						label: values.label,
						description: values.description,
						required: values.required,
						location: values.location,
						multipleUploads: values.multipleUploads,
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'location') {
					let data = {
						type: 'location',
						label: values.label,
						description: values.description,
						required: values.required,
						selectBy: values.selectBy
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'numberSlider') {
					let data = {
						type: 'numberSlider',
						label: values.label,
						description: values.description,
						startLabel: values.startLabel,
						endLabel: values.endLabel,
						required: values.required,
						location: values.location
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				if (fieldSelected === 'audioRecording') {
					let data = {
						type: 'audioRecording',
						label: values.label,
						description: values.description,
						required: values.required,
						location: values.location
					};
					if (!isEmpty(formValues)) {
						data.id = formValues.id;
						if (!isEmpty(formValues.documentId)) {
							data.documentId = formValues.documentId;
						}
						documentList[parseInt(formValues.id) - 1] = data;
					} else {
						data.id = (documentList.length + 1).toString();
						documentList.push(data);
					}
					addFormField(documentList);
				}
				form.resetFields();
				this.resetAllItems();
				this.closeLoading();
			}
		});
	};

	resetAllItems = () => {
		let array = [
			{
				id: '0',
				label: ''
			},
			{
				id: '1',
				label: ''
			}
		];
		this.setState({ items: array });
	}

	handleFormFieldsClose = () => {
		const { handleClose } = this.props;
		let initialList = [
			{
				id: '0',
				label: ''
			},
			{
				id: '1',
				label: ''
			}
		];
		this.setState({ items: initialList });
		handleClose();
	}

	handleFormFields = () => {
		const { fieldSelected } = this.props;
		if (fieldSelected === 'dropdown') {
			return (this.handleDropDownField());
		}
		if (fieldSelected === 'task') {
			return (this.handleTaskField());
		}
		if (fieldSelected === 'number') {
			//check
			return (this.handleTaskField());
		}
		if (fieldSelected === 'date') {
			return (this.handleDateField());
		}
		if (fieldSelected === 'openEnded') {
			return (this.handleTaskField());
		}
		if (fieldSelected === 'rating') {
			return (this.handleRatingField());
		}
		if (fieldSelected === 'boolean') {
			return (this.handleBooleanField());
		}
		if (fieldSelected === 'imageUpload') {
			return (this.handleImageUploadField());
		}
		if (fieldSelected === 'scanner') {
			return (this.handleImageUploadField());
		}
		if (fieldSelected === 'signature') {
			return (this.handleTaskField());
		}
		if (fieldSelected === 'imageSelection') {
			return (this.handleImageSelection());
		}
		if (fieldSelected === 'fileUpload') {
			return (this.handleTaskField());
		}
		if (fieldSelected === 'location') {
			return (this.handleLocationField());
		}
		if (fieldSelected === 'numberSlider') {
			return (this.handleSlidersField());
		}
		if (fieldSelected === 'audioRecording') {
			return (this.handleTaskField());
		}
	}

	handleHeaderFormPart = () => {
		const { validateTrigger, fieldSelected, formValues } = this.props;
		const { form: { getFieldDecorator } } = this.props;
		return (
			<>
				<div className="theme-form">
					<Form.Item>
						{getFieldDecorator('label', {
							validateTrigger: validateTrigger ? 'onChange' : '',
							rules: [
								{ required: true, message: 'Please enter field value' },
							],
							initialValue: !isEmpty(formValues) ? formValues.label : ''
						})(
							<Input placeholder={fieldSelected === 'task' ? 'New task' : 'Question'} />
						)}
					</Form.Item>
					{fieldSelected !== 'description' &&
						<Form.Item>
							{getFieldDecorator('description', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								initialValue: !isEmpty(formValues) ? formValues.description : ''
							})(
								<Input placeholder={fieldSelected === 'task' ? 'Task description (optional)' : 'Description (optional)'} />
							)}
						</Form.Item>
					}
				</div>
			</>
		);
	}

	handleFooterFormPart = () => {
		const { fieldSelected, form: { getFieldDecorator }, validateTrigger, formValues } = this.props;
		return (
			<>
				<hr className="field-hr-divider" />
				<div className="field-checkbox">
					<Form.Item>
						{getFieldDecorator('required', {
							validateTrigger: validateTrigger ? 'onChange' : '',
							initialValue: !isEmpty(formValues) ? formValues.required : false,
							valuePropName: 'checked'
						})(
							<Checkbox className="custom-checkbox">Required</Checkbox>
						)}
					</Form.Item>
				</div>
				{fieldSelected !== 'location' &&
					<>
						<div className="field-checkbox">
							<Form.Item>
								{getFieldDecorator('location', {
									validateTrigger: validateTrigger ? 'onChange' : '',
									initialValue: !isEmpty(formValues) ? formValues.location : false,
									valuePropName: 'checked'
								})(
									<Checkbox className="custom-checkbox">Location stamp capture</Checkbox>
								)}
							</Form.Item>
						</div>
					</>
				}
				{fieldSelected === 'dropdown' &&
					<>
						<div className="field-checkbox">
							<Form.Item>
								{getFieldDecorator('multiple_selection', {
									validateTrigger: validateTrigger ? 'onChange' : '',
									initialValue: !isEmpty(formValues) ? formValues.multiple_selection : false,
									valuePropName: 'checked'
								})(
									<Checkbox className="custom-checkbox">Multiple selection</Checkbox>
								)}
							</Form.Item>
						</div>
					</>
				}
				{(fieldSelected === 'imageUpload' || fieldSelected === 'scanner' || fieldSelected === 'fileUpload') &&
					<>
						<div className="field-checkbox">
							<Form.Item>
								{getFieldDecorator('multipleUploads', {
									validateTrigger: validateTrigger ? 'onChange' : '',
									initialValue: !isEmpty(formValues) ? formValues.multipleUploads : false,
									valuePropName: 'checked'
								})(
									<Checkbox className="custom-checkbox">Allow multiple uploads</Checkbox>
								)}
							</Form.Item>
						</div>
					</>
				}
			</>
		);
	}

	handleDropDownField = () => {
		const { form: { getFieldDecorator }, validateTrigger, displayDragItems } = this.props;
		const { items } = this.state;
		return (
			<Form layout="vertical" className="theme-form">
				{this.handleHeaderFormPart()}
				<div className="form-export-part">
					<p className="no-mb"><b>Items</b></p>
					<div className="form-export">
						<p className="no-mb">Sort - Custom</p>
						<p className="no-mb">Export</p>
						<p className="no-mb">import</p>
					</div>
				</div>
				{displayDragItems === true &&
					<DragDropComponent
						items={items}
						formType="field"
						getFieldDecorator={getFieldDecorator}
						validateTrigger={validateTrigger}
						getItemsOrder={(list) => this.setState({ items: list })}
					/>
				}
				<div className="text-right">
					<button className="site-btn add-field" onClick={() => this.addField()}>
						+ Add field
					</button>
				</div>

				{this.handleFooterFormPart()}
			</Form>
		);
	}

	addField = () => {
		const { items } = this.state;
		let oldArray = items;
		oldArray.push({ id: (items.length).toString(), label: '' });
		this.setState({ items: oldArray });
	}

	handleTaskField = () => {
		return (
			<Form layout="vertical" className="theme-form">
				{this.handleHeaderFormPart()}
				{this.handleFooterFormPart()}
			</Form>
		);
	}

	handleDateField = () => {
		const { form: { getFieldDecorator }, validateTrigger, formValues } = this.props;
		return (
			<Form layout="vertical" className="theme-form">
				{this.handleHeaderFormPart()}
				<div className='middleContent'>
					<p><b>Format</b></p>
					<Form.Item>
						{getFieldDecorator('dateFormat', {
							validateTrigger: validateTrigger ? 'onChange' : '',
							valuePropName: 'checked',
							initialValue: !isEmpty(formValues) ? formValues.dateFormat : true
						})(
							<Checkbox className="custom-checkbox">Date</Checkbox>
						)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('timeFormat', {
							validateTrigger: validateTrigger ? 'onChange' : '',
							valuePropName: 'checked',
							initialValue: !isEmpty(formValues) ? formValues.timeFormat : true
						})(
							<Checkbox className="custom-checkbox">Time</Checkbox>
						)}
					</Form.Item>
				</div>
				{this.handleFooterFormPart()}
			</Form>
		);
	}

	handleRatingField = () => {
		const { validateTrigger } = this.props;
		const { form: { getFieldDecorator } } = this.props;
		return (
			<Form layout="vertical" className="theme-form">
				{this.handleHeaderFormPart()}
				<div className='middleContent'>
					<p><b>Choose number of stars</b></p>
					<div className='ratingDiv'>
						<Form.Item>
							{getFieldDecorator('startLabel', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								rules: [
									{ required: true, message: 'Please enter field value' },
								],
								initialValue: 'very bad'
							})(
								<Input />
							)}
						</Form.Item>
						<Form.Item className='rateRange'>
							{getFieldDecorator('rateRange', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								rules: [
									{ required: true, message: 'Please enter field value' },
								],
								initialValue: '3'
							})(
								// <Input suffix={<TiDocumentText />} />
								<InputNumber min={3} />
							)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator('endLabel', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								rules: [
									{ required: true, message: 'Please enter field value' },
								],
								initialValue: 'very good'
							})(
								<Input />
							)}
						</Form.Item>
					</div>
				</div>
				{this.handleFooterFormPart()}
			</Form>
		);
	}

	handleBooleanField = () => {
		const { validateTrigger } = this.props;
		const { form: { getFieldDecorator } } = this.props;
		return (
			<Form layout="vertical" className="theme-form">
				{this.handleHeaderFormPart()}
				<div className="middleContent">
					<p><b>Value</b></p>
					<div className="ratingDiv">
						<Form.Item>
							{getFieldDecorator('startLabel', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								rules: [
									{ required: true, message: 'Please enter field value' },
								],
								initialValue: 'Yes'
							})(
								<Input />
							)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator('endLabel', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								rules: [
									{ required: true, message: 'Please enter field value' },
								],
								initialValue: 'No'
							})(
								<Input />
							)}
						</Form.Item>
					</div>
				</div>
				{this.handleFooterFormPart()}
			</Form>
		);
	}

	handleImageUploadField = () => {
		const { form: { getFieldDecorator }, validateTrigger } = this.props;
		return (
			<Form layout="vertical" className="theme-form">
				{this.handleHeaderFormPart()}
				<div className='middleContent'>
					<p><b>Image source</b></p>
					<div>
						<Form.Item>
							{getFieldDecorator('imageSource', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								initialValue: 'both'
							})(
								<Radio.Group>
									<Radio value='camera'>Camera</Radio>
									<Radio value='gallery'>Gallery</Radio>
									<Radio value='both'>Both</Radio>
								</Radio.Group>
							)}
						</Form.Item>
					</div>
				</div>
				{this.handleFooterFormPart()}
			</Form>
		);
	}

	handleLocationField = () => {
		const { form: { getFieldDecorator }, validateTrigger } = this.props;
		return (
			<Form layout="vertical" className="theme-form">
				{this.handleHeaderFormPart()}
				<div className='middleContent'>
					<p><b>Select by</b></p>
					<div>
						<Form.Item>
							{getFieldDecorator('selectBy', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								initialValue: 'current'
							})(
								<Radio.Group>
									<Radio value='current'>Current location</Radio>
									<Radio value='manual'>Manual input</Radio>
								</Radio.Group>
							)}
						</Form.Item>
					</div>
				</div>
				{this.handleFooterFormPart()}
			</Form>
		);
	}

	handleSlidersField = () => {
		const { validateTrigger, formValues } = this.props;
		const { form: { getFieldDecorator } } = this.props;
		return (
			<Form layout="vertical" className="theme-form">
				{this.handleHeaderFormPart()}
				<p><b>Range of values</b></p>
				<div className="field-range-slider">
					<Form.Item>
						{getFieldDecorator('startLabel', {
							validateTrigger: validateTrigger ? 'onChange' : '',
							rules: [
								{ required: true, message: 'Please enter field value' },
							],
							initialValue: !isEmpty(formValues) ? formValues.startLabel : ''
						})(
							<InputNumber />
						)}
					</Form.Item>
					<p className="devider-text">to</p>
					<Form.Item>
						{getFieldDecorator('endLabel', {
							validateTrigger: validateTrigger ? 'onChange' : '',
							rules: [
								{ required: true, message: 'Please enter field value' },
							],
							initialValue: !isEmpty(formValues) ? formValues.endLabel : ''
						})(
							<InputNumber />
						)}
					</Form.Item>
				</div>
				{this.handleFooterFormPart()}
			</Form>
		);
	}

	handleImageSelection = () => {
		const { validateTrigger, displayDragItems } = this.props;
		const { form: { getFieldDecorator } } = this.props;
		const { items } = this.state;
		return (
			<Form layout="vertical" className="theme-form">
				{this.handleHeaderFormPart()}
				<div className="form-export-part">
					<p className="no-mb"><b>Items</b></p>
					<div className="form-export">
						<p className="no-mb">Sort - Custom</p>
						<p className="no-mb">Export</p>
						<p className="no-mb">import</p>
					</div>
				</div>
				{displayDragItems === true &&
					<DragDropComponent
						items={items}
						formType="image"
						getFieldDecorator={getFieldDecorator}
						validateTrigger={validateTrigger}
						getItemsOrder={(list) => this.setState({ items: list })}
					/>
				}
				<div className="text-right">
					<button className="site-btn add-field" onClick={() => this.addField()}>
						+ Add field
					</button>
				</div>
				{this.handleFooterFormPart()}
			</Form>
		);
	}

	handleMobilePreviewUpdation = () => {
		const { documentList } = this.props;
		const { isLoading } = this.state;
		return (
			<>
				{isLoading === true ?
					<Spin size='large' className='spinner' />
					: documentList.length > 0 && documentList.map((doc, index) => {
						return (
							doc.type === 'description' ? <MobileDescription key={index} details={doc} /> :
								doc.type === 'dropdown' ? <MobileDropDown key={index} details={doc} /> :
									doc.type === 'task' ? <MobileTask key={index} details={doc} /> :
										doc.type === 'number' ? <MobileNumber key={index} details={doc} /> :
											doc.type === 'date' ? <MobileData key={index} details={doc} /> :
												doc.type === 'openEnded' ? <MobileOpenEnded key={index} details={doc} /> :
													doc.type === 'rating' ? <MobileRating key={index} details={doc} /> :
														doc.type === 'boolean' ? <MobileYesNo key={index} details={doc} /> :
															doc.type === 'imageUpload' ? <MobileImageUpload key={index} details={doc} /> :
																doc.type === 'scanner' ? <MobileScanner key={index} details={doc} /> :
																	doc.type === 'signature' ? <MobileSignature key={index} details={doc} /> :
																		doc.type === 'imageSelection' ? <MobileImageSelection key={index} details={doc} /> :
																			doc.type === 'fileUpload' ? <MobileFileUpload key={index} details={doc} /> :
																				doc.type === 'location' ? <MobileLocation key={index} details={doc} /> :
																					doc.type === 'numberSlider' ? <MobileNumbersSlider key={index} details={doc} /> :
																						doc.type === 'audioRecording' ? <MobileAudioRecording key={index} details={doc} /> : null
						);
					})}
			</>
		);
	}
	closeLoading = () => {
		setTimeout(() => {
			this.setState({ isLoading: false });
		}, 2000);
	}

	deleteSelectedField = (index) => {
		this.setState({ deleteModal: !this.state.deleteModal, deleteIndex: index });
	}

	deleteFormField = () => {
		const { documentList, updateFormList } = this.props;
		const { deleteIndex } = this.state;

		this.setState({ isLoading: true });
		documentList.splice((deleteIndex), 1);
		documentList.forEach((item, index) => { return item.id = (index + 1).toString(); });
		updateFormList(documentList);
		this.deleteSelectedField();
		this.closeLoading();
	}

	updateFormField = (value) => {
		const { handleEditFormFields } = this.props;
		if (!isEmpty(value.items)) {
			this.setState({ items: value.items });
		}
		if (value.type === 'description') {
			this.onEditorChange();
			let contentBlock = htmlToDraft(value.label);
			let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			let editorState = EditorState.createWithContent(contentState);
		
			this.setState({ editorState: editorState });
			setTimeout(() => this.setState({ contentState: value.label }), 100);
			// this.onContentStateChange(htmlToDraft(value.label));
		}
		handleEditFormFields(value);
	}

	copyFormField = (value, type) => {
		const { updateFormList, documentList } = this.props;
		this.setState({ isUpdating: true });

		let oldValue = Object.assign({}, value);
		oldValue.id = (parseInt(oldValue.id) + 1).toString();
		if (type === 'other') {
			oldValue.label = oldValue.label + ' (Copy)';
		}
		delete oldValue['documentId'];

		let oldArray = documentList.slice(parseInt(value.id), documentList.length);
		let oldArray2 = documentList.slice(0, parseInt(value.id));
		oldArray2.push(oldValue);

		oldArray = oldArray2.concat(oldArray);
		oldArray = oldArray.filter((obj, index) => obj.id = (index + 1).toString());
		updateFormList(oldArray);
		setTimeout(() => { this.setState({ isUpdating: false }); }, 2);
	}

	onContentStateChange = (contentState) => {
		this.setState({
			contentState: draftToHtml(contentState),
		});
	}

	onEditorChange = () => {
		const { editorModal } = this.state;
		this.setState({ editorModal: !editorModal });
	}

	submitEditorChange = () => {
		const { contentState } = this.state;
		const { formValues, documentList, updateFormList } = this.props;
		let data = {
			type: 'description',
			label: contentState,
			id: '0'
		};
		if (isEmpty(formValues)) {
			this.copyFormField(data, 'description');
		} else {
			documentList[parseInt(formValues.id) - 1]['label'] = contentState;
			updateFormList(documentList);
		}
		this.onEditorChange();
	}

	render() {
		const { displayModal, showButton, deleteModal, editorModal, isUpdating, contentState, editorState } = this.state;
		const { formModal, fieldSelected, documentList, handleFormFields, updateFormList, isDeletePermitted } = this.props;

		return (
			<>
				<div className="card-body safetyform-card-body no-mt">
					<div className="safetyform-field-part">
						<div className="add-field-area">
							<h4 className="card-title text-center">Add a field</h4>
							<button className="description-btn site-btn" onClick={() => this.onEditorChange()}>Description</button>
							<hr className="devider" />
							<div className="fields-area custom-scroll">
								<div className="safetyform-fields">
									<p className="template-btn" onClick={() => handleFormFields('dropdown')}><Icon type="check-square" />Dropdown</p>
									<p className="template-btn" onClick={() => handleFormFields('task')}><Icon type="check-circle" />Task</p>
									<p className="template-btn" onClick={() => handleFormFields('number')}><Icon type="number" />Number</p>
									<p className="template-btn" onClick={() => handleFormFields('date')}><Icon type="calendar" />Date</p>
									<p className="template-btn" onClick={() => handleFormFields('openEnded')}><Icon type="font-size" />Open ended</p>
									<p className="template-btn" onClick={() => handleFormFields('rating')}><Icon type="star" />Rating</p>
									<p className="template-btn" onClick={() => handleFormFields('boolean')}><Icon type="check" />Yes/No</p>
									<p className="template-btn" onClick={() => handleFormFields('imageUpload')}><AiFillPicture />Image upload</p>
								</div>
								{showButton === false &&
									<>
										<div className="safetyform-fields">
											<p className="template-btn" onClick={() => handleFormFields('scanner')}><AiFillPrinter />Scanner</p>
											<p className="template-btn" onClick={() => handleFormFields('signature')}><AiFillEdit />Signature</p>
											<p className="template-btn" onClick={() => handleFormFields('imageSelection')}><AiFillLayout />Image Selection</p>
											<p className="template-btn" onClick={() => handleFormFields('fileUpload')}><Icon type="upload" />File Upload</p>
											<p className="template-btn" onClick={() => handleFormFields('location')}><AiOutlineEnvironment />Location</p>
											<p className="template-btn" onClick={() => handleFormFields('numberSlider')}><AiOutlineLine />Numbers slider</p>
											<p className="template-btn" onClick={() => handleFormFields('audioRecording')}><Icon type="audio" />Audio recording</p>
										</div>
									</>
								}
							</div>
							<button className="show-more-btn site-btn" onClick={() => { this.setState({ showButton: !showButton }); }}>{showButton === false ? 'Show less' : 'Show more'}</button>
							{documentList.length > 0 ?
								<div className="bottom-btn">
									<button className="show-more-btn site-btn" onClick={() => this.props.saveFormContent()}>Save as template</button>
									<button className="show-more-btn site-btn solid" onClick={() => this.props.saveFormContent()}>Save changes</button>
								</div>
								:
								<div className="bottom-btn bottom-btn-disabled">
									<button className="show-more-btn site-btn" disabled={documentList.length === 0}>Save as template</button>
									<button className="show-more-btn site-btn solid" disabled={documentList.length === 0}>Save changes</button>
								</div>
							}
						</div>
						<div className="field-area custom-scroll">
							{documentList.length === 0 &&
								<div className="no-field-lists">
									<TiDocumentText style={{ fontSize: '4em', magin: '10px', color: '#1890ff' }} />
									<h5 className="no-mb">No fields to display</h5>
									<p className="primaryColor"><b>Add your first field</b></p>
								</div>
							}
							{/* {documentList.length > 0 && documentList.map((value, index) => {
								return (
									<div className="field-lists" key={index}>
										<p className="field-index">{index + 1}</p>
										{value.type === 'description' ?
											<p className="field-content">
												<span className="title-label">{value.label}</span>
											</p>
											:
											<p className="field-content">
												<span className="title-label">{value.label}</span>
												<span className="subtexts">{value.type}</span>
											</p>
										}
										<div className="copy-delete-icons">
											<button className="copy-icon">
												<Icon type="copy" />
											</button>
											<button className="delete-icon" onClick={() => this.deleteFormField(index)}>
												<Icon className="error-text" type="delete" />
											</button>
										</div>
									</div>
								);
							})} */}

							{!isUpdating &&
								(documentList.length > 0) &&
								<DragDropComponent
									items={documentList}
									formType="list"
									getItemsOrder={(list) => updateFormList(list)}
									deleteFormField={(index) => this.deleteSelectedField(index)}
									updateFormField={(value) => this.updateFormField(value)}
									copyFormField={(value) => this.copyFormField(value, 'other')}
									isDeletePermitted={isDeletePermitted}
								/>
							}
						</div>
						<div className="iphone-view-area custom-scroll">
							<div className="iphone-content-part">
								<img className="iphone-body" src={IphoneMobile} alt="Iphone Mobile" />
								<div className="mobile-preview-content">
									<div className="mobile-scrolling-area">
										{this.handleMobilePreviewUpdation()}
									</div>
									<div className="mobile-submit-btn">
										<button className="m-btn">Submit</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal centered className="send-mail-modal" title={`${fieldSelected.replace(/([A-Z])/g, ' $1')}`} visible={formModal} onOk={this.handleFormFieldsSubmit} onCancel={this.handleFormFieldsClose} maskClosable={false}>
					{this.handleFormFields()}
				</Modal>

				<Modal
					centered
					width={767}
					className="send-mail-modal"
					title="Description"
					visible={editorModal}
					maskClosable={false}
					onOk={this.submitEditorChange}
					onCancel={this.onEditorChange}
				>
					<>
						{isEmpty(contentState) ?
							<Editor
								editorState={editorState}
								wrapperClassName="editor-part"
								editorClassName="editor-content-area"
								onContentStateChange={this.onContentStateChange}
							/>
							:
							<Editor
								wrapperClassName="editor-part"
								editorClassName="editor-content-area"
								onContentStateChange={this.onContentStateChange}
							/>
						}
						<textarea
							style={{ width: '100%' }}
							disabled
							// value={JSON.stringify(contentState, null, 4)}
							value={!isEmpty(contentState) ? contentState : ''}
						/>
					</>
				</Modal>

				<Modal
					centered
					className="delete-field-modal modal-footer-hide send-mail-modal"
					title="Delete Field"
					visible={deleteModal}
					maskClosable={false}
					onOk={this.deleteSelectedField}
					onCancel={this.deleteSelectedField}
				>
					<div className="text-center">
						<h4>Are you sure?</h4>
						<p className="no-mb">Are you sure that you want to delete this question?</p>
						<p><b>Data from existing will be deleted</b></p>
						<button className="delete-btn site-btn" onClick={() => this.deleteFormField()}>Delete</button>
					</div>
				</Modal>

				<Modal centered className="send-mail-modal" title="New Form" visible={displayModal} onOk={this.handleFormTitleSubmit} onCancel={this.handleFormTitleClose} maskClosable={false}>
					{this.displayFormInputModal()}
				</Modal>
			</>
		);
	}
}
export default FormContent;