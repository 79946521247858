import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, Dropdown, Menu, Row, Col, Upload, Select, Checkbox, Alert, Modal } from 'antd';
import { AiTwotoneDelete } from 'react-icons/ai';
import firebase from '../../../services/firebase';
import loader from '../../../assets/imgs/loader.gif';
import { addActivityLogs, isEmpty } from '../../../utils/activity-logs';
import moment from 'moment';
import { RiInboxArchiveFill } from 'react-icons/ri';
import { RiSendPlaneFill } from 'react-icons/ri';
import { saveAs } from 'file-saver';
import { sendRigPic } from '../../../services/auth';
import { checkDeletePermissions, checkWritePermissions } from '../../../utils/native';
const Option = Select.Option;

class EditAsset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'assets'),
			isDeletePermitted: checkDeletePermissions(props, 'assets'),
			id: this.props.match.params.id,
			assets: [],
			data: [],
			rig: '',
			load: false,
			part: '',
			task: '',
			validateTrigger: false,
			menu: '',
			companies: [],
			subMenu: (
				<Menu>
					<Menu.Item key="Not usable" onClick={(value) => this.onInputChange('condition', value.key)}>Not usable</Menu.Item>
					<Menu.Item key="Poor" onClick={(value) => this.onInputChange('condition', value.key)}>Poor</Menu.Item>
					<Menu.Item key="Good" onClick={(value) => this.onInputChange('condition', value.key)}>Good</Menu.Item>
					<Menu.Item key="Excellent" onClick={(value) => this.onInputChange('condition', value.key)}>Excellent</Menu.Item>
				</Menu>
			),
			errorMessage: false,
			submitted: false,
			load_part: false,
			load_task: false,
			user: '',
			isModalVisible: false,
			emailDetails: {},
			formSubmitted: false,
			isDelete: false
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'assets'), isDeletePermitted: checkDeletePermissions(this.props, 'assets') });
		}
	}


	onLoad = () => {
		this.setState({
			load: true
		});
	}

	componentDidMount() {
		const db = firebase.firestore();
		const assetsRef = db.collection('assets').doc(this.state.id);
		assetsRef.get().then((querySnapshot) => {
			const res = [];
			if (querySnapshot.exists) {
				const data = querySnapshot.data();
				data.$key = querySnapshot.id;
				if (data.archive !== true) {
					res.push(data);
				}
				this.state.data.push(querySnapshot.data().date);
			}
			this.setState({
				assets: res,
				data: this.state.data.filter((item, index, inputArray) => {
					return inputArray.indexOf(item) === index;
				})
			});
			if (!isEmpty(res[0].createdBy)) {
				this.getUserById(res[0].createdBy);
			}
			this.getRig(res[0].rigId);
			this.getRigParts();
			this.getRigTasks();
			this.getCompany();
		});
	}

	getRig(rigId) {
		const db = firebase.firestore();
		const rigs = db.collection('rigs').doc(rigId);

		rigs.get().then((doc) => {
			this.setState({ Loader: false });
			// if (doc.exists && doc.data().archive !== true) {
			if (doc.exists) {
				const res = doc.data();
				res.$key = doc.id;
				res.releaseDate = res.releaseDate.replace('Z', '');
				this.setState({
					rig: res
				});
				this.getCurrentUser();
			}
		}).catch(() => {
			this.setState({ Loader: false });
		});
	}

	getRigParts() {
		const { assets } = this.state;
		
		const databse = firebase.firestore();
		const partsRef = databse.collection('parts');

		partsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const response = doc.data();
					response.$key = doc.id;
					temp.push(response);
				}
			});

			const parts = [];
			if (assets[0].part) {
				for (let i = 0; i < temp.length; i++) {
					for (let j = 0; j < assets[0].part.length; j++) {
						if (temp[i].name === assets[0].part[j]) {
							parts.push(temp[i].name);
						}
					}
				}
				assets[0]['part'] = parts;
			}

			this.setState({ part: temp, ...assets, load_part: true });
		});
	}

	getRigTasks() {
		const { assets } = this.state;
		
		const databse = firebase.firestore();
		const partsRef = databse.collection('tasks');
		partsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const response = doc.data();
					response.$key = doc.id;
					temp.push(response);

				}
			});

			const tasks = [];
			if (assets[0].task) {
				for (let i = 0; i < temp.length; i++) {
					for (let j = 0; j < assets[0].task.length; j++) {
						if (temp[i].name === assets[0].task[j]) {
							tasks.push(temp[i].name);
						}
					}
				}
				assets[0]['task'] = tasks;
			}

			this.setState({ task: temp, ...assets, load_task: true });
		});
	}

	getCompany() {
		const { assets } = this.state;
		const databse = firebase.firestore();
		// const companyRef = databse.collection("rigCompanies").where('uid', '==', localStorage.getItem('user'));
		const companyRef = databse.collection('rigCompanies').where('rigId', '==', assets[0].rigId);
		companyRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const response = doc.data();
					response.$key = doc.id;
					temp.push(response);
				}
			});
			this.setState({ companies: temp });
			if (this.state.companies.length > 0) {
				this.getMenuItem();
			}
		});
	}

	getUserById(id) {
		const db = firebase.firestore();
		const rigs = db.collection('users').where('uid', '==', id);
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					temp.push(doc.data());
				}
			});
			this.setState({ user: temp[0] });
		});
	}

	getMenuItem() {
		const menu = () => {
			const { companies } = this.state;
			return (
				<Menu>
					{companies.map((key) => {
						return (
							<Menu.Item key={key.name} onClick={() => this.handleClick('companyName', key.name)}>
								<span style={{ fontSize: 16 }}>{key.name}</span>
							</Menu.Item>
						);
					})}
				</Menu>
			);
		};
		this.setState({ menu: menu });
	}

	handleClick(key, value) {
		const { assets } = this.state;
		assets[0][key] = value;
		this.setState({ ...assets });
	}

	onInputChange(key, value) {
		const { assets } = this.state;
		assets[0][key] = value;
		this.setState({ ...assets });
	}

	onSelectChange(key, value) {
		const { assets } = this.state;
		const temp = [];
		for (let i = 0; i < value.length; i++) {
			temp.push(value[i]);
		}
		assets[0][key] = temp;
		this.setState({ ...assets });
	}

	onChange = async info => {
		const { assets } = this.state;
		if (!isEmpty(info) && info.file && info.file.originFileObj) {
			const type = info.file.originFileObj.type;
			const src = await new Promise(resolve => {
				if (type.split('/')[0] === 'image') {
					const reader = new FileReader();
					reader.readAsDataURL(info.file.originFileObj);
					reader.onload = () => resolve(reader.result);
					reader.onerror = () => { };
				} else {
					this.setState({ errorMessage: true }, () => setTimeout(() => this.setState({ errorMessage: false }), 5000));
				}
			});

			if (type.split('/')[0] === 'image') {
				//Add image url
				const image = new Image();
				image.src = src;
				assets[0].url = src;
				assets[0].createdBy = localStorage.getItem('user');
				assets[0].updatedAt = moment().valueOf();
				this.getUserById(localStorage.getItem('user'));
				this.setState({ ...assets, submitted: false });
			}
		}
	};

	onDelete() {
		const { assets } = this.state;
		const oldAssets = assets;
		oldAssets[0].url = '';
		this.setState({ assets: oldAssets });
	}

	onDeletePic() {
		const { isDelete } = this.state;
		this.setState({ isDelete: !isDelete });
	}

	async confirmDelete() {
		const { assets } = this.state;
		assets[0].archive = true;

		this.onDeletePic();
		const db = firebase.firestore();
		await db.doc(`assets/${assets[0].$key}`).update(assets[0])
			.then(() => this.updateLogs('delete'))
			.catch(() => {});
	}

	onValueChanges(key, value) {
		const { emailDetails } = this.state;
		emailDetails[key] = value;
		this.setState({ ...emailDetails });
	}

	onArchive() {
		const { assets } = this.state;
		saveAs(assets[0].url);
	}

	onSend() {
		const { assets } = this.state;
		const temp = assets[0].url.split(';')[0];
		const type = temp.split('/')[1];
		const data = {
			file: assets[0].url,
			type: type
		};
		this.setState({ emailDetails: data });
		this.showModal();
	}

	showModal() {
		this.setState({ isModalVisible: true });
	}

	handleOk(e) {
		e.preventDefault();
		const { form } = this.props;
		const { emailDetails } = this.state;
		form.validateFields(async (err) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				this.setState({ formSubmitted: true });
				sendRigPic(emailDetails)
					.then((res) => {
						alert(res.data.success);
						this.setState({ formSubmitted: false, isModalVisible: false });
					}).catch((e) => {
						alert(e);
						this.setState({ formSubmitted: false, isModalVisible: false });
					});
			}
		});
	}

	handleCancel() {
		this.setState({ isModalVisible: false });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { form } = this.props;
		const { assets } = this.state;
		form.validateFields(async (err) => {
			this.setState({ validateTrigger: true });

			if (assets[0].url !== '') {
				if (!err) {
					this.setState({
						errorMessage: ''
					});
				}
				this.setState({ submitted: false });

				const db = firebase.firestore();
				await db.doc(`assets/${assets[0].$key}`).update(assets[0])
					.then(() => this.updateLogs('update'))
					.catch(() => {});

			} else {
				this.setState({ submitted: true });
			}
		});
	}

	async updateLogs(action) {
		const { rig, assets } = this.state;
		await addActivityLogs(rig, action, 'Assets', assets[0].$key).then(() => {
			this.props.history.replace('/assets');
		});
	}

	render() {
		const { validateTrigger, part, task, companies, menu, subMenu, errorMessage, submitted, load_part, load_task, user, isModalVisible, emailDetails,
			formSubmitted, isDelete, isDeletePermitted } = this.state;
		const { form: { getFieldDecorator } } = this.props;

		if (this.state.assets.length === 0 || formSubmitted) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div className="edit-rig-area edit-rig-asset-part">
				<h4 className="card-title no-mb">Edit Rig Asset</h4>
				<div className="edit-rig-inner">
					{this.state.assets.map((picture, index) => {
						return (
							<div key={index}>
								<div className="rig-photo-form-img-area">
									<div className="left-area">
										<div className="card-body">
											<div className="rig-photos-part">
												<Upload
													className="rig-photo-upload-area"
													listType="picture"
													showUploadList={false}
													onChange={this.onChange}
													customRequest={this.onChange}
												>
													{/* {'Upload'} */}
													{picture.url ?
														<img src={picture.url} className="edit-image" alt="asset" />
														:
														<div className="upload-btn">Upload</div>
													}
												</Upload>
												{picture.url &&
													<>
														{/* <AiTwotoneDelete size={20} className="deleteIcon" onClick={() => this.onDelete()} /> */}
														{isDeletePermitted &&
															<AiTwotoneDelete size={20} className="deleteIcon" onClick={() => this.onDeletePic()} />
														}
														<RiInboxArchiveFill size={20} className="archiveIcon" onClick={() => this.onArchive()} />
														<RiSendPlaneFill size={20} className="sendIcon" onClick={() => this.onSend()} />
													</>
												}
												{!isEmpty(user) &&
													<p className='user-details'>Captured By: {user.firstName + ' ' + user.lastName}</p>
												}
												{errorMessage ? <Alert message="Please upload image only" type="error" style={{ margin: '15px auto', width: '50%' }} /> :
													submitted && <Alert message="Please upload image" type="error" style={{ margin: '15px auto', width: '50%' }} />}
											</div>
										</div>
									</div>
									<div className="right-area">
										<div className="card-body">
											<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="theme-form">
												<Row>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Title">
															{getFieldDecorator('title', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.title,
															})(
																<Input onChange={(e) => this.onInputChange('title', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													{companies.length === 0 ?
														<Col md={24} xl={12} span={24}>
															<Form.Item label="Company">
																{getFieldDecorator('companyName', {
																	validateTrigger: validateTrigger ? 'onChange' : '',
																	initialValue: picture.companyName,
																})(
																	<Dropdown overlayClassName="custom-dropdown-option" className="custom-dropdown-part" overlay={menu} placement="bottomCenter" arrow disabled={true}>
																		<Button size="large">
																			{picture.companyName}
																		</Button>
																	</Dropdown>
																)}
															</Form.Item>
														</Col>
														:
														<Col md={24} xl={12} span={24}>
															<Form.Item label="Company">
																{getFieldDecorator('companyName', {
																	validateTrigger: validateTrigger ? 'onChange' : '',
																	initialValue: picture.companyName,
																})(
																	<Dropdown overlayClassName="custom-dropdown-option" className="custom-dropdown-part" overlay={menu} placement="bottomCenter" arrow>
																		<Button size="large">
																			{picture.companyName}
																		</Button>
																	</Dropdown>
																)}
															</Form.Item>
														</Col>
													}
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Truck #">
															{getFieldDecorator('truck', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.truck,
															})(
																<Input onChange={(e) => this.onInputChange('truck', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													{/* Rig Part */}
													<Col md={24} xl={12} id="DropdownFixPostion">
														{load_part ?
															<Form.Item label="Rig part">
																{getFieldDecorator('part', {
																	validateTrigger: validateTrigger ? 'onChange' : '',
																	initialValue: picture.part
																})(
																	<Select
																		dropdownClassName="custom-select-option"
																		className="custom-select-multiple"
																		getPopupContainer={() => document.getElementById('DropdownFixPostion')}
																		mode="multiple"
																		labelInValue={false}
																		tokenSeparators={[' ', ',']}
																		onChange={(value) => this.onSelectChange('part', value)}
																	>
																		{part && part.length > 0 && part.map((key) => {
																			return (
																				<Option key={key.$key} value={key.name}>{key.name}</Option>
																			);
																		})}
																	</Select>
																)}
															</Form.Item>
															:
															<Form.Item label="Rig part">
																{getFieldDecorator('part', {
																	validateTrigger: validateTrigger ? 'onChange' : ''
																})(
																	<Input disabled={true} />
																)}
															</Form.Item>
														}
													</Col>
													{/* Task */}
													<Col md={24} xl={12} id="DropdownFixPostion">
														{load_task ?
															<Form.Item label="Task">
																{getFieldDecorator('task', {
																	validateTrigger: validateTrigger ? 'onChange' : '',
																	initialValue: picture.task
																})(
																	<Select
																		dropdownClassName="custom-select-option"
																		className="custom-select-multiple"
																		getPopupContainer={() => document.getElementById('DropdownFixPostion')}
																		mode="multiple"
																		labelInValue={false}
																		tokenSeparators={[' ', ',']}
																		onChange={(value) => this.onSelectChange('task', value)}
																	>
																		{task && task.length > 0 && task.map((key) => {
																			return (
																				<Option key={key.$key} value={key.name}>{key.name}</Option>
																				// <Option key={key+i} value={key}>{key}</Option>
																			);
																		})}
																	</Select>
																)}
															</Form.Item>
															:
															<Form.Item label="Task">
																{getFieldDecorator('task', {
																	validateTrigger: validateTrigger ? 'onChange' : ''
																})(
																	<Input disabled={true} />
																)}
															</Form.Item>
														}
													</Col>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Description / Notes">
															{getFieldDecorator('description', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.description,
															})(
																<Input onChange={(e) => this.onInputChange('description', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Item #">
															{getFieldDecorator('item_number', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.item_number,
															})(
																<Input onChange={(e) => this.onInputChange('item_number', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Asset #">
															{getFieldDecorator('asset_number', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.asset_number,
															})(
																<Input onChange={(e) => this.onInputChange('asset_number', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="P.O. #">
															{getFieldDecorator('po_number', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.po_number,
															})(
																<Input onChange={(e) => this.onInputChange('po_number', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Enter Quantity">
															{getFieldDecorator('quantity', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.quantity,
															})(
																<Input onChange={(e) => this.onInputChange('quantity', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Enter Height">
															{getFieldDecorator('height', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.height,
															})(
																<Input onChange={(e) => this.onInputChange('height', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Enter Width">
															{getFieldDecorator('width', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.width,
															})(
																<Input onChange={(e) => this.onInputChange('width', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Enter Length">
															{getFieldDecorator('length', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.length,
															})(
																<Input onChange={(e) => this.onInputChange('length', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Enter Weight">
															{getFieldDecorator('weight', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.weight,
															})(
																<Input onChange={(e) => this.onInputChange('weight', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													<Col md={24}>
														<Form.Item className="custom-checkbox">
															{getFieldDecorator('service', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.service,
															})(
																<Checkbox checked={picture.service} onChange={() => this.onInputChange('service', !picture.service)}>Ready for service</Checkbox>
															)}
														</Form.Item>
														<Form.Item className="custom-checkbox">
															{getFieldDecorator('repairs', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.repairs,
															})(
																<Checkbox checked={picture.repairs} onChange={() => this.onInputChange('repairs', !picture.repairs)}>Needs repair</Checkbox>
															)}
														</Form.Item>
													</Col>
													{picture.repairs &&
														<>
															<Col md={24} xl={12} span={24}>
																<Form.Item label="Repair Details">
																	{getFieldDecorator('repairDetails', {
																		validateTrigger: validateTrigger ? 'onChange' : '',
																		initialValue: picture.repairDetails,
																	})(
																		<Input onChange={(e) => this.onInputChange('repairDetails', e.target.value)} />
																	)}
																</Form.Item>
															</Col>
															<Col md={24} xl={12} span={24}></Col>
														</>
													}
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Overall Condition">
															{getFieldDecorator('condition', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.condition,
															})(
																<Dropdown overlayClassName="custom-dropdown-option" className="custom-dropdown-part" overlay={subMenu} placement="bottomCenter" arrow>
																	<Button size="large">
																		{picture.condition}
																	</Button>
																</Dropdown>
															)}
														</Form.Item>
													</Col>
												</Row>
												{submitted && <Alert message="Please upload image" type="error" />}
												<Form.Item className="button-section">
													<Button type="primary" htmlType="submit" size="large" className="submitBtn">
														Update
													</Button>
												</Form.Item>
												{/* <div id="testPosition" style={{ position: 'relative' }}>
													hello
													<Select
													getPopupContainer={() => document.getElementById("testPosition")}
													style={{ width: 100 }}>
													<Option value="jack">jack</Option>
													<Option value="lucy">lucy</Option>
													<Option value="yiminghe">yiminghe</Option>
													</Select>
												</div> */}
											</Form>
										</div>
									</div>
									{isModalVisible &&
										<Modal className="send-mail-modal" title="Send Mail Details" visible={isModalVisible} onOk={(e) => this.handleOk(e)} onCancel={() => this.handleCancel()}>
											<Form className="theme-form" layout="vertical">
												<Form.Item label="Message">
													{getFieldDecorator('emailDetails.message', {
														validateTrigger: validateTrigger ? 'onChange' : '',
														rules: [
															{ required: true, message: 'Please enter message for sending email' }
														],
														initialValue: emailDetails.message,
													})(
														<Input onChange={(e) => this.onValueChanges('message', e.target.value)} />
													)}
												</Form.Item>
												<Form.Item label="Subject">
													{getFieldDecorator('emailDetails.title', {
														validateTrigger: validateTrigger ? 'onChange' : '',
														rules: [
															{ required: true, message: 'Please enter subject for sending email' }
														],
														initialValue: emailDetails.title,
													})(
														<Input onChange={(e) => this.onValueChanges('title', e.target.value)} />
													)}
												</Form.Item>
												<Form.Item label="To">
													{getFieldDecorator('emailDetails.users', {
														validateTrigger: validateTrigger ? 'onChange' : '',
														rules: [
															{ required: true, message: 'Please enter email address' },
															{ type: 'email', message: 'Invalid email address' },
														],
														initialValue: emailDetails.users,
													})(
														<Input onChange={(e) => this.onValueChanges('users', e.target.value)} />
													)}
												</Form.Item>
											</Form>
										</Modal>
									}
									{isDelete === true &&
										<Modal className='send-mail-modal' title='Delete Asset' visible={isDelete} onOk={() => this.confirmDelete()} onCancel={() => this.onDeletePic()}>
											<p className='text-center no-mb'><b>Are you sure, you want to delete this asset ?</b></p>
										</Modal>
									}
								</div>
							</div>
						);
					})}
				</div>
				{/* <footer className='reportFooter site_footer'>
					<p>Copyright 2018 - all rights reserved by RigMoveApp</p>
				</footer> */}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(EditAsset)));
