import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import firebase from '../../services/firebase';
import rec from '../../assets/imgs/rec.png';
import { withRouter } from 'react-router-dom';
import loader from '../../assets/imgs/loader.gif';
import { Tabs } from 'antd';
import './pic-list.css';
import { isEmpty } from '../../utils/activity-logs';
import { checkPermissions, checkWritePermissions } from '../../utils/native';
import ErrorPage from '../error-page';

const { TabPane } = Tabs;
class PicListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkPermissions(props, 'rigPics'),
			isPermitted1: checkPermissions(props, 'pusher'),
			isWritePermitted: checkWritePermissions(props, 'rigPics'),
			isWritePermitted1: checkWritePermissions(props, 'pusher'),
			rigpics: [],
			truckPusherPics: [],
			rigPicsLoadMore: false,
			loaded: false,
			selectedTab: 1
		};
	}
	componentDidMount() {
		this.getRigpics();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({
				isPermitted: checkPermissions(this.props, 'rigPics'), isPermitted1: checkPermissions(this.props, 'pusher'),
				isWritePermitted: checkWritePermissions(this.props, 'rigPics'), isWritePermitted1: checkWritePermissions(this.props, 'pusher')
			});
		}
	}

	getRigpics() {
		const db = firebase.firestore();
		// const picsRef = db.collection("rigpics").orderBy('createdAt', 'asc').limit(25);
		const picsRef = db.collection('rigpics').orderBy('createdAt', 'asc');
		picsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			if (temp.length !== 25) {
				this.setState({ rigPicsLoadMore: true });
			}
			this.setState({ rigpics: temp });
		});
		const pusherPicsRef = db.collection('pusher').orderBy('createdAt', 'asc');
		pusherPicsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						await this.getPusherPics(doc.id, doc.data().createdAt, temp);
					}
				}
				setTimeout(() => { this.setState({ loaded: true }); }, 2000);
			});
			if (temp.length !== 25) {
				this.setState({ rigPicsLoadMore: true });
			}
		});
	}

	getPusherPics(id, createdAt, temp) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const pusherRef = db.collection('pusher').doc(id).collection('images').orderBy('id', 'asc');
			pusherRef.get().then((querySnapshot) => {
				querySnapshot.forEach(async (doc) => {
					if (doc.exists) {
						const data = doc.data();
						data.$key = id;
						data.createdAt = !isEmpty(data.updatedAt) ? data.updatedAt : data.id;
						if (data.archive !== true) {
							temp.push(data);
						}
					}
				});
				this.setState({ truckPusherPics: temp });
				resolve(true);
			});
		});
	}

	getRigsData(type) {
		const db = firebase.firestore();
		let rigsRef;
		if (type === 'rigpics') {
			db.collection('rigpics').orderBy('createdAt', 'asc').startAt(this.state.rigpics[this.state.rigpics.length - 1].createdAt).limit(25);
		} else {
			db.collection('pusher').orderBy('createdAt', 'asc').startAt(this.state.rigpics[this.state.rigpics.length - 1].createdAt).limit(25);
		}
		rigsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			if (temp.length !== 25) {
				this.setState({ rigPicsLoadMore: true });
			}
			this.setState({ rigpics: [...this.state.rigpics, ...temp] });
		});
	}

	onChange = (key) => {
		this.setState({ selectedTab: parseInt(key) });
	};

	picLists(rigpics, type) {
		const { rigPicsLoadMore, loaded, isWritePermitted, isWritePermitted1 } = this.state;
		return (
			<div>
				{loaded === true &&
					<div className="rigImage-part">
						<div className="rigImage-row" id="rig_pics">
							{rigpics.map((picture, index) => {
								return (
									<div className="rigImage-box" key={index}>
										{/* <div onClick={() => this.props.history.push(`/pics/${picture.rigId}`)}> */}
										<div className="inner-area" onClick={() => type === 'rigpics' ?
											isWritePermitted &&
											this.props.history.replace(`/pics/${picture.$key}`)
											:
											isWritePermitted1 &&
											this.props.history.replace(`/pic/${picture.$key}`)}>
											{picture.url ?
												<img src={picture.url} alt="rigpics" className="rig-photo" />
												:
												<img src={rec} onLoad={this.onLoad} alt="no_rigpics" className="rig-photo" />
											}
											<h4><Moment format="MMM DD, YYYY">{picture.createdAt}</Moment></h4>
											<p className='picSection-time'><Moment format="h:mm a">{picture.createdAt}</Moment></p>
										</div>
									</div>
								);
							})}
						</div>
						{!rigPicsLoadMore && <button className="site-btn" onClick={() => this.getRigsData()} >Load more Data</button>}
					</div>
				}
			</div>
		);
	}

	render() {
		const { rigpics, loaded, truckPusherPics, selectedTab, isPermitted, isPermitted1 } = this.state;

		if (loaded === false) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			// <div className="custom-tabs">
			<div className="card-tab-body">
				<div className="custom-tabs no-mt">
					<Tabs type="card" defaultActiveKey="1" onChange={this.onChange}>
						<TabPane tab={!isPermitted ? 'Rig Pics' : `Rig Pics (${rigpics.length})`} key="1">
							{(selectedTab === 1 && isPermitted) ? this.picLists(rigpics, 'rigpics') : <ErrorPage />}
						</TabPane>
						<TabPane tab={!isPermitted1 ? 'Truck Pusher Pics' : `Truck Pusher Pics (${truckPusherPics.length})`} key="2">
							{(selectedTab === 2 && isPermitted1) ? this.picLists(truckPusherPics, 'truckPusherPics') : <ErrorPage />}
						</TabPane>
					</Tabs>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(PicListing));
