import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../../services/firebase';
import { withRouter } from 'react-router-dom';
import { Table, Row, Col } from 'antd';
import loader from '../../../../assets/imgs/loader.gif';
import Moment from 'react-moment';
import moment from 'moment';
import { FaCheck } from 'react-icons/fa';
import { checkWritePermissions } from '../../../../utils/native';

class Loads extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			isLoading: false,
			rig: {},
			id: props.match.params.id,
			down_notes: [],
			up_notes: [],
			notes: [],
			columns: [
				{
					title: 'Rig Part',
					dataIndex: 'part',
					key: 'part',
					render: (part) => part && part.map((parts) => parts.name).join(),
				},
				{
					title: 'Width',
					dataIndex: 'width',
					key: 'width',
					render: (_, record) => this.renderCustomCell(record.dimensions.width),
				},
				{
					title: 'Height',
					dataIndex: 'height',
					key: 'height',
					render: (_, record) => this.renderCustomCell(record.dimensions.height),
				},
				{
					title: 'Length',
					dataIndex: 'length',
					key: 'length',
					render: (_, record) => this.renderCustomCell(record.dimensions.length),
				},
				{
					title: 'Overhang',
					dataIndex: 'overhang',
					key: 'overhang',
					render: (_, record) => this.renderCustomCell(record.dimensions.truck),
				},
				{
					title: 'Permit',
					dataIndex: 'permitLoad',
					key: 'permitLoad',
					render: (_, record) => record.permitLoad === true && (
						<FaCheck size={28} />
					)
				},
				{
					title: 'Non-Permit',
					dataIndex: 'permitLoad',
					key: 'Non-Permit',
					render: (_, record) => record.permitLoad === false && (
						<FaCheck size={28} />
					)
				}
			]
		};
	}

	renderCustomCell(record) {
		return (
			record.feet && (
				<p>{record.feet} ft. {record.inches} inch.</p>
			)
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports') });
		}
	}

	componentDidMount() {
		this.getRigById();
		this.getRigDownNotes();
		this.getRigUpNotes();
	}

	getRigById() {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		const rigsRef = db.collection('rigs').doc(this.state.id);
		rigsRef.get().then((doc) => {
			this.setState({ Loader: false });
			if (doc.exists && doc.data().archive !== true) {
				const res = doc.data();
				res.$key = doc.id;
				res.releaseDate = res.releaseDate.replace('Z', '');
				this.setState({
					rig: res
				});
			}
		}).catch(() => {
			this.setState({ Loader: false });
		});
	}

	getRigDownNotes() {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		const downRef = db.collection('Rig-Down').where('rigId', '==', this.state.id).orderBy('time', 'desc');
		downRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						if (data.dimensions.width.feet === undefined) {
							data.dimensions.width = {};
						}
						if (data.dimensions.height.feet === undefined) {
							data.dimensions.height = {};
						}
						if (data.dimensions.length.feet === undefined) {
							data.dimensions.length = {};
						}
						if (data.dimensions.truck.feet === undefined) {
							data.dimensions.truck = {};
						}
						temp.push(data);
					}
				}
			});
			this.setState({ down_notes: temp });
		}).catch(() => {
			this.setState({ Loader: false });
		});
	}

	getRigUpNotes() {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		const upRef = db.collection('Rig-Up').where('rigId', '==', this.state.id).orderBy('time', 'desc');
		upRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			this.setState({ up_notes: temp });
			this.setState({ notes: this.state.down_notes.concat(temp) });
		}).catch(() => {
			this.setState({ Loader: false });
		});
	}

	render() {
		const { rig, Loader, notes, columns } = this.state;

		if (Loader === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div className="card-body">
				{/* <button onClick={() => this.props.history.push('/rigs/add')} className="newAdminCreate">
					<BiPlus size={40} style={{ color: "#fff", fontSize: "20px" }} onClick={() => this.props.history.push('/rigs/add')} />
				</button> */}
				{rig &&
					<div className="output-table-header">
						<Row>
							<Col md={12} xl={12} span={24}>
								<p><span>Rig Name: </span>{rig.name}</p>
							</Col>
							<Col md={12} xl={12} span={24}>
								<p><span>Rig #: </span>{rig.number}</p>
							</Col>
							<Col md={12} xl={12} span={24}>
								<p><span>Job #: </span>{rig.jobNumber}</p>
							</Col>
							<Col md={12} xl={12} span={24}>
								<p><span>Date: </span><Moment format="MMM DD, YYYY">{moment()}</Moment></p>
							</Col>
						</Row>
					</div>
				}
				{notes.length > 0 &&
					<Table columns={columns} dataSource={notes} rowKey={record => record.$key} />
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(Loads));
