import { isEmpty } from './activity-logs';

export const checkPermissions = (props, key) => {
	const { currentUser } = props.auth;
	if (!isEmpty(currentUser)) {
		const { userType } = currentUser;
		if (userType !== 'owner') {
			const { currentUser: { modulePermissions: { modules } } } = props.auth;
			const result = modules.filter((obj) => { return obj.type === key; });
			return result[0].read;
		} else {
			return true;
		}
	}
};

export const checkWritePermissions = (props, key) => {
	const { currentUser } = props.auth;
	if (!isEmpty(currentUser)) {
		const { userType } = currentUser;
		if (userType !== 'owner') {
			const { currentUser: { modulePermissions: { modules } } } = props.auth;
			const result = modules.filter((obj) => { return obj.type === key; });
			return result[0].write;
		} else {
			return true;
		}
	}
};

export const checkDeletePermissions = (props, key) => {
	const { currentUser } = props.auth;
	if (!isEmpty(currentUser)) {
		const { userType } = currentUser;
		if (userType !== 'owner') {
			const { currentUser: { modulePermissions: { modules } } } = props.auth;
			const result = modules.filter((obj) => { return obj.type === key; });
			return result[0].delete;
		} else {
			return true;
		}
	}
};

export const checkAccess = (props) => {
	const { currentUser } = props.auth;
	if (!isEmpty(currentUser)) {
		const { userType } = currentUser;
		if (userType === 'owner') {
			return true;
		} else {
			return false;
		}
	}
};