import React, { Component } from 'react';
import { Input } from 'antd';
import './mobile-preview.css';

const { TextArea } = Input;
class MobileOpenEnded extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { details } = this.props;
		return (
			<>
				{/* <div className="mobile-field error"> */}
				<div className="mobile-field">
					<div className="m-box-header">
						<p className="title">
							{details.required &&
								<span className="require-symbol">*</span>
							}
							{details.label}
							<span className="subtitle">{details.description}</span>
						</p>
					</div>
					<div className="m-box-content">
						<TextArea className="text-area" rows={1} placeholder="Type here..." />
					</div>
				</div>
			</>
		);
	}
}
export default MobileOpenEnded;
