import React, { Component } from 'react';
import './mobile-preview.css';

class MobileDescription extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { details } = this.props;
		return (
			<>
				<div className="mobile-field">
					<div className="m-box-header">
						<p className="title">
							{/* {details.label} */}
							<span className="title-label" dangerouslySetInnerHTML={{ __html: details.label }} />
						</p>
					</div>
				</div>
			</>
		);
	}
}
export default MobileDescription;