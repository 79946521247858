import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../services/firebase';
import { withRouter } from 'react-router-dom';
import { Table, Popconfirm, Row, Col, DatePicker, Input } from 'antd';
import loader from '../../../assets/imgs/loader.gif';
import { saveAs } from 'file-saver';
import { fileAttachment, generateReport } from '../../../services/auth';
import Moment from 'react-moment';
import * as moment from 'moment';
import { addActivityLogs, isEmpty } from '../../../utils/activity-logs';
import { checkDeletePermissions, checkWritePermissions } from '../../../utils/native';

const { Column } = Table;

class PusherReportsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			isDeletedPermitted: checkDeletePermissions(props, 'reports'),
			isLoading: false,
			reports: [],
			reportsLoadMore: false,
			isAdmin: localStorage.getItem('admin') === 'true' ? true : false,
			rigs: [],
			searchInput: '',
			searchList: [],
			users: []
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports'), isDeletedPermitted: checkDeletePermissions(this.props, 'reports') });
		}
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		this.getUsers();
		/* I've commented out this as below function we are already calling on componentDidMount*/
		// this.getRigs();
	}

	getUsers() {
		const db = firebase.firestore();
		const rigs = db.collection('users');
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					temp.push(doc.data());
				}
			});
			this.setState({
				users: temp
			});
			this.getRigs(false);
		});
	}

	getRigs() {
		const db = firebase.firestore();
		const rigsRef = db.collection('rigs').orderBy('createdAt', 'asc');
		rigsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					// if (data.archive !== true) {
					temp.push(data);
					// }
				}
			});
			this.setState({ rigs: temp });
			this.getReports();
		});
	}

	getReports() {
		const { rigs } = this.state;
		const db = firebase.firestore();
		const reportsRef = db.collection('rigReports').where('reportType', '==', 'pusher_report').orderBy('generatedOn', 'desc');
		this.setState({ isLoading: true });
		reportsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						const userIndex = rigs.map(admin => admin.$key).indexOf(data.rigId);
						data.rigName = rigs[userIndex].name;
						data.rigNumber = rigs[userIndex].number;
						data.rigJobNumber = rigs[userIndex].jobNumber;
						if (isEmpty(data.number)) {
							temp.push(data);
						}
					}
					this.getUserDetails(data);
				}
			});
			this.setState({ reports: temp, isLoading: false });
		});
	}

	getUserDetails(data) {
		const { users } = this.state;
		const userIndex = users.map(user => user.uid).indexOf(data.uid);
		if (userIndex !== -1) {
			data.updatedByUser = users[userIndex].firstName + ' ' + users[userIndex].lastName;
		}
	}

	reportsLoadMoreData() {
		
		const db = firebase.firestore();
		const reportsRef = db.collection('rigReports').orderBy('generatedOn', 'desc').startAt(this.state.reports[this.state.reports.length - 1].generatedOn).limit(25);
		reportsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			this.setState({ reports: [...this.state.reports, ...temp] });
		});
	}

	getRigById(rigId) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const rigsRef = db.collection('rigs').where('$key', '==', rigId);
			rigsRef.get().then((querySnapshot) => {
				let rigDetails;
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						rigDetails = doc.data();
						rigDetails.$key = doc.id;
					}
				});
				this.setState({ rig: rigDetails });
				// resolve(true);
				resolve(rigDetails);
			});
		});
	}

	viewReport(key) {
		this.props.history.push(`/pusher/${key}`);
	}

	calculateTimeDifference = async (rigDetails) => {
		if (rigDetails.startClock === 'bumpPlug') {
			const result = await this.calculateDiff(rigDetails.bumpPlug, rigDetails);
			return result;
		} else {
			const result = await this.calculateDiff(rigDetails.releaseDate, rigDetails);
			return result;
		}
	};

	calculateDiff = (key, rigDetails) => {
		let  mastLowered, mastRaised, subsLowered, subsRaised, nippleUpBope, beginBope, finishBope, pickupBha, spud;
		if (rigDetails.mastLowered !== null) {
			mastLowered = this.elapsedTime(rigDetails.mastLowered, key);
		}
		if (rigDetails.mastRaised !== null) {
			mastRaised = this.elapsedTime(rigDetails.mastRaised, key);
		}
		if (rigDetails.subsLowered !== null) {
			subsLowered = this.elapsedTime(rigDetails.subsLowered, key);
		}
		if (rigDetails.subsRaised !== null) {
			subsRaised = this.elapsedTime(rigDetails.subsRaised, key);
		}
		if (rigDetails.nippleUpBope !== null) {
			nippleUpBope = this.elapsedTime(rigDetails.nippleUpBope, key);
		}
		if (rigDetails.beginBope !== null) {
			beginBope = this.elapsedTime(rigDetails.beginBope, key);
		}
		if (rigDetails.finishBope !== null) {
			finishBope = this.elapsedTime(rigDetails.finishBope, key);
		}
		if (rigDetails.pickupBha !== null) {
			pickupBha = this.elapsedTime(rigDetails.pickupBha, key);
		}
		if (rigDetails.spud !== null) {
			spud = this.elapsedTime(rigDetails.spud, key);
		}
		return {
			mastLowered: mastLowered,
			mastRaised: mastRaised,
			subsLowered: subsLowered,
			subsRaised: subsRaised,
			nippleUpBope: nippleUpBope,
			beginBope: beginBope,
			finishBope: finishBope,
			pickupBha: pickupBha,
			spud: spud,
		};
	};

	/* ---------------------------- Elapsed Time ---------------------------- */
	elapsedTime = (t1, t2) => {
		const time1 = moment(t1);
		const time2 = moment(t2);
		const days = time2.diff(time1, 'days');
		let hours;
		if (days > 0) {
			hours = time2.diff(time1, 'hours') - (days * 24);
		} else {
			hours = time2.diff(time1, 'hours');
		}
		const minutes = +moment.utc(moment(time2, 'HH:mm:ss').diff(moment(time1, 'HH:mm:ss'))).format('mm');
		return {
			days: days,
			hours: hours,
			minutes: minutes
		};
	};

	rigmoveClockCount = async (rigDetails) => {
		if ((rigDetails.startClock && !rigDetails.endClock) || (rigDetails.startClock && rigDetails.endClock === '')) {
			if (rigDetails.startClock === 'startRigClock' && (rigDetails.startRigClock === undefined || rigDetails.startRigClock === '')) {
				const rigClock = await this.noDateDiff();
				return rigClock;
			} else {
				const startDate = rigDetails.startRigClock && moment(rigDetails.startRigClock.replace('Z', ''));
				const currentDate = moment();
				const rigClock = await this.calculateDateDiff(moment(startDate), currentDate, false);
				return rigClock;
			}
		}
		if (rigDetails.startClock === 'startRigClock' && rigDetails.endClock === 'stopRigClock') {
			if (rigDetails.startRigClock && rigDetails.stopRigClock && rigDetails.startRigClock !== null && rigDetails.stopRigClock !== '') {
				const rigClock = await  this.calculateDateDiff(rigDetails.startRigClock, rigDetails.stopRigClock, true);
				return rigClock;
			} else if (rigDetails.stopRigClock === '') {
				const startDate = moment(rigDetails.startRigClock);
				const currentDate = moment();
				const rigClock = await this.calculateDateDiff(startDate, currentDate, true);
				return rigClock;
			} else {
				const rigClock = await this.noDateDiff();
				return rigClock;
			}
		}
	};

	calculateDateDiff = (date1, date2, utc) => {
		let date = { days: 0, hour: 0, minutes: 0 };
		if (utc === true) {
			const release = moment(date1);
			const pickup = moment(date2);
			date.days = pickup.diff(release, 'days');
			date.hour = pickup.diff(release, 'hours') - (date.days * 24);
			date.minutes = +moment.utc(moment(pickup, 'HH:mm:ss').diff(moment(release, 'HH:mm:ss'))).format('mm');
			return date;
		} else {
			const currentDate = moment(date2, 'MM-DD-YYYY HH:mm:ss').valueOf();
			const startDate = moment(date1, 'MM-DD-YYYY HH:mm:ss').valueOf();
			var temp = Math.abs(currentDate - startDate) / 1000;
			date.days = Math.floor(temp / 86400);
			date.hour = Math.floor(temp / 3600) % 24;
			date.minutes = Math.floor(temp / 60) % 60;
			return date;
		}
	};

	noDateDiff = () => {
		let date = { days: 0, hour: 0, minutes: 0 };
		return date;
	};

	getUserById(userId) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const usersRef = db.collection('users').where('uid', '==', userId);
			usersRef.get().then((querySnapshot) => {
				let userDetails;
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						userDetails = doc.data();
						userDetails.$key = doc.id;
					}
				});
				resolve(userDetails);
			});
		});
	}

	getPusherByType(rigId, selectedDate, endDate) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			let query;
			if (!endDate) {
				query = db.collection('pusher').where('rigId', '==', rigId).where('date', '==', selectedDate).orderBy('time', 'desc');
			} else {
				query = db.collection('pusher').where('rigId', '==', rigId).where('date', '>=', selectedDate).where('date', '<=', endDate);
			}
			query.get().then((querySnapshot) => {
				let pusherDownNotes = [], pusherUpNotes = [], otherNotes = [];
				if (!querySnapshot.empty) {
					querySnapshot.forEach(async note => {
						let item = note.data();
						item.$key = note.id;
						delete item.images;
						if (!item.archive) {
							if (item.type === 'rigDown') {
								pusherDownNotes.push(item);
							}
							if (item.type === 'rigUp') {
								pusherUpNotes.push(item);
							}
							if (isEmpty(item.type)) {
								otherNotes.push(item);
							}
						}
					});
				}
				resolve({ pusherDownNotes, pusherUpNotes, otherNotes });
			});
		});
	}

	getPusherPics(id, users) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			// const query = db.collection(`pusher/${id}/images`);
			const query = db.collection('pusher').doc(id).collection('images');
			query.get().then((querySnapshot) => {
				let pusherPics = [];
				querySnapshot.forEach(async note => {
					let item = note.data();
					item.$key = note.id;
					delete item.images;
					if (!item.archive) {
						const selected = users.filter((user) => item.createdBy === user.uid);
						if (selected.length > 0) {
							item.createdUser = selected[0];
						}
						pusherPics.push(item);
					}
				});
				resolve(pusherPics);
			});
		});
	}

	getArrayByDates = (list) => {
		return new Promise(async(resolve) => {
			const indexesArray = [], indexes = [];
			for(const i in list) {
				if (!list[i].archive) {
					const index = indexes.indexOf(list[i].date);
					if (index === -1) {
						indexes.push(list[i].date);
						const newIndex = indexes.indexOf(list[i].date);
						indexesArray[newIndex] = {
							title: list[i].createdAt,
							items: []
						};
						indexesArray[newIndex].items.push(list[i]);
					} else {
						if (!indexesArray[index]) {
							indexesArray[index] = {
								title: list[i].createdAt,
								items: []
							};
						}
						indexesArray[index].items.push(list[i]);
					}
				}
				if (parseInt(i) === (list.length - 1)) {
					resolve(indexesArray);
				}
			}
		});
	};

	getPusherPicsById = async(allPushers, users) => {
		const oldArray = [...allPushers];
		for(const i in oldArray) {
			const pics = await this.getPusherPics(oldArray[i].$key, users);
			oldArray[i].images = pics;
		}
		return oldArray;
	};

	handleDownload(selectedReport) {
		this.setState({ isLoading: true });
		// saveAs(selectedReport.url, selectedReport.title);
		fileAttachment(selectedReport)
			.then((res) => {
				saveAs(res.data.file, selectedReport.title);
				this.setState({ isLoading: false });
			}).catch(() => {
			});
	}

	async fetchReportDetails(selectedReport) {
		const rigDetails = await this.getRigById(selectedReport.rigId);
		const result = await this.calculateTimeDifference(rigDetails);
		const rigclock = await this.rigmoveClockCount(rigDetails);
		const userDetails = await this.getUserById(selectedReport.uid);
		const data = {
			generatedOn: selectedReport.generatedOn,
			selectedDate: selectedReport.date,
			endDate: selectedReport.endDate,
			rig: {
				...rigDetails,
				releaseDate: typeof (rigDetails.releaseDate) === 'string' ? rigDetails.releaseDate : ''
			},
			mastLowered: result.mastLowered,
			mastRaised: result.mastRaised,
			subsLowered: result.subsLowered,
			subsRaised: result.subsRaised,
			nippleUpBope: result.nippleUpBope,
			beginBope: result.beginBope,
			finishBope: result.finishBope,
			pickupBha: result.pickupBha,
			spud: result.spud,
			date: rigclock,
			reportCreatedBy: {
				firstName: userDetails.firstName,
				lastName: userDetails.lastName,
				email: userDetails.email
			}
		};

		const pusherResult = await this.getPusherByType(selectedReport.rigId, selectedReport.date, selectedReport.endDate);
		if (selectedReport.pusher_pics) {
			const downNotesPics = await this.getPusherPicsById(pusherResult.pusherDownNotes, this.state.users);
			const upNotesPics = await this.getPusherPicsById(pusherResult.pusherUpNotes, this.state.users);
			const otherNotesPics = await this.getPusherPicsById(pusherResult.otherNotes, this.state.users);

			if (downNotesPics.length > 0) {
				data.pusherDownNotes = await this.getArrayByDates(downNotesPics);
			}
			if (upNotesPics.length > 0) {
				data.pusherUpNotes = await this.getArrayByDates(upNotesPics);
			}
			if (otherNotesPics.length > 0) {
				data.otherNotes = await this.getArrayByDates(otherNotesPics);
			}
		} else {
			if (pusherResult.pusherDownNotes.length > 0) {
				data.pusherDownNotes = await this.getArrayByDates(pusherResult.pusherDownNotes);
			}
			if (pusherResult.pusherUpNotes.length > 0) {
				data.pusherUpNotes = await this.getArrayByDates(pusherResult.pusherUpNotes);
			}
			if (pusherResult.otherNotes.length > 0) {
				data.otherNotes = await this.getArrayByDates(pusherResult.otherNotes);
			}
		}

		this.generateFileFromDetails(data, userDetails, selectedReport);
	}

	generateFileFromDetails(data, userDetails, selectedReport) {
		this.setState({ isLoading: true });
		const folderName = userDetails.uid + '/dailyreports/';
		generateReport(data, selectedReport.reportType, folderName)
			.then(async (res) => {
				selectedReport.url = res.data.url;
				const db = firebase.firestore();
				await db.doc(`rigReports/${selectedReport.$key}`).update(selectedReport);
				fileAttachment({url: res.data.url})
					.then((res) => {
						saveAs(res.data.file, selectedReport.title);
						this.setState({ isLoading: false });
					}).catch(() => {
						// console.log("file attachment err::", error);
					});
			}).catch(() => {
				// console.log("err::", err);
			});
	}

	handleUpdate(key) {
		this.props.history.push(`/report/pusher/${key}`);
	}

	async handleDelete(report) {
		const db = firebase.firestore();
		const reportsRef = await db.collection('rigReports').where('$key', '==', report.$key);
		reportsRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					// await db.collection('rigReports').doc(doc.id).delete();
					report.archive = true;
					await db.collection('rigReports').doc(doc.id).update(report);
					await this.getRigById(report.rigId);
					this.updateLogs();
					this.getReports();
				}
			});
		});
	}

	updateLogs = async() => {
		const { rig } = this.state;
		await addActivityLogs(rig, 'delete', 'Pusher Report', rig.$key)
			.then(() => {
				alert('Report is deleted successfully');
			});
	}

	reportSearch(value) {
		const { reports } = this.state;
		this.setState({ searchInput: value });
		const string = value.toLowerCase();
		const temp = [];
		if (value !== '') {
			for (let i = 0; i < reports.length; i++) {
				const data = (reports[i].title !== undefined) &&( reports[i].title.toLowerCase().match(string) || reports[i].rigName.toLowerCase().match(string) ||
					reports[i].rigNumber.toLowerCase().match(string) || reports[i].rigJobNumber.toLowerCase().match(string));
				if (data !== null && Array.isArray(data)) {
					temp.push(reports[i]);
				}
			}
			this.setState({ searchList: temp });
		}
	}

	fromDate(date) {
		const { to } = this.state;
		if (!isEmpty(date)) {
			this.setState({ from: moment(date).format('YYYY-MM-DD') });
			const database = firebase.firestore();
			if (!isEmpty(to)) {
				const reportRef = database.collection('rigReports').where('date', '>=', moment(date).format('YYYY-MM-DD')).where('date', '<=', to).orderBy('date', 'desc');
				this.searchReports(reportRef);
			} else {
				const reportRef = database.collection('rigReports').where('date', '>=', moment(date).format('YYYY-MM-DD')).orderBy('date', 'desc');
				this.searchReports(reportRef);
			}
		} else {
			this.setState({ from: '', isLoading: true });
			this.getReports();
		}
	}

	toDate(date) {
		const { from } = this.state;
		if (date !== null && !isEmpty(from)) {
			if (from < moment(date).format('YYYY-MM-DD')) {
				this.setState({ to: moment(date).format('YYYY-MM-DD') });
			}
			const database = firebase.firestore();
			const reportRef = database.collection('rigReports').where('date', '>=', from).where('date', '<=', moment(date).format('YYYY-MM-DD')).orderBy('date', 'desc');
			this.searchReports(reportRef);
		}

		if (date !== null && isEmpty(from)) {
			const database = firebase.firestore();
			const reportRef = database.collection('rigReports').where('date', '<=', moment(date).format('YYYY-MM-DD')).orderBy('date', 'desc');
			this.searchReports(reportRef);
		}

		if (date === null && isEmpty(from)) {
			this.getReports();
		}
		if (date === null && !isEmpty(from)) {
			this.fromDate(from);
		}
	}

	searchReports(reportRef) {
		const { rigs } = this.state;
		reportRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true && data.reportType === 'pusher_report' ) {
						const userIndex = rigs.map(admin => admin.$key).indexOf(data.rigId);
						data.rigName = rigs[userIndex].name;
						data.rigNumber = rigs[userIndex].number;
						if (isEmpty(data.number)) {
							temp.push(data);
						}
					}
					this.getUserDetails(data);
				}
			});
			this.setState({ reports: temp });
		});
	}

	render() {
		const { reports, isLoading, isAdmin, searchInput, searchList, isPermitted, isDeletedPermitted } = this.state;
		const { Search } = Input;

		if (isLoading === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div>
				<div className="card-body">
					<Row className="rowContent">
						<Col span={12} className="searchInput spacing-top">
							<Search
								placeholder="Enter Rig Name, Rig Number, Rig Job Number, Title"
								className="rigSearch custom-search"
								allowClear={true}
								onChange={(data) => this.reportSearch(data.target.value)}
							/>
						</Col>
						<Col className="custom-date-picker spacing-bottom" span={6} id="DropdownFixPostion">
							<span className="small-title">From:</span>
							<DatePicker
								getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
								onChange={(value) => this.fromDate(value)}
								className="date_picker"
							/>
						</Col>
						<Col className="custom-date-picker spacing-bottom" span={6} id="DropdownFixPostion">
							<span className="small-title">To:</span>
							<DatePicker
								getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
								onChange={(value) => this.toDate(value)}
								className="date_picker"
							/>
						</Col>
					</Row>
					{reports.length > 0 ?
						<Table dataSource={searchInput === '' ? reports : searchList} rowKey={(record, index) => record.uid + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
							<Column title='Rig Name' dataIndex='rigName' key='rigName' />
							<Column title='Rig Number' dataIndex='rigNumber' key='rigNumber' />
							<Column title='Rig Job Number' dataIndex='rigJobNumber' key='rigJobNumber' />
							<Column title='Title' dataIndex='title' key='title' />
							<Column title='Date' dataIndex='date' render={(_, record) =>
								record.endDate ?
									<>
										<Moment format='MMM DD, YYYY'>{record.date}</Moment> - <Moment format='MMM DD, YYYY'>{record.endDate}</Moment>
									</>
									:
									<Moment format='MMM DD, YYYY'>{record.date}</Moment>}
							/>
							<Column title='Created By' dataIndex='updatedByUser' key='updatedByUser' />
							<Column title="View" dataIndex="View" render={(_, record) =>
								this.state.reports.length >= 1 ? (
									<p className="table-action-link box-link" onClick={() => this.viewReport(record.$key)}>View</p>
								) : null}
							/>
							{isAdmin === true &&
								<Column title="Download" dataIndex="download"
									render={(_, record) =>
										// <Popconfirm disabled={!record.url} overlayClassName="custom-popconfirm" title="Are you sure to download this report?" onConfirm={() => this.handleDownload(record)}>
										<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to download this report?"
											onConfirm={() => record.url ? this.handleDownload(record) : this.fetchReportDetails(record)}>
											<a href="true" className="warning-box-link">Download</a>
											{/* {record.url ?
												<a href="true" className="warning-box-link">Download</a>
												:
												<a href="true" style={{ color: 'grey', cursor: 'default' }}>Download</a>
											} */}
										</Popconfirm>
									}
								/>
							}
							{isAdmin === true && isPermitted &&
								<Column title="Edit" dataIndex="Edit" render={(_, record) =>
									this.state.reports.length >= 1 ? (
										<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to update this report?" onConfirm={() => this.handleUpdate(record.$key)}>
											<a href="true">Edit</a>
										</Popconfirm>
									) : null}
								/>
							}
							{isAdmin === true && isDeletedPermitted &&
								<Column title="Delete" className="delete" dataIndex="Delete" render={(_, record) =>
									this.state.reports.length >= 1 ? (
										<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to delete this rig ?" onConfirm={() => this.handleDelete(record)}>
											<a href="true">Delete</a>
										</Popconfirm>
									) : null}
								/>
							}
						</Table>
						:
						<div className="card-body">
							<h3 className="card-title text-center">No reports are found</h3>
						</div>
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(PusherReportsList));