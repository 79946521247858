import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, DatePicker, Dropdown, Menu, Row, Col, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import firebase from '../../../services/firebase';
import loader from '../../../assets/imgs/loader.gif';
import { checkWritePermissions } from '../../../utils/native';
import { isEmpty } from '../../../utils/activity-logs';

class AddRig extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(this.props, 'rigs'),
			assets: [],
			data: [],
			load: false,
			validateTrigger: false,
			rig: { jobNumber: '', name: '', number: '', releaseDate: '', oldLocationName: '', operator: '', status: 'Scheduled', moveType: 'In-field' },
			isLoading: false,
			menu: (
				<Menu>
					<Menu.Item key="In-field" onClick={(value) => this.onValueChange('moveType', value.key)}>In-field</Menu.Item>
					<Menu.Item key="Out-field" onClick={(value) => this.onValueChange('moveType', value.key)}>Out-field</Menu.Item>
					<Menu.Item key="Walk" onClick={(value) => this.onValueChange('moveType', value.key)}>Walk</Menu.Item>
				</Menu>
			),
			menuStatus: (
				<Menu>
					<Menu.Item key="Active" onClick={(value) => this.onValueChange('status', value.key)}>Active</Menu.Item>
					<Menu.Item key="Scheduled" onClick={(value) => this.onValueChange('status', value.key)}>Scheduled</Menu.Item>
					<Menu.Item key="Completed" onClick={(value) => this.onValueChange('status', value.key)}>Completed</Menu.Item>
					<Menu.Item key="Canceled" onClick={(value) => this.onValueChange('status', value.key)}>Cancelled</Menu.Item>
				</Menu>
			),
			rigTypeMenu: (
				<Menu>
					<Menu.Item key="Apex" onClick={(value) => this.onInputChange('type', value.key)}>Apex</Menu.Item>
					<Menu.Item key="BOSS" onClick={(value) => this.onInputChange('type', value.key)}>BOSS</Menu.Item>
					<Menu.Item key="Box on Box" onClick={(value) => this.onInputChange('type', value.key)}>Box on Box</Menu.Item>
					<Menu.Item key="Conventional" onClick={(value) => this.onInputChange('type', value.key)}>Conventional</Menu.Item>
					<Menu.Item key="Down-Hole" onClick={(value) => this.onInputChange('type', value.key)}>Down-Hole</Menu.Item>
					<Menu.Item key="F-Rigs" onClick={(value) => this.onInputChange('type', value.key)}>F-Rigs</Menu.Item>
					<Menu.Item key="Flex 3" onClick={(value) => this.onInputChange('type', value.key)}>Flex 3</Menu.Item>
					<Menu.Item key="Flex 3S" onClick={(value) => this.onInputChange('type', value.key)}>Flex 3S</Menu.Item>
					<Menu.Item key="Flex 3W" onClick={(value) => this.onInputChange('type', value.key)}>Flex 3W</Menu.Item>
					<Menu.Item key="Flex 4" onClick={(value) => this.onInputChange('type', value.key)}>Flex 4</Menu.Item>
					<Menu.Item key="Flex 5" onClick={(value) => this.onInputChange('type', value.key)}>Flex 5</Menu.Item>
					<Menu.Item key="M-Rigs" onClick={(value) => this.onInputChange('type', value.key)}>M-Rigs</Menu.Item>
					<Menu.Item key="NOV" onClick={(value) => this.onInputChange('type', value.key)}>NOV</Menu.Item>
					<Menu.Item key="Other" onClick={(value) => this.onInputChange('type', value.key)}>Other</Menu.Item>
					<Menu.Item key="Percussion" onClick={(value) => this.onInputChange('type', value.key)}>Percussion</Menu.Item>
					<Menu.Item key="RIG STYLE" onClick={(value) => this.onInputChange('type', value.key)}>RIG STYLE</Menu.Item>
					<Menu.Item key="ROCKET" onClick={(value) => this.onInputChange('type', value.key)}>ROCKET</Menu.Item>
					<Menu.Item key="Rotary" onClick={(value) => this.onInputChange('type', value.key)}>Rotary</Menu.Item>
					<Menu.Item key="S-Rigs" onClick={(value) => this.onInputChange('type', value.key)}>S-Rigs</Menu.Item>
					<Menu.Item key="SLING SHOT" onClick={(value) => this.onInputChange('type', value.key)}>SLING SHOT</Menu.Item>
					<Menu.Item key="Super Single" onClick={(value) => this.onInputChange('type', value.key)}>Super Single</Menu.Item>
					<Menu.Item key="Tele-Double" onClick={(value) => this.onInputChange('type', value.key)}>Tele-Double</Menu.Item>
					<Menu.Item key="Triple Box" onClick={(value) => this.onInputChange('type', value.key)}>Triple Box</Menu.Item>
					<Menu.Item key="WOLFSLAYER" onClick={(value) => this.onInputChange('type', value.key)}>WOLFSLAYER</Menu.Item>
					<Menu.Item key="Workover" onClick={(value) => this.onInputChange('type', value.key)}>Workover</Menu.Item>
					<Menu.Item key="X-Rigs" onClick={(value) => this.onInputChange('type', value.key)}>X-Rigs</Menu.Item>
				</Menu>
			)
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'rigs') });
		}
	}

	onInputChange(key, value) {
		const { rig } = this.state;
		rig[key] = value;
		this.setState({ ...rig });

		if (rig.estDays && rig.actualDaysOfRigmove && rig.estDays !== '' && rig.actualDaysOfRigmove !== '') {
			rig.estimatedVsActual = rig.actualDaysOfRigmove - rig.estDays;
		} else {
			rig.estimatedVsActual = 0;
		}


		if (rig.actualDaysOfRigmove && rig.actualDaysOfRigmove !== '') {
			if ((rig.NptHours) && (rig.NptHours !== 0)) {
				rig.WoNptDays = rig.actualDaysOfRigmove - rig.NptDays;
				rig.WoNptHours = 24 - rig.NptHours;

				if (((rig.NptDays === 0) || (!rig.NptDays)) && (rig.NptHours !== 0)) {
					rig.NptDays = 0;
					rig.WoNptDays = rig.actualDaysOfRigmove;
					rig.WoNptHours = 24 - rig.NptHours;
				}
				if (rig.WoNptDays >= 1) {
					rig.WoNptDays -= 1;
				}
				if ((rig.WoNptDays > 0) && (rig.WoNptDays < 1)) {
					rig.WoNptHours = 0;
					rig.WoNptDays += 1;
				}
			} else {
				rig.WoNptDays = rig.actualDaysOfRigmove - rig.NptDays;
				rig.WoNptHours = 0;
			}
		}
		if ((rig.NptHours === 0)) {
			rig.NptHours = 0;
			rig.WoNptHours = 0;
		}
		if ((rig.NptDays === 0) && (rig.NptHours === 0)) {
			rig.NptDays = 0;
			rig.WoNptDays = 0;
			rig.NptHours = 0;
			rig.WoNptHours = 0;
		}
	}

	onChange(key, value) {
		const { rig } = this.state;
		// rig['releaseDate'] = moment(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
		rig[key] = isEmpty(value) ? '' : moment(value).format('YYYY-MM-DDTHH:mm:ss');
		this.setState({ ...rig });
	}

	onValueChange(key, value) {
		const oldRig = this.state.rig;
		key === 'moveType' ? oldRig.moveType = value : oldRig.status = value;
		this.setState({ rig: oldRig });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { form } = this.props;
		const { rig } = this.state;
		form.validateFields(async (err) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				this.setRigCounters();
				this.setState({ errorMessage: '', isLoading: true });

				const result = await this.checkDuplicateRigs();
				this.setState({ isLoading: false });
				if (result.res === false) {
					alert(`Rig with same '${result.field}' already exists!`);
				}
				if (result.res === true) {
					rig.uid = localStorage.getItem('user');
					rig.createdAt = moment().valueOf();
					rig.updatedAt = rig.createdAt;
					rig.archive = false;

					const db = firebase.firestore();
					await db.collection('rigs').add(rig)
						.then((res) => {
							this.updateLogs(res.id);
						})
						.catch(() => { });
				}
			}
		});
	}

	setRigCounters() {
		const { rig } = this.state;
		let count = {
			pusher: 0,
			companies: 0,
			drillingRig: 0,
			rigDown: {
				permits: 0,
				nonPermits: 0
			},
			rigUp: {
				permits: 0,
				nonPermits: 0
			},
			reports: 0,
			tofs: 0,
			jsa: 0,
			assets: 0
		};
		rig['counters'] = count;
		this.setState({ ...rig });
	}

	checkDuplicateRigs() {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const safetyReportsRef = db.collection('rigs').orderBy('createdAt', 'desc');
			safetyReportsRef.get().then((rigRef) => {
				let flag = false;

				rigRef.forEach((doc) => {
					if (doc.data().archive !== true) {
						const data = doc.data();
						data.$key = doc.id;

						const currentDate = moment();
						const endDate = moment(data.createdAt);
						const days = Math.round(moment.duration((moment(currentDate)).diff(moment(endDate))).asDays());
						if (flag === false) {
							if (this.compareDetails('name', data) && (days >= 0 && days < 8)) {
								flag = true;
								resolve({ res: false, field: 'Rig Name' });
							}
							if (this.compareDetails('number', data) && (days >= 0 && days < 8)) {
								flag = true;
								resolve({ res: false, field: 'Rig ID (number)' });
							}
							if (this.compareDetails('jobNumber', data) && (days >= 0 && days < 8)) {
								flag = true;
								resolve({ res: false, field: 'Job Number' });
							}
						}
					}
				});
				resolve({ res: true });
			});
		});
	}

	compareDetails(key, data) {
		const { rig } = this.state;
		if ((data[key]).toLowerCase() === (rig[key]).toLowerCase()) {
			return true;
		} else {
			return false;
		}
	}

	async updateLogs(id) {
		const { rig } = this.state;
		const data = {
			uid: rig.uid,
			createdAt: moment().valueOf(),
			type: 'rigs',
			type_of_action: 'add',
			rigId: id,
			number: rig.number,
			name: rig.name
		};
		const db = firebase.firestore();
		await db.collection('activity-logs').add(data);
		// this.props.history.push('/rigs');
		this.props.history.replace(`/rigs/optional/${id}`, { add: true });
	}

	render() {
		const { validateTrigger, rig, menu, menuStatus, rigTypeMenu, isLoading } = this.state;
		const { form: { getFieldDecorator } } = this.props;
		const { Option } = Select;

		if (isLoading === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div className="edit-rig-area create-new-user-part">
				<h4 className="card-title no-mb">Create New Rig</h4>
				<div className="card-body">
					<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="theme-form">
						<Row>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Job Number">
									{getFieldDecorator('jobNumber', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter job number' }
										],
										initialValue: rig.jobNumber,
									})(
										<Input onChange={(e) => this.onInputChange('jobNumber', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Name">
									{getFieldDecorator('name', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter rig name' }
										],
										initialValue: rig.name
									})(
										<Input onChange={(e) => this.onInputChange('name', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig ID (number)">
									{getFieldDecorator('number', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter rig number' }
										],
										initialValue: rig.number
									})(
										<Input onChange={(e) => this.onInputChange('number', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Drilling Rig Manager / Tool Pusher">
									{getFieldDecorator('manager', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.manager
									})(
										<Input onChange={(e) => this.onInputChange('manager', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Phone">
									{getFieldDecorator('phoneNumber', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.phoneNumber
									})(
										<Input onChange={(e) => this.onInputChange('phoneNumber', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Email">
									{getFieldDecorator('email', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ type: 'email', message: 'Invalid email address' },
										],
										initialValue: rig.email
									})(
										<Input autoComplete="email" onChange={(e) => this.onInputChange('email', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							{/* <Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Superintendent">
									{getFieldDecorator('superintendent', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.superintendent
									})(
										<Input onChange={(e) => this.onInputChange('superintendent', e.target.value)} />,
									)}
								</Form.Item>
							</Col> */}
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Rig Release Date">
									{getFieldDecorator('releaseDate', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.releaseDate !== '' ? moment(rig.releaseDate) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											style={{ width: '100%' }}
											showTime
											format="DD-MMM-YY HH:mm"
											onChange={(e) => this.onChange('releaseDate', e)}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Old Location/Well Name">
									{getFieldDecorator('oldLocationName', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.oldLocationName
									})(
										<Input onChange={(e) => this.onInputChange('oldLocationName', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="New Location/Well Name">
									{getFieldDecorator('newLocationName', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.newLocationName
									})(
										<Input onChange={(e) => this.onInputChange('newLocationName', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Operator">
									{getFieldDecorator('operator', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.operator
									})(
										<Input onChange={(e) => this.onInputChange('operator', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Operator - Company Man">
									{getFieldDecorator('operatorName', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.operatorName
									})(
										<Input onChange={(e) => this.onInputChange('operatorName', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Operator - Company Man Phone">
									{getFieldDecorator('operatorPhone', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.operatorPhone
									})(
										<Input onChange={(e) => this.onInputChange('operatorPhone', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Operator - Company Man Email">
									{getFieldDecorator('operatorEmail', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ type: 'email', message: 'Invalid email address' },
										],
										initialValue: rig.operatorEmail
									})(
										<Input autoComplete="email" onChange={(e) => this.onInputChange('operatorEmail', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							{/* <Col md={24} xl={12} span={24}>
								<Form.Item label="Operator DSM">
									{getFieldDecorator('operatorEngineer', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.operatorEngineer
									})(
										<Input onChange={(e) => this.onInputChange('operatorEngineer', e.target.value)} />,
									)}
								</Form.Item>
							</Col> */}
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Rig Type">
									{getFieldDecorator('type', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.type
									})(
										<Dropdown
											getPopupContainer={() => document.getElementById('DropdownFixPostion')}
											overlayClassName="custom-dropdown-option"
											className="custom-dropdown-part"
											overlay={rigTypeMenu}
											placement="bottomCenter"
											arrow
										>
											<Button size="large">{rig.type}</Button>
										</Dropdown>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Move Type">
									{getFieldDecorator('moveType', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.moveType
									})(
										<Dropdown
											getPopupContainer={() => document.getElementById('DropdownFixPostion')}
											overlayClassName="custom-dropdown-option"
											className="custom-dropdown-part"
											overlay={menu}
											placement="bottomCenter"
											arrow
										>
											<Button size="large">{rig.moveType}</Button>
										</Dropdown>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Status">
									{getFieldDecorator('status', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.status
									})(
										<Dropdown
											getPopupContainer={() => document.getElementById('DropdownFixPostion')}
											overlayClassName="custom-dropdown-option"
											className="custom-dropdown-part"
											overlay={menuStatus}
											placement="bottomCenter"
											arrow
										>
											<Button size="large">{rig.status !== 'Canceled' ? rig.status : 'Cancelled'}</Button>
										</Dropdown>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Est. Miles to move">
									{getFieldDecorator('estMiles', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.estMiles,

									})(
										<Input type="number" onChange={(e) => this.onInputChange('estMiles', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Est. days to move">
									{getFieldDecorator('estDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.estDays
									})(
										<Input type="number" onChange={(e) => this.onInputChange('estDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Actual move days">
									{getFieldDecorator('actualDaysOfRigmove', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.actualDaysOfRigmove,
									})(
										<Input type="number" onChange={(e) => this.onInputChange('actualDaysOfRigmove', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Estimated vs Actual">
									{getFieldDecorator('estimatedVsActual', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.estimatedVsActual
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('estimatedVsActual', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Weather conditions">
									{getFieldDecorator('weather', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.weather,
									})(
										<Select
											getPopupContainer={() => document.getElementById('DropdownFixPostion')}
											size="large"
											mode="multiple"
											dropdownClassName="custom-select-option"
											className="custom-select-multiple"
											onChange={(value) => this.onInputChange('weather', value)}
										>
											<Option value="Hot/Dry">Hot/Dry</Option>
											<Option value="Sunny/Clear">Sunny/Clear</Option>
											<Option value="Partially Cloudy">Partially Cloudy</Option>
											<Option value="Cloudy">Cloudy</Option>
											<Option value="Overcast">Overcast</Option>
											<Option value="Windy">Windy</Option>
											<Option value="Rain">Rain</Option>
											<Option value="Drizzle">Drizzle</Option>
											<Option value="Foggy">Foggy</Option>
											<Option value="Snow">Snow</Option>
											<Option value="Stormy">Stormy</Option>
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion"></Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<p style={{ color: 'red' }}>Start Rig Move Clock with<span>1st Safety Meeting, 1st Truck Load or 1st Crane Pick</span></p>
								<Form.Item label="Start Rig Move Clock">
									{getFieldDecorator('startRigClock', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.startRigClock && rig.startRigClock !== '' ? moment(rig.startRigClock) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('startRigClock', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<p style={{ color: 'red' }}>Stop Rig Move Clock with Last Load or Last Crane Pick</p>
								<Form.Item label="Stop Rig Move Clock">
									{getFieldDecorator('stopRigClock', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.stopRigClock && rig.stopRigClock !== '' ? moment(rig.stopRigClock) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('stopRigClock', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
						<Form.Item className="button-section">
							<Button type="primary" htmlType="submit" size="large" className="submitBtn">
								Save & Continue
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(AddRig)));