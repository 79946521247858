import React, { Component } from 'react';
import firebase from '../../services/firebase';
import loader from '../../assets/imgs/loader.gif';
import { Table, Tabs, Input, Row, Col, Checkbox } from 'antd';
import { withRouter } from 'react-router-dom';
import '../users/users.css';
import './permission.css';
import { isEmpty } from '../../utils/activity-logs';
import moment from 'moment';

const { TabPane } = Tabs;
const { Search } = Input;
class Permissions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rig: '',
			isLoading: false,
			losEmployees: [],
			checkboxLoading: false,
			rigs: [],
			isAdmin: localStorage.getItem('admin') === 'true' ? true : false,
			admins: [],
			selectedTab: 1,
			searchedUsers: [],
			searchInput: '',
			validateTrigger: false,
			selectedUID: '',
			userColumns: [
				{
					title: 'First Name',
					dataIndex: 'firstName',
					key: 'firstName',
					align: 'left',
					width: 100,
					fixed: 'left'
				},
				{
					title: 'Last Name',
					dataIndex: 'lastName',
					key: 'lastName',
					align: 'left',
					width: 100,
					fixed: 'left'
				},
				{
					title: 'Group',
					dataIndex: 'group',
					key: 'group',
					align: 'left',
					render: (group) => group && group.map((groups) => groups).join(),
					width: 150,
					fixed: 'left'
				},
				{
					title: 'Rigs',
					dataIndex: 'rigs',
					key: 'rigs',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'rigs'),
				},
				{
					title: 'Users',
					dataIndex: 'users',
					key: 'users',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'users'),
				},
				{
					title: 'Pusher',
					dataIndex: 'pusher',
					key: 'pusher',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'pusher'),
				},
				{
					title: 'Reports',
					dataIndex: 'reports',
					key: 'reports',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'reports'),
				},
				{
					title: 'Safety Form / Report',
					dataIndex: 'losSafety',
					key: 'losSafety',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'losSafety'),
				},
				{
					title: 'Smart Groups',
					dataIndex: 'smartGroups',
					key: 'smartGroups',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'smartGroups'),
				},
				{
					title: 'Rig Pics',
					dataIndex: 'rigPics',
					key: 'rigPics',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'rigPics'),
				},
				{
					title: 'Assets',
					dataIndex: 'assets',
					key: 'assets',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'assets'),
				},
				{
					title: 'Individual Loads',
					dataIndex: 'individualLoads',
					key: 'individualLoads',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'individualLoads'),
				},
			],
			shopList: [
				{ key: 'admin', value: 'Admin-Receptionist' }, { key: 'asst_manager', value: 'Asst. Manager' }, { key: 'contractor', value: 'Contractor' },
				{ key: 'crane', value: 'Crane Operator' }, { key: 'dispatch', value: 'Dispatch' }, { key: 'field', value: 'Field' },
				{ key: 'forklift', value: 'Forklift (DOT)' }, { key: 'contract', value: 'HSE - Advisor (Contract)' }, { key: 'trainer', value: 'HSE - Advisor (Field Trainer)' },
				{ key: 'coordinator', value: 'HSE - Coordinator' }, { key: 'director', value: 'HSE - Director' }, { key: 'ofc_manager', value: 'OFC Manager' },
				{ key: 'office', value: 'Office' }, { key: 'shop', value: 'Shop' },
			]
		};
	}

	componentDidMount() {	
		this.setState({ isLoading: true });
		this.getLOSEmployees(true);
	}

	getLOSEmployees(value) {
		const { shopList } = this.state;
		const db = firebase.firestore();
		if (value === true) {
			this.setState({ isLoading: true });
		}
		const safetyReportsRef = db.collection('users').orderBy('createdAt', 'desc');
		safetyReportsRef.get().then((querySnapshot) => {
			const losTemp = []; 
			const admin = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (!isEmpty(data.selectedShop)) {
						const shops = shopList.filter((item) => {
							if (data.selectedShop.indexOf(item.key) !== -1 || data.selectedShop.indexOf(item.value) !== -1) {
								return item.value;
							}
							return null;
						});
						data.selectedShop = shops.map((key) => key.value);
						// data.selectedShop = data.selectedShop.map((id) => shopList.find(x => x.key === id).value).join(', ');
					}

					if (data.archive !== true) {
						if (data.userType === 'admin') {
							data.userType1 = 'ADMIN';
							admin.push(data);
						} 
						if (data.userType === 'LOS') {
							data.userType1 = 'LOS';
							losTemp.push(data);
						}
					}
				}
			});
			this.setState({ isLoading: false });
			this.setState({ losEmployees: losTemp, admins: admin });
		});
	}

	onChange = (key) => {
		this.setState({ selectedTab: parseInt(key), searchInput: '', searchedUsers: [] });
	};

	userHeader = () => {
		const { searchInput } = this.state;
		return (
			<>
				<Row className="search-rig-header">
					<Col className="search-btn-part" span={4}>
					</Col>
					<Col className="search-btn-part" span={20}>
						<Search
							placeholder="Search..."
							onSearch={value => this.rigSearch(value)}
							style={{ width: '100%' }}
							className="rigSearch"
							allowClear={true}
							onChange={data => this.rigSearch(data.target.value)}
							value={searchInput}
						/>
					</Col>
				</Row>
			</>
		);
	}

	userLists(users) {
		let { userColumns } = this.state;
		return (
			<div style={{ marginTop: 20 }}>
				<Row>
					<Col span={24}>
						<Table className="shadow-table" columns={userColumns} dataSource={users} rowKey={record => record.$key} scroll={{ x: 1500, y: 430 }} />
					</Col>
				</Row>
			</div>
		);
	}

	rigSearch(value) {
		const { selectedTab, losEmployees, admins, users } = this.state;
		let searched = [];
		if (selectedTab === 1) {
			searched = losEmployees;
		}
		if (selectedTab === 2) {
			searched = admins;
		}
		if (selectedTab === 3) {
			searched = users;
		}
		const string = value.toLowerCase();
		const temp = [];

		for (let i = 0; i < searched.length; i++) {
			const data = searched[i].firstName.toLowerCase().match(string) || searched[i].lastName.toLowerCase().match(string) || searched[i].email.toLowerCase().match(string);
			if (data !== null) {
				temp.push(searched[i]);
			}
		}
		this.setState({ searchedUsers: temp, searchInput: value, loaded: true });
	}

	renderModule(record, moduleType) {
		const { checkboxLoading } = this.state;
		return (
			record.modulePermissions.modules.map((module, index) => {
				return (
					module.type === moduleType && (
						<Row className="fixedtable-checkbox-row" key={index}>
							<Col className='colContent'>
								{checkboxLoading === false &&
									<>
										<Checkbox checked={module.read} onChange={() => this.updateReadPermission(record, index, 'read', !module.read, module.write, module.delete)} /> View
									</>
								}
							</Col>
							{moduleType !== 'individualLoads' && <Col className='colContent'><Checkbox checked={module.write} onChange={() => this.updateWritePermission(record, index, 'write', !module.write)} /> Edit</Col>}
							<Col className='colContent'><Checkbox checked={module.delete} onChange={() => this.updateDeletePermission(record, index, 'delete', !module.delete)} /> Delete</Col>
						</Row>
					));
			})
		);
	}

	async updateReadPermission(record, index, permissionType, value, writeValue, deleteValue) {
		if (permissionType === 'read' && writeValue === false && (isEmpty(deleteValue) || deleteValue === false)) {
			this.setState({ checkboxLoading: true });
			record.modulePermissions.modules[index][permissionType] = value;
			record.modulePermissions.updateBy = localStorage.getItem('user');
			record.modulePermissions.updateAt = moment().valueOf();
			this.setState({ checkboxLoading: false });

			const db = firebase.firestore();
			await db.doc(`users/${record.$key}`).update(record)
				// .then(() => this.setState({ isLoading: false }))
				.catch(() => { });
		}
	}

	async updateWritePermission(record, index, permissionType, value) {
		this.setState({ checkboxLoading: true });
		if (permissionType === 'write' && value === true) {
			record.modulePermissions.modules[index][permissionType] = value;
			record.modulePermissions.modules[index]['read'] = value;
		} else {
			record.modulePermissions.modules[index][permissionType] = value;
		}
		record.modulePermissions.updateBy = localStorage.getItem('user');
		record.modulePermissions.updateAt = moment().valueOf();
		this.setState({ checkboxLoading: false });

		const db = firebase.firestore();
		await db.doc(`users/${record.$key}`).update(record)
			// .then(() => this.setState({ isLoading: false }))
			.catch(() => { });
	}

	async updateDeletePermission(record, index, permissionType, value) {
		this.setState({ checkboxLoading: true });
		if (permissionType === 'delete' && value === true) {
			record.modulePermissions.modules[index][permissionType] = value;
			record.modulePermissions.modules[index]['read'] = value;
		} else {
			record.modulePermissions.modules[index][permissionType] = value;
		}
		record.modulePermissions.updateBy = localStorage.getItem('user');
		record.modulePermissions.updateAt = moment().valueOf();
		this.setState({ checkboxLoading: false });

		const db = firebase.firestore();
		await db.doc(`users/${record.$key}`).update(record)
			.catch(() => { });
	}

	render() {
		const { isLoading, losEmployees, admins, searchedUsers, selectedTab, searchInput } = this.state;
		if (isLoading === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
		return (
			<div className="card-tab-body">
				<div className="custom-tabs no-mt">
					<Tabs type="card test" defaultActiveKey="1" onChange={this.onChange} centered>
						<TabPane tab={`Mobile Users (${losEmployees.length})`} key="1">
							{this.userHeader()}
							{selectedTab === 1 && searchedUsers.length === 0 && searchInput === '' ? this.userLists(losEmployees, 'LOS') : this.userLists(searchedUsers, 'LOS')}
						</TabPane>
						<TabPane tab={`Admins (${admins.length})`} key="2">
							{this.userHeader()}
							{selectedTab === 2 && searchedUsers.length === 0 && searchInput === '' ? this.userLists(admins) : this.userLists(searchedUsers)}
						</TabPane>
					</Tabs>
				</div>
			</div>
		);
	}
}

export default withRouter(Permissions);
