import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../services/firebase';
import Moment from 'react-moment';
import rec from '../../assets/imgs/rec.png';
import { withRouter } from 'react-router-dom';
import { checkWritePermissions } from '../../utils/native';

class AssetsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assets: [],
			assetsLoadMore: false,
			isPermitted: checkWritePermissions(this.props, 'assets')
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'assets') });
		}
	}

	componentDidMount() {
		this.getAssetsData();
	}
	getAssetsData() {
		const db = firebase.firestore();
		const picsRef = db.collection('assets');
		picsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			if (temp.length !== 25) {
				this.setState({ assetsLoadMore: true });
			}
			this.setState({ assets: temp });
		});
	}
	getAssetsLoadMoreData() {
		
		const db = firebase.firestore();
		const picsRef = db.collection('assets').orderBy('createdAt', 'desc').startAt(this.state.assets[this.state.assets.length - 1].createdAt).limit(25);
		picsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			if (temp.length !== 25) {
				this.setState({ assetsLoadMore: true });
			}
			this.setState({ assets: [...this.state.assets, ...temp] });
		});
	}
	render() {
		const { assets, assetsLoadMore, isPermitted } = this.state;
		return (
			<div>
				{assets.length > 0 &&
					<div className="rigImage-part">
						<div className="rigImage-row" id="rig_pics">
							{assets.map((picture, index) => {
								return (
									<div className="rigImage-box" key={index}>
										{/* <div onClick={() => this.props.history.push(`/assets/${picture.rigId}`)}> */}
										<div className="inner-area" onClick={() => isPermitted && this.props.history.replace(`/assets/${picture.$key}`)}>
											{picture.url ?
												<img src={picture.url} alt="assest" className="rig-photo" />
												:
												<img src={rec} onLoad={this.onLoad} alt="no_rigpics" className="rig-photo" />
											}
											<h4><Moment format="MMM DD, YYYY">{picture.createdAt}</Moment></h4>
											<p className='picSection-time'><Moment format="h:mm a">{picture.createdAt}</Moment></p>
										</div>
									</div>
								);
							})}
						</div>
						{!assetsLoadMore && <button className="site-btn" onClick={() => this.getAssetsLoadMoreData()} >load more Data</button>}
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(AssetsList));