/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../services/firebase';
import { withRouter } from 'react-router-dom';
import { Table, Popconfirm, Row, Col, DatePicker, Input, Tabs } from 'antd';
import loader from '../../assets/imgs/loader.gif';
import { saveAs } from 'file-saver';
import { fileAttachment, generateReport } from '../../services/auth';
import Moment from 'react-moment';
import * as moment from 'moment';
import { addActivityLogs, isEmpty } from '../../utils/activity-logs';
import { checkDeletePermissions, checkWritePermissions } from '../../utils/native';

const { Column } = Table;
const { TabPane } = Tabs;
class ReportsData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			isDeletedPermitted: checkDeletePermissions(props, 'reports'),
			isLoading: false,
			reports: [],
			reportsLoadMore: false,
			isAdmin: localStorage.getItem('admin') === 'true' ? true : false,
			rigs: [],
			searchInput: '',
			searchList: [],
			from: null,
			to: null,
			users: [],
			rig: '',
			selectedTab: 1,
			Rig_Update_Reports: [],
			Customer_Reports: [],
			Permit_Reports: [],
			LOS_Rig_Report:  [],
			mastLowered: { days: 0, hours: 0, minutes: 0 },
			mastRaised: { days: 0, hours: 0, minutes: 0 },
			subsLowered: { days: 0, hours: 0, minutes: 0 },
			subsRaised: { days: 0, hours: 0, minutes: 0 },
			nippleUpBope: { days: 0, hours: 0, minutes: 0 },
			beginBope: { days: 0, hours: 0, minutes: 0 },
			finishBope: { days: 0, hours: 0, minutes: 0 },
			pickupBha: { days: 0, hours: 0, minutes: 0 },
			spud: { days: 0, hours: 0, minutes: 0 },
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports'), isDeletedPermitted: checkDeletePermissions(this.props, 'reports') });
		}
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		this.getUsers();
		/* Commented out this as below function we are already calling from getUsers*/
		// this.getRigs();
	}

	getUsers() {
		const db = firebase.firestore();
		const rigs = db.collection('users');
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					temp.push(doc.data());
				}
			});
			this.setState({
				users: temp
			});
			this.getRigs(false);
		});
	}

	getRigs() {
		const db = firebase.firestore();
		const rigsRef = db.collection('rigs').orderBy('createdAt', 'asc');
		rigsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					// if (data.archive !== true) {
					temp.push(data);
					// }
				}
			});
			this.setState({ rigs: temp });
			this.getReports();
		});
	}

	getReports() {
		const { rigs } = this.state;
		const db = firebase.firestore();
		const reportsRef = db.collection('rigReports').where('reportType', 'not-in', ['pusher_report', 'load_list_report']);
		reportsRef.get().then((querySnapshot) => {
			const temp = [];
			const Rig_Update_Reports = [];
			const Customer_Reports = [];
			const Permit_Reports = [];
			const LOS_Rig_Reports = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists && rigs) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						const userIndex = rigs.map(admin => admin.$key).indexOf(data.rigId);
						if (userIndex !== -1 && rigs[userIndex].archive !== true) {
							data.rigName = rigs[userIndex].name;
							data.rigNumber = rigs[userIndex].number;
							data.rigJobNumber = rigs[userIndex].jobNumber;
							if (isEmpty(data.number)) {
								temp.push(data);
							}
							if(data.reportType==='update_report'){
								Rig_Update_Reports.push(data);
							}else if(data.reportType==='customer_report'){
								Customer_Reports.push(data);
							}else if(data.reportType==='permit_report' && data.individual !== true){
								Permit_Reports.push(data);
							}else if(data.reportType==='los_report'){
								LOS_Rig_Reports.push(data);
							}
							this.setState({ Rig_Update_Reports, Customer_Reports, Permit_Reports, LOS_Rig_Reports, isLoading: false });
						}
						this.getUserDetails(data);
					}
				}
			});
			this.setState({ reports: temp, isLoading: false });
		}).catch(() => {
			this.setState({ reports: [], isLoading: false });
		});
	}

	getUserDetails(data) {
		const { users } = this.state;
		const userIndex = users.map(user => user.uid).indexOf(data.uid);
		if (userIndex !== -1) {
			data.updatedByUser = users[userIndex].firstName + ' ' + users[userIndex].lastName;
		}
	}

	reportsLoadMoreData() {
		const db = firebase.firestore();
		const reportsRef = db.collection('rigReports').orderBy('generatedOn', 'desc').startAt(this.state.reports[this.state.reports.length - 1].generatedOn).limit(25);
		reportsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			this.setState({ reports: [...this.state.reports, ...temp] });
		});
	}

	getRigById(rigId) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const rigsRef = db.collection('rigs').where('$key', '==', rigId);
			rigsRef.get().then((querySnapshot) => {
				let rigDetails;
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						rigDetails = doc.data();
						rigDetails.$key = doc.id;
					}
				});
				this.setState({ rig: rigDetails });
				// resolve(true);
				resolve(rigDetails);
			});
		});
	}

	viewReport(report) {
		if (report.reportType === 'los_report') {
			this.props.history.push(`/los_report/${report.$key}`);
		} else {
			this.props.history.push(`/report/${report.$key}`);
		}
	}

	calculateTimeDifference = async (rigDetails) => {
		if (rigDetails.startClock === 'bumpPlug') {
			const result = await this.calculateDiff(rigDetails.bumpPlug, rigDetails);
			return result;
		} else {
			const result = await this.calculateDiff(rigDetails.releaseDate, rigDetails);
			return result;
		}
	};

	calculateDiff = (key, rigDetails) => {
		let  mastLowered, mastRaised, subsLowered, subsRaised, nippleUpBope, beginBope, finishBope, pickupBha, spud;
		if (rigDetails.mastLowered !== null) {
			mastLowered = this.elapsedTime(rigDetails.mastLowered, key);
		}
		if (rigDetails.mastRaised !== null) {
			mastRaised = this.elapsedTime(rigDetails.mastRaised, key);
		}
		if (rigDetails.subsLowered !== null) {
			subsLowered = this.elapsedTime(rigDetails.subsLowered, key);
		}
		if (rigDetails.subsRaised !== null) {
			subsRaised = this.elapsedTime(rigDetails.subsRaised, key);
		}
		if (rigDetails.nippleUpBope !== null) {
			nippleUpBope = this.elapsedTime(rigDetails.nippleUpBope, key);
		}
		if (rigDetails.beginBope !== null) {
			beginBope = this.elapsedTime(rigDetails.beginBope, key);
		}
		if (rigDetails.finishBope !== null) {
			finishBope = this.elapsedTime(rigDetails.finishBope, key);
		}
		if (rigDetails.pickupBha !== null) {
			pickupBha = this.elapsedTime(rigDetails.pickupBha, key);
		}
		if (rigDetails.spud !== null) {
			spud = this.elapsedTime(rigDetails.spud, key);
		}
		return {
			mastLowered: mastLowered,
			mastRaised: mastRaised,
			subsLowered: subsLowered,
			subsRaised: subsRaised,
			nippleUpBope: nippleUpBope,
			beginBope: beginBope,
			finishBope: finishBope,
			pickupBha: pickupBha,
			spud: spud,
		};
	};

	/* ---------------------------- Elapsed Time ---------------------------- */
	elapsedTime = (t1, t2) => {
		const time1 = moment(t1);
		const time2 = moment(t2);
		const days = time2.diff(time1, 'days');
		let hours;
		if (days > 0) {
			hours = time2.diff(time1, 'hours') - (days * 24);
		} else {
			hours = time2.diff(time1, 'hours');
		}
		const minutes = +moment.utc(moment(time2, 'HH:mm:ss').diff(moment(time1, 'HH:mm:ss'))).format('mm');
		return {
			days: days,
			hours: hours,
			minutes: minutes
		};
	};

	rigmoveClockCount = async (rigDetails) => {
		if ((rigDetails.startClock && !rigDetails.endClock) || (rigDetails.startClock && rigDetails.endClock === '')) {
			if (rigDetails.startClock === 'startRigClock' && (rigDetails.startRigClock === undefined || rigDetails.startRigClock === '')) {
				const rigClock = await this.noDateDiff();
				return rigClock;
			} else {
				const startDate = rigDetails.startRigClock && moment(rigDetails.startRigClock.replace('Z', ''));
				const currentDate = moment();
				const rigClock = await this.calculateDateDiff(moment(startDate), currentDate, false);
				return rigClock;
			}
		}
		if (rigDetails.startClock === 'startRigClock' && rigDetails.endClock === 'stopRigClock') {
			if (rigDetails.startRigClock && rigDetails.stopRigClock && rigDetails.startRigClock !== null && rigDetails.stopRigClock !== '') {
				const rigClock = await  this.calculateDateDiff(rigDetails.startRigClock, rigDetails.stopRigClock, true);
				return rigClock;
			} else if (rigDetails.stopRigClock === '') {
				const startDate = moment(rigDetails.startRigClock);
				const currentDate = moment();
				const rigClock = await this.calculateDateDiff(startDate, currentDate, true);
				return rigClock;
			} else {
				const rigClock = await this.noDateDiff();
				return rigClock;
			}
		}
	};

	calculateDateDiff = (date1, date2, utc) => {
		let date = { days: 0, hour: 0, minutes: 0 };
		if (utc === true) {
			const release = moment(date1);
			const pickup = moment(date2);
			date.days = pickup.diff(release, 'days');
			date.hour = pickup.diff(release, 'hours') - (date.days * 24);
			date.minutes = +moment.utc(moment(pickup, 'HH:mm:ss').diff(moment(release, 'HH:mm:ss'))).format('mm');
			return date;
		} else {
			const currentDate = moment(date2, 'MM-DD-YYYY HH:mm:ss').valueOf();
			const startDate = moment(date1, 'MM-DD-YYYY HH:mm:ss').valueOf();
			var temp = Math.abs(currentDate - startDate) / 1000;
			date.days = Math.floor(temp / 86400);
			date.hour = Math.floor(temp / 3600) % 24;
			date.minutes = Math.floor(temp / 60) % 60;
			return date;
		}
	};

	noDateDiff = () => {
		let date = { days: 0, hour: 0, minutes: 0 };
		return date;
	};

	getUserById(userId) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const usersRef = db.collection('users').where('uid', '==', userId);
			usersRef.get().then((querySnapshot) => {
				let userDetails;
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						userDetails = doc.data();
						userDetails.$key = doc.id;
					}
				});
				resolve(userDetails);
			});
		});
	}

	getLoadCountByRigId(collectionName, rigId) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const loadsRef = db.collection(collectionName).where('rigId', '==', rigId).orderBy('time', 'desc');
			loadsRef.get().then((querySnapshot) => {
				let details;
				const totalNotes = [], arrivedNotes = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						details = doc.data();
						details.$key = doc.id;
						if (details.archive !== true) {
							totalNotes.push(details);
							if (details.arrived_count === true) {
								arrivedNotes.push(details);
							}
						}
					}
				});
				resolve({ totalNotes, arrivedNotes });
			});
		});
	}

	getCompaniesByRigId(rigId, selectedDate, endDate) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			let companiesRef;
			if (!endDate) {
				companiesRef = db.collection('rigCompanies').where('rigId', '==', rigId).where('date', '==', selectedDate).orderBy('createdAt', 'desc');
			} else {
				companiesRef = db.collection('rigCompanies').where('rigId', '==', rigId).where('date', '>=', selectedDate).where('date', '<=', endDate);
			}
			companiesRef.get().then((querySnapshot) => {
				let details;
				const companies = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						details = doc.data();
						details.$key = doc.id;
						if (details.archive !== true) {
							companies.push(details);
						}
					}
				});
				companies.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
				resolve(companies);
			});
		});
	}

	getLoadsByRigId(collectionName, rigId, selectedDate, endDate, permitLoad) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			let query;
			if (!endDate) {
				query = db.collection(collectionName).where('rigId', '==', rigId).where('date', '==', selectedDate).orderBy('time', 'desc');
			} else {
				query = db.collection(collectionName).where('rigId', '==', rigId).where('date', '>=', selectedDate).where('date', '<=', endDate);
			}
			query.get().then((querySnapshot) => {
				let loads = [];
				querySnapshot.forEach(note => {
					let item = note.data();
					item.$key = note.id;
					if (!item.archive) {
						if (!isEmpty(permitLoad) && item.permitLoad === permitLoad) {
							loads.push(item);
						}
						if (isEmpty(permitLoad)) {
							loads.push(item);
						}
					}
				});
				resolve({ list: loads });
			});
		});
	}

	getTaskSummaryByRigId(rigId, selectedDate, endDate) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			let query;
			if (!endDate) {
				query = db.collection('drilling-rig').where('rigId', '==', rigId).where('type', '==', 'Summary').where('date', '==', selectedDate).orderBy('time', 'asc');
			} else {
				query = db.collection('drilling-rig').where('rigId', '==', rigId).where('type', '==', 'Summary').where('date', '>=', selectedDate).where('date', '<=', endDate);
			}
			query.get().then((querySnapshot) => {
				let rigDown = [], rigUp = [], other = [];
				querySnapshot.forEach(note => {
					let item = note.data();
					item.$key = note.id;
					if (!item.archive) {
						if (item.selectType === 'Rig Down') {
							rigDown.push(item);
						}
						if (item.selectType === 'Rig Up') {
							rigUp.push(item);
						}
						if (item.selectType === undefined) {
							other.push(item);
						}
					}
				});
				resolve({ cannedSummary: rigDown, cannedUpSummary: rigUp, drillingSummary: other });
			});
		});
	}

	getColDetailsByRigId(collectionName, rigId, selectedDate, endDate) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			let query;
			if (!endDate) {
				query = db.collection(collectionName).where('rigId', '==', rigId).where('date', '==', selectedDate).orderBy('createdAt', 'desc');
			} else {
				query = db.collection(collectionName).where('rigId', '==', rigId).where('date', '>=', selectedDate).where('date', '<=', endDate);
			}
			query.get().then(async(querySnapshot) => {
				let rigPics = [], picsList = [];
				querySnapshot.forEach(note => {
					let item = note.data();
					item.$key = note.id;
					if (!item.archive) {
						rigPics.push(item);
					}
				});
				if (rigPics.length > 0) {
					picsList = await this.getArrayByDates(rigPics);

					picsList.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
					resolve(picsList);
				} else {
					resolve(rigPics);
				}
			});
		});
	}

	getArrayByDates = (list) => {
		return new Promise(async(resolve) => {
			const indexesArray = [], indexes = [];
			for(const i in list) {
				if (!list[i].archive) {
					const index = indexes.indexOf(list[i].date);
					if (index === -1) {
						indexes.push(list[i].date);
						const newIndex = indexes.indexOf(list[i].date);
						indexesArray[newIndex] = {
							title: list[i].createdAt,
							items: []
						};
						indexesArray[newIndex].items.push(list[i]);
					} else {
						if (!indexesArray[index]) {
							indexesArray[index] = {
								title: list[i].createdAt,
								items: []
							};
						}
						indexesArray[index].items.push(list[i]);
					}
				}
				if (parseInt(i) === (list.length - 1)) {
					resolve(indexesArray);
				}
			}
		});
	};

	handleDownload(selectedReport) {
		this.setState({ isLoading: true });
		// saveAs(selectedReport.url, selectedReport.title);
		fileAttachment(selectedReport)
			.then((res) => {
				saveAs(res.data.file, selectedReport.title);
				this.setState({ isLoading: false });
			});
	}

	async fetchReportDetails(selectedReport) {
		const rigDetails = await this.getRigById(selectedReport.rigId);
		const result = await this.calculateTimeDifference(rigDetails);
		const rigclock = await this.rigmoveClockCount(rigDetails);
		const userDetails = await this.getUserById(selectedReport.uid);

		const downNotes = await this.getLoadCountByRigId('Rig-Down', selectedReport.rigId);
		const upNotes = await this.getLoadCountByRigId('Rig-Up', selectedReport.rigId);

		const total = downNotes.totalNotes.concat(upNotes.totalNotes);
		const dataArray = [], index = [];
		total.filter((item) => {
			const itemDate = moment(item.date).format('YYYY-MM-DD');
			const noteIndex = index.indexOf(itemDate);
			if (noteIndex === -1) {
				index.push(itemDate);
				dataArray.push({ title: itemDate, count: 1 });
			} else {
				dataArray[noteIndex].count = dataArray[noteIndex].count + 1;
			}
			return dataArray;
		});
		dataArray.sort((a, b) => (a.title < b.title) ? 1 : -1);
		const dailyCount = dataArray;

		const companies = await this.getCompaniesByRigId(selectedReport.rigId, selectedReport.date, selectedReport.endDate);

		const data = {
			generatedOn: selectedReport.generatedOn,
			selectedDate: selectedReport.date,
			endDate: selectedReport.endDate,
			rig: {
				...rigDetails,
				releaseDate: typeof (rigDetails.releaseDate) === 'string' ? rigDetails.releaseDate : ''
			},
			mastLowered: result.mastLowered,
			mastRaised: result.mastRaised,
			subsLowered: result.subsLowered,
			subsRaised: result.subsRaised,
			nippleUpBope: result.nippleUpBope,
			beginBope: result.beginBope,
			finishBope: result.finishBope,
			pickupBha: result.pickupBha,
			spud: result.spud,
			date: rigclock,
			notes: dailyCount,
			companies: companies,
			reportCreatedBy: {
				firstName: userDetails.firstName,
				lastName: userDetails.lastName,
				email: userDetails.email
			}
		};

		if (selectedReport.reportType === 'update_report') {
			this.generateFileFromDetails(data, userDetails, selectedReport);
		}

		let queryDown, queryUp;
		if (selectedReport.reportType !== 'permit_report') {
			queryDown = await this.getLoadsByRigId('Rig-Down', selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			queryUp = await this.getLoadsByRigId('Rig-Up', selectedReport.rigId, selectedReport.date, selectedReport.endDate);
		} else {
			queryDown = await this.getLoadsByRigId('Rig-Down', selectedReport.rigId, selectedReport.date, selectedReport.endDate, true);
			queryUp = await this.getLoadsByRigId('Rig-Up', selectedReport.rigId, selectedReport.date, selectedReport.endDate, true);
		}
		const rigDownNotes = queryDown.list;
		const rigUpNotes = queryUp.list;

		if (rigDownNotes.length > 0) {
			data.rigDownNotes = await this.getArrayByDates(rigDownNotes);
		}
		if (rigUpNotes.length > 0) {
			data.rigUpNotes = await this.getArrayByDates(rigUpNotes);
		}
		if (selectedReport.reportType !== 'los_report' ) {
			this.generateFileFromDetails(data, userDetails, selectedReport);
		}
		if (selectedReport.reportOptions.all === true) {
			const summaryResult = await this.getTaskSummaryByRigId(selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			if (summaryResult.cannedSummary.length > 0) {
				data.cannedSummary = await this.getArrayByDates(summaryResult.cannedSummary);
			}
			if (summaryResult.drillingSummary.length > 0) {
				data.cannedUpSummary = await this.getArrayByDates(summaryResult.drillingSummary);
			}
			if (summaryResult.cannedUpSummary.length > 0) {
				data.drillingSummary = await this.getArrayByDates(summaryResult.cannedUpSummary);
			}
			const pusherResult = await this.getColDetailsByRigId('pusher', selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			if (pusherResult.length) {
				data.pusherNotes = pusherResult;
			}
			const assets = await this.getColDetailsByRigId('assets', selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			if (assets.length) {
				data.assets = assets;
			}
			const rigpics = await this.getColDetailsByRigId('rigpics', selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			if (rigpics.length) {
				data.rigpics = rigpics;
			}
			const equiList = await this.getColDetailsByRigId('equipments', selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			if (equiList.length) {
				data.equiList = equiList;
			}
		} else {
			const summaryResult = await this.getTaskSummaryByRigId(selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			if (selectedReport.reportOptions.drillingSummaryDown && selectedReport.reportOptions.drillingSummaryDown === true && summaryResult.cannedSummary.length > 0) {
				data.cannedSummary = await this.getArrayByDates(summaryResult.cannedSummary);
			}
			if (selectedReport.reportOptions.drillingSummary && selectedReport.reportOptions.drillingSummary === true && summaryResult.drillingSummary.length > 0) {
				data.cannedUpSummary = await this.getArrayByDates(summaryResult.drillingSummary);
			}
			if (selectedReport.reportOptions.drillingSummaryUp && selectedReport.reportOptions.drillingSummaryUp === true && summaryResult.cannedUpSummary.length > 0) {
				data.drillingSummary = await this.getArrayByDates(summaryResult.cannedUpSummary);
			}
			const pusherResult = await this.getColDetailsByRigId('pusher', selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			if (selectedReport.reportOptions.pusher && selectedReport.reportOptions.pusher === true && pusherResult.length) {
				data.pusherNotes = pusherResult;
			}
			const assets = await this.getColDetailsByRigId('assets', selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			if (selectedReport.reportOptions.assets && selectedReport.reportOptions.assets === true && assets.length) {
				data.assets = assets;
			}
			const rigpics = await this.getColDetailsByRigId('rigpics', selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			if (selectedReport.reportOptions.pics && selectedReport.reportOptions.pics === true && rigpics.length) {
				data.rigpics = rigpics;
			}
			const equiList = await this.getColDetailsByRigId('equipments', selectedReport.rigId, selectedReport.date, selectedReport.endDate);
			if (selectedReport.reportOptions.equipmentInventory && selectedReport.reportOptions.equipmentInventory === true && equiList.length) {
				data.equiList = equiList;
			}

			if (selectedReport.reportOptions.company && selectedReport.reportOptions.company !== true) {
				delete data.companies;
			}
			if (selectedReport.reportOptions.tasks && selectedReport.reportOptions.tasks !== true) {
				delete data.rigDownNotes;
				delete data.rigUpNotes;
			}
		}
		this.generateFileFromDetails(data, userDetails, selectedReport);
	}

	generateFileFromDetails(data, userDetails, selectedReport) {
		this.setState({ isLoading: true });
		const folderName = userDetails.uid + '/dailyreports/';
		generateReport(data, selectedReport.reportType, folderName)
			.then(async (res) => {
				selectedReport.url = res.data.url;
				const db = firebase.firestore();
				await db.doc(`rigReports/${selectedReport.$key}`).update(selectedReport);
				fileAttachment({url: res.data.url})
					.then((res) => {
						saveAs(res.data.file, selectedReport.title);
						this.setState({ isLoading: false });
					}).catch(() => {
					});
			}).catch(() => {
			});
	}

	handleUpdate(key) {
		this.props.history.push(`/report/reports/${key}`);
	}

	async handleDelete(report) {
		const db = firebase.firestore();
		const reportsRef = await db.collection('rigReports').where('$key', '==', report.$key);
		reportsRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					// await db.collection('rigReports').doc(doc.id).delete();
					report.archive = true;
					await db.collection('rigReports').doc(doc.id).update(report);
					await this.getRigById(report.rigId);
					this.updateLogs(report);
					this.getReports();
				}
			});
		});
	}

	updateLogs = async(report) => {
		const { rig } = this.state;
		let type;

		switch(report.reportType) {
			case 'update_report' : type = 'Rig Update Report';
				break;
			case 'customer_report': type = 'Customer Rig Report';
				break;
			case 'los_report': type = 'LOS Rig Report';
				break;
			case 'permit_report': type = 'Permit Report';
				break;
			default: break;
		}
		await addActivityLogs(rig, 'delete', type, rig.$key)
			.then(() => {
				alert('Report is deleted successfully');
			});
	}

	reportSearch(value) {
		const { reports, selectedTab, Rig_Update_Reports, Customer_Reports, Permit_Reports, LOS_Rig_Reports } = this.state;
		this.setState({ searchInput: value });
		let searched = [];
		const string = value.toLowerCase();
		const temp = [];
		if (selectedTab === 1) {
			searched = Rig_Update_Reports;
		}
		if (selectedTab === 2) {
			searched = Customer_Reports;
		}
		if (selectedTab === 3) {
			searched = Permit_Reports;
		}
		if (selectedTab === 4) {
			searched = LOS_Rig_Reports;
		}
		if (selectedTab === 5) {
			searched = reports;
		}
		if (value !== '') {
			for (let i = 0; i < searched.length; i++) {
				let data = (searched[i].title !== undefined) && (searched[i].title.toLowerCase().match(string) || searched[i].rigName.toLowerCase().match(string) ||
				searched[i].rigNumber.toLowerCase().match(string) || searched[i].rigJobNumber.toLowerCase().match(string)
					|| searched[i].reportType.toLowerCase().match(string));
				if (data !== null && Array.isArray(data)) {
					temp.push(searched[i]);
				}
			}
			this.setState({ searchList: temp });
		}
	}

	fromDate(date) {
		const { to } = this.state;
		if (date !== null) {
			this.setState({ from: moment(date).format('YYYY-MM-DD') });
			const database = firebase.firestore();
			if (to !== null) {
				const reportRef = database.collection('rigReports').where('date', '>=', moment(date).format('YYYY-MM-DD')).where('date', '<=', to).orderBy('date', 'desc');
				this.searchReports(reportRef);
			} else {
				const reportRef = database.collection('rigReports').where('date', '>=', moment(date).format('YYYY-MM-DD')).orderBy('date', 'desc');
				this.searchReports(reportRef);
			}
		} else {
			this.setState({ from: '', isLoading: true, to: null });
			this.getReports();
		}
	}

	toDate(date) {
		const { from } = this.state;
		if (date !== null && from !== null) {
			if (from < moment(date).format('YYYY-MM-DD')) {
				this.setState({ to: moment(date).format('YYYY-MM-DD') });
			}
			const database = firebase.firestore();
			const reportRef = database.collection('rigReports').where('date', '>=', from).where('date', '<=', moment(date).format('YYYY-MM-DD')).orderBy('date', 'desc');
			this.searchReports(reportRef);
		}

		if (date !== null && from === '') {
			const database = firebase.firestore();
			const reportRef = database.collection('rigReports').where('date', '<=', moment(date).format('YYYY-MM-DD')).orderBy('date', 'desc');
			this.searchReports(reportRef);
		}

		if (date === null && from === null) {
			this.setState({ isLoading: true, to: null });
			this.getReports();
		}
	}

	searchReports(reportRef) {
		const { rigs, selectedTab } = this.state;
		reportRef.get().then((querySnapshot) => {
			const temp = [];
			const Rig_Update_Reports = [];
			const Customer_Reports = [];
			const Permit_Reports = [];
			const LOS_Rig_Reports = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true && data.reportType !== 'pusher_report' && data.reportType !== 'load_list_report') {
						const userIndex = rigs.map(admin => admin.$key).indexOf(data.rigId);
						if (userIndex !== -1 && rigs[userIndex].archive !== true) {
							data.rigName = rigs[userIndex].name;
							data.rigNumber = rigs[userIndex].number;
							data.rigJobNumber = rigs[userIndex].jobNumber;
							if (isEmpty(data.number)) {
								temp.push(data);
							}
							if(data.reportType==='update_report'){
								Rig_Update_Reports.push(data);
							}else if(data.reportType==='customer_report'){
								Customer_Reports.push(data);
							}else if(data.reportType==='permit_report' && data.individual !== true){
								Permit_Reports.push(data);
							}else if(data.reportType==='los_report'){
								LOS_Rig_Reports.push(data);
							}
							// this.setState({ Rig_Update_Reports, Customer_Reports, Permit_Reports, LOS_Rig_Reports, isLoading: false });
						}
						this.getUserDetails(data);
					}
				}
			});
			// this.setState({ reports: temp });
			if (selectedTab === 1) {
				this.setState({ Rig_Update_Reports, isLoading: false });
			}
			if (selectedTab === 2) {
				this.setState({ Customer_Reports, isLoading: false });
			}
			if (selectedTab === 3) {
				this.setState({ Permit_Reports, isLoading: false });
			}
			if (selectedTab === 4) {
				this.setState({ LOS_Rig_Reports, isLoading: false });
			}
			if (selectedTab === 5) {
				this.setState({ reports: temp, isLoading: false });
			}
		});
	}

	rigsHeader = () => {
		const { Search } = Input;
		const { isLoading } = this.state;

		if (isLoading === true) {
			return (
				<div className='loader'>
					<img src={loader} alt='loader' />
				</div>
			);
		}
		return (
			<Row className='rowContent search-rig-header'>
				<Col span={12} className='searchInput spacing-top'>
					<Search
						placeholder='Enter Rig Name, Rig Number, Rig Job Number, Title, Type'
						className='rigSearch custom-search'
						allowClear={true}
						onChange={(data) => this.reportSearch(data.target.value)}
					/>
				</Col>
				<Col
					className='custom-date-picker spacing-bottom'
					span={6}
					id='DropdownFixPostion'
				>
					<span className='small-title'>From:</span>
					<DatePicker
						onChange={(value) => this.fromDate(value)}
						className='date_picker'
					/>
				</Col>
				<Col
					className='custom-date-picker spacing-bottom'
					span={6}
					id='DropdownFixPostion1'
				>
					<span className='small-title'>To:</span>
					<DatePicker
						onChange={(value) => this.toDate(value)}
						className='date_picker'
					/>
				</Col>
			</Row>
		);
	}

	rigsLists = (lists) => {
		const { reports, isAdmin, isPermitted, isDeletedPermitted, isLoading } = this.state;

		if (isLoading === true) {
			return (
				<div className='loader'>
					<img src={loader} alt='loader' />
				</div>
			);
		}
		if(reports.length > 0){
			return <Table dataSource={lists} rowKey={(record, index) => record.uid + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
				<Column title='Rig Name' dataIndex='rigName' key='rigName' />
				<Column title='Rig Number' dataIndex='rigNumber' key='rigNumber' />
				<Column title='Rig Job Number' dataIndex='rigJobNumber' key='rigJobNumber' />
				<Column title='Title' dataIndex='title' render={(_, record) => record.title === undefined ? <span>Rig Part</span> : record.title} />
				<Column title='Date' dataIndex='date' render={(_, record) =>
					record.endDate ?
						<>
							<Moment format='MMM DD, YYYY'>{record.date}</Moment> - <Moment format='MMM DD, YYYY'>{record.endDate}</Moment>
						</>
						:
						<Moment format='MMM DD, YYYY'>{record.date}</Moment>}
				/>
				<Column title='Type' dataIndex='reportType' render={(_, record) => this.getReportType(record)} />
				<Column title='Created By' dataIndex='updatedByUser' key='updatedByUser' />
				<Column title='View' dataIndex='View' render={(_, record) =>
					this.state.reports.length >= 1 ? (
						<p className='table-action-link box-link' onClick={() => this.viewReport(record)}>View</p>
					) : null}
				/>
				{isAdmin === true &&
					<Column title='Download' dataIndex='download'
						render={(_, record) =>
							// <Popconfirm disabled={!record.url} overlayClassName='custom-popconfirm' title='Are you sure to download this report?' onConfirm={() => this.handleDownload(record)}>
							<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to download this report?"
								onConfirm={() => record.url ? this.handleDownload(record) : this.fetchReportDetails(record)}>
								<a href='true' className='warning-box-link'>Download</a>
								{/* {record.url ?
									<a href='true' className='warning-box-link'>Download</a>
									:
									<a href='true' style={{ color: 'grey', cursor: 'default' }}>Download</a>
								} */}
							</Popconfirm>
						}
					/>
				}
				{isAdmin === true && isPermitted &&
					<Column title='Edit' dataIndex='Edit' render={(_, record) =>
						this.state.reports.length >= 1 ? (
							<Popconfirm overlayClassName='custom-popconfirm' title='Are you sure to update this report?' onConfirm={() => this.handleUpdate(record.$key)}>
								<a href='true'>Edit</a>
							</Popconfirm>
						) : null}
					/>
				}
				{isAdmin === true && isDeletedPermitted &&
					<Column title='Delete' className='delete' dataIndex='Delete' render={(_, record) =>
						this.state.reports.length >= 1 ? (
							<Popconfirm overlayClassName='custom-popconfirm' title='Are you sure to delete this rig ?' onConfirm={() => this.handleDelete(record)}>
								<a href='true'>Delete</a>
							</Popconfirm>
						) : null}
					/>
				}
			</Table>;
		} else {
			return <div className='card-body'>
				<h3 className='card-title text-center'>No reports are found</h3>
			</div>;
		}}

	onChange = (key) => {
		this.setState({ selectedTab: parseInt(key), searchInput: '', searchedUsers: [] });
	};

	getReportType(report) {
		const type = report.reportType === 'update_report' ? 'Rig Update Report' : report.reportType === 'customer_report' ? 'Customer Rig Report' :
			report.reportType === 'permit_report' ? 'Permit Report' : report.reportType === 'los_report' ? 'LOS Rig Report' :
				report.reportType === 'load_list_report' ? 'Load List Report' : 'Truck Pusher Report';
		return type;
	}

	render() {
		const { searchInput, searchList, selectedTab, reports,Rig_Update_Reports ,Customer_Reports,Permit_Reports,LOS_Rig_Reports} = this.state;

		return (
			<div>
				<div className='card-tab-body'>
					<div className="custom-tabs no-mt">
						<Tabs type="card" defaultActiveKey="1" onChange={this.onChange}>
							<TabPane tab='Rig Update Reports' key="1">
								{this.rigsHeader()}
								{selectedTab === 1 && searchInput === '' ? this.rigsLists(Rig_Update_Reports) : this.rigsLists(searchList)}
							</TabPane>
							<TabPane tab='Customer Reports' key="2">
								{this.rigsHeader()}
								{selectedTab === 2 && searchInput === '' ? this.rigsLists(Customer_Reports) : this.rigsLists(searchList)}
							</TabPane>
							<TabPane tab='Permit Reports' key="3">
								{this.rigsHeader()}
								{selectedTab === 3 && searchInput === '' ? this.rigsLists(Permit_Reports) : this.rigsLists(searchList)}
							</TabPane>
							<TabPane tab='LOS Rig Reports' key="4">
								{this.rigsHeader()}
								{selectedTab === 4 && searchInput === '' ? this.rigsLists(LOS_Rig_Reports) : this.rigsLists(searchList)}
							</TabPane>
							<TabPane tab='All' key="5">
								{this.rigsHeader()}
								{selectedTab === 5 && searchInput === '' ? this.rigsLists(reports) : this.rigsLists(searchList)}
							</TabPane>
						</Tabs>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(ReportsData));