import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../services/firebase';
import loader from '../../../assets/imgs/loader.gif';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, Row, Col } from 'antd';
import { addActivityLogs } from '../../../utils/activity-logs';
import { checkWritePermissions } from '../../../utils/native';
class EditReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			id: this.props.match.params.id,
			report: '',
			data: [],
			rig: '',
			load: false,
			validateTrigger: false,
			errorMessage: false,
		};
	}

	onLoad = () => {
		this.setState({
			load: true
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports') });
		}
	}

	componentDidMount() {
		const db = firebase.firestore();
		const reportRef = db.collection('rigReports').doc(this.state.id);
		reportRef.get().then((querySnapshot) => {
			const res = [];
			if (querySnapshot.exists) {
				const data = querySnapshot.data();
				data.$key = querySnapshot.id;
				if (data.archive !== true) {
					res.push(data);
				}
				this.state.data.push(querySnapshot.data().date);
			}
			this.setState({
				report: res[0],
				data: this.state.data.filter((item, index, inputArray) => {
					return inputArray.indexOf(item) === index;
				})
			});
			this.getRig(res[0].rigId);
		});
	}

	getRig(rigId) {
		const db = firebase.firestore();
		const rigs = db.collection('rigs').doc(rigId);

		rigs.get().then((doc) => {
			this.setState({ Loader: false });
			// if (doc.exists && doc.data().archive !== true) {
			if (doc.exists) {
				const res = doc.data();
				res.$key = doc.id;
				res.releaseDate = res.releaseDate.replace('Z', '');
				this.setState({
					rig: res
				});
				this.getCurrentUser();
			}
		}).catch(() => {
			this.setState({ Loader: false });
		});
	}

	onInputChange(key, value) {
		const { report } = this.state;
		report[key] = value;
		this.setState({ ...report });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { form } = this.props;
		const { report } = this.state;
		form.validateFields(async (err) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				this.setState({
					errorMessage: ''
				});
				const db = firebase.firestore();
				await db.doc(`rigReports/${report.$key}`).update(report)
					.then(() => this.updateLogs())
					.catch(() => {});
			}
		});
	}

	async updateLogs() {
		const { rig, report } = this.state;
		await addActivityLogs(
			rig,
			'update',
			report.reportType === 'update_report' ? 'Rig Update Report' : report.reportType === 'customer_report' ? 'Customer Rig Report' :
				report.reportType === 'los_report' ? 'LOS Rig Report' : report.reportType === 'permit_report' ? 'Permit Report' :
					report.reportType === 'pusher_report' ? 'Pusher Report' : 'Load List Report',
			report.$key).then(() => {
			this.props.history.push(`/${this.props.match.params.report}`);
		});
	}

	render() {
		const { validateTrigger, report, rig } = this.state;
		const { form: { getFieldDecorator } } = this.props;

		if (report.$key === undefined) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div className="edit-rig-area edit-rig-asset-part">
				<h4 className="card-title">Edit Rig Report</h4>
				<div className="card-body">
					{report.$key &&
						<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="theme-form">
							<Row>
								<Col md={24} xl={12} span={24}>
									<Form.Item label="Title">
										{getFieldDecorator('title', {
											validateTrigger: validateTrigger ? 'onChange' : '',
											rules: [
												{ required: true, message: 'Please enter title' }
											],
											initialValue: report.title,
										})(
											<Input onChange={(e) => this.onInputChange('title', e.target.value)} />
										)}
									</Form.Item>
								</Col>
								<Col md={24} xl={12} span={24}>
									<Form.Item label="Date">
										{getFieldDecorator('date', {
											validateTrigger: validateTrigger ? 'onChange' : '',
											initialValue: report.date,
										})(
											<Input disabled={true} onChange={(e) => this.onInputChange('date', e.target.value)} />
										)}
									</Form.Item>
								</Col>
								<Col md={24} xl={12} span={24}>
									<Form.Item label="Rig Number">
										{getFieldDecorator('rigId', {
											validateTrigger: validateTrigger ? 'onChange' : '',
											initialValue: rig.number,
										})(
											<Input disabled={true} onChange={(e) => this.onInputChange('rigId', e.target.value)} />
										)}
									</Form.Item>
								</Col>
								<Col md={24} xl={12} span={24}>
									<Form.Item label="Report Type">
										{getFieldDecorator('reportType', {
											validateTrigger: validateTrigger ? 'onChange' : '',
											initialValue: report.reportType,
										})(
											<Input disabled={true} onChange={(e) => this.onInputChange('reportType', e.target.value)} />
										)}
									</Form.Item>
								</Col>
							</Row>
							<Form.Item className="button-section">
								<Button type="primary" htmlType="submit" size="large" className="submitBtn">Update</Button>
							</Form.Item>
						</Form>
					}
				</div>
			</div>
		);
	}
}


const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(EditReport)));
