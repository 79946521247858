import React, { Component } from 'react';
import { Icon } from 'antd';
import './mobile-preview.css';

class MobileLocation extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { details } = this.props;
		return (
			<>
				<div className="mobile-field">
					<div className="m-box-header">
						<p className="title">
							{details.required &&
								<span className="require-symbol">*</span>
							}
							{details.label}
							<span className="subtitle">{details.description}</span>
						</p>
					</div>
					<div className="m-box-content">
						<button className="add-img-btn">
							<Icon type="environment" />
                            Add Location
						</button>
					</div>
				</div>
			</>
		);
	}
}
export default MobileLocation;
