

import moment from 'moment';
import firebase from '../services/firebase';

export const addActivityLogs = (rig, action, type, moduleId) => {
	return new Promise(async(resolve) => {
		const data = {
			uid: localStorage.getItem('user'),
			createdAt: moment().valueOf(),
			type: type,
			type_of_action: action,
			rigId: rig.$key,
			number: rig.number,
			name: rig.name,
			moduleId: moduleId
		};
		const db = firebase.firestore();
		await db.collection('activity-logs').add(data);
		rig.updatedAt = moment().valueOf();
		rig.updatedBy = localStorage.getItem('user');
		await db.doc(`rigs/${rig.$key}`).update(rig);
		resolve(true);
	});
};

export const isEmpty = (value) => {
	if (value === '' || value === undefined || value === null) {
		return true;
	} else {
		return false;
	}
};