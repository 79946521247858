import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../services/firebase';
import './report.css';
import Moment from 'react-moment';
import { Row, Col } from 'antd';
// import { FaAngleLeft } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment';
import rec from '../../assets/imgs/rec.png';
import loader from '../../assets/imgs/loader.gif';
import lightning_logo from '../../assets/imgs/lightning_logo.jpeg';
import { checkWritePermissions } from '../../utils/native';

class Report extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			id: this.props.match.params.id,
			// i: this.props.match.params.i,
			rig: '',
			report: '',
			rigpics: [],
			selectedDate: '',
			data: [],
			notes: [],
			dataNotes: [],
			personnels: [],
			assets: [],
			dataAssets: [],
			companies: [],
			load: false,
			user: '',
			notifications: [],
			elaspsedTime: '',
			date: { days: 0, hour: 0, minutes: 0 },
			up_loadcount_notes: [],
			down_loadcount_notes: [],
			loadcount_notes: [],
			index: [],
			summaries: [],
			rigdown_notes: [],
			rigup_notes: [],
			dates: [],
			rigdown_dates: [],
			rigup_dates: [],
			drilling_summary: [],
			drilling_summary_dates: [],
			drilling_rigdown: [],
			drilling_rigdown_dates: [],
			drilling_rigup: [],
			drilling_rigup_dates: []
		};
	}

	onLoad = () => {
		this.setState({
			load: true
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports') });
		}
	}

	componentDidMount() {
		const db = firebase.firestore();
		const rigs = db.collection('rigReports').where('$key', '==', this.state.id);
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists && doc.data().archive !== true) {
					temp.push(doc.data());
				}
			});
			this.setState({
				report: temp[0],
				selectedDate: temp.date
			});
			const reportDetails = temp[0];
			if (reportDetails.reportType === 'update_report') {
				this.getRigDetails();
				this.getRigUpLoadCount();
				this.getRigDownLoadCount();
				this.getCompanies();
				this.getCurrentUser();
			} else if (reportDetails.individual === true) {
				this.getRigDetails();
				// this.getRigUpLoadCount();
				// this.getRigDownLoadCount();
				this.getRigDownNotes();
				this.getRigUPNotes();
			} else {
				this.getPics();
				this.getRigDetails();
				this.getRigUpLoadCount();
				this.getRigDownLoadCount();
				this.getRigDownNotes();
				this.getRigUPNotes();
				this.getAssets();
				this.getCompanies();
				this.getCurrentUser();
				// this.getNoteNotification();
			}
		});
	}

	getPics() {
		const selectedDate = moment(this.state.report.date).format('YYYY-MM-DD');


		const db = firebase.firestore();
		if (!this.state.report.endDate) {
			const picsRef = db.collection('rigpics').where('rigId', '==', this.state.report.rigId).where('date', '==', selectedDate).orderBy('createdAt', 'asc');
			picsRef.get().then((querySnapshot) => {
				const res = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.$key = doc.id;
						temp = doc.data();
						if (temp.archive !== true) {
							res.push(temp);
						}
						this.state.data.push(doc.data().date);
					}
				});
				this.setState({
					rigpics: res,
					data: this.state.data.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		} else {
			const picsRef = db.collection('rigpics').where('rigId', '==', this.state.report.rigId).where('date', '>=', selectedDate).where('date', '<=', this.state.report.endDate);
			picsRef.get().then((querySnapshot) => {
				const res = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.$key = doc.id;
						temp = doc.data();
						if (temp.archive !== true) {
							res.push(temp);
						}
						this.state.data.push(doc.data().date);
					}
				});
				this.setState({
					rigpics: res,
					data: this.state.data.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
				this.state.data.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1);
			});
		}
	}

	getAssets() {
		const selectedDate = moment(this.state.report.date).format('YYYY-MM-DD');


		const db = firebase.firestore();
		if (!this.state.report.endDate) {
			const picsRef = db.collection('assets').where('rigId', '==', this.state.report.rigId).where('date', '==', selectedDate).orderBy('createdAt', 'asc');
			picsRef.get().then((querySnapshot) => {
				const res = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.$key = doc.id;
						temp = doc.data();
						if (temp.archive !== true) {
							res.push(temp);
						}
						this.state.dataAssets.push(doc.data().date);
					}
				});
				this.setState({
					assets: res,
					dataAssets: this.state.dataAssets.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		} else {
			const picsRef = db.collection('assets').where('rigId', '==', this.state.report.rigId).where('date', '>=', selectedDate).where('date', '<=', this.state.report.endDate);
			picsRef.get().then((querySnapshot) => {
				const res = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.$key = doc.id;
						temp = doc.data();
						if (temp.archive !== true) {
							res.push(temp);
						}
						this.state.dataAssets.push(doc.data().date);
					}
				});
				this.setState({
					assets: res,
					dataAssets: this.state.dataAssets.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
				this.state.dataAssets.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1);
			});
		}
	}

	getRigDetails() {

		const databse = firebase.firestore();
		if (this.state.report.rigId) {
			const rigsRef = databse.collection('rigs').doc(this.state.report.rigId);
			rigsRef.get().then((doc) => {
				if (doc.exists) {
					const response = doc.data();
					if (response.counters) {
						response.loadcount = response.counters.rigDown.nonPermits + response.counters.rigUp.nonPermits +
						response.counters.rigDown.permits + response.counters.rigUp.permits;
					}
					this.setState({
						rig: response,
					});
					this.rigmoveClock();
				}
			});
		}
	}

	getNotes() {
		const selectedDate = moment(this.state.report.date).format('YYYY-MM-DD');


		const database = firebase.firestore();
		if (!this.state.report.endDate) {
			const rigs = database.collection('notes').where('rigId', '==', this.state.report.rigId).where('date', '==', selectedDate).orderBy('time', 'asc');
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.push(doc.data());
					}
				});
				this.setState({
					notes: temp
				});
			});
		} else {
			const rigs = database.collection('notes').where('rigId', '==', this.state.report.rigId).where('date', '>=', selectedDate).where('date', '<=', this.state.report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.push(doc.data());
						this.state.dataNotes.push(doc.data().date);
					}
				});
				this.setState({
					notes: temp,
					dataNotes: this.state.dataNotes.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
				this.state.dataNotes.sort((a, b) => (a.time > b.time) ? -1 : 1);
				this.state.notes.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1);
			});
		}
	}
	/* ---------------------------- RigDown Notes ---------------------------- */
	getRigDownNotes() {
		const { report } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');


		const database = firebase.firestore();
		if (!report.endDate) {
			let query;
			if (report.individual === true) {
				query = database.collection('Rig-Down').where('rigId', '==', report.rigId).where('$key', '==', report.noteId);
			} else {
				query = database.collection('Rig-Down').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('time', 'asc');
			}
			query.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
					}
				});
				this.setState({
					rigdown_notes: temp
				});
			});
		} else {
			const rigs = database.collection('Rig-Down').where('rigId', '==', this.state.report.rigId).where('date', '>=', selectedDate).where('date', '<=', this.state.report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
						this.state.rigdown_dates.push(data.date);
					}
				});
				this.setState({
					rigdown_notes: temp,
					rigdown_dates: this.state.rigdown_dates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
				this.state.rigdown_dates.sort((a, b) => (a.time > b.time) ? -1 : 1);
				this.state.notes.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1);
			});
		}
	}

	/* ---------------------------- RigUp Notes ---------------------------- */
	getRigUPNotes() {
		const { report } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');


		const database = firebase.firestore();
		if (!this.state.report.endDate) {
			let query;
			if (report.individual === true) {
				query = database.collection('Rig-Up').where('rigId', '==', report.rigId).where('$key', '==', report.noteId);
			} else {
				query = database.collection('Rig-Up').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('time', 'asc');
			}
			query.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
					}
				});
				this.setState({
					rigup_notes: temp
				});
			});
		} else {
			const rigs = database.collection('Rig-Up').where('rigId', '==', this.state.report.rigId).where('date', '>=', selectedDate).where('date', '<=', this.state.report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
						this.state.rigup_dates.push(data.date);
					}
				});
				this.setState({
					rigup_notes: temp,
					rigup_dates: this.state.rigup_dates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
				this.state.rigup_dates.sort((a, b) => (a.time > b.time) ? -1 : 1);
				this.state.notes.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1);
			});
		}
	}

	/* ---------------------------- Drilling Summary Notes ---------------------------- */
	getDrillingSummaryNotes() {
		const selectedDate = moment(this.state.report.date).format('YYYY-MM-DD');


		const database = firebase.firestore();
		if (!this.state.report.endDate) {
			const rigs = database.collection('drilling-rig').where('rigId', '==', this.state.report.rigId).where('type', '==', 'Summary').where('date', '==', this.selectedDate).orderBy('time', 'asc');
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
					}
				});
				this.setState({
					drilling_summary: temp
				});
			});
		} else {
			const rigs = database.collection('drilling-rig').where('rigId', '==', this.state.report.rigId).where('type', '==', 'Summary').where('date', '>=', selectedDate).where('date', '<=', this.state.report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
						this.state.drilling_summary_dates.push(data.date);
					}
				});
				this.setState({
					drilling_summary: temp,
					drilling_summary_dates: this.state.drilling_summary_dates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
				this.state.drilling_summary_dates.sort((a, b) => (a.time > b.time) ? -1 : 1);
				this.state.notes.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1);
			});
		}
	}

	timeDifference(t1, t2) {
		const time1 = moment(t1);
		const time2 = moment(t2);
		const days = time2.diff(time1, 'days');
		let hours, time;
		if (days > 0)
			hours = time2.diff(time1, 'hours') - (days * 24);
		else
			hours = time2.diff(time1, 'hours');
		const minutes = +moment.utc(moment(time2, 'HH:mm:ss').diff(moment(time1, 'HH:mm:ss'))).format('mm');
		time = {
			days: days,
			hours: hours,
			minutes: minutes
		};
		return time;
	}
	getRigUpLoadCount() {
		const database = firebase.firestore();
		const rigs = database.collection('Rig-Up').where('rigId', '==', this.state.report.rigId).orderBy('time', 'desc');
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				data.$key = doc.id;
				if (doc.exists && data.archive !== true) {
					if (data.loadcount === true) {
						temp.push(data);
					}
				}
			});
			this.setState({
				up_loadcount_notes: temp
			});
		});
	}

	getRigDownLoadCount() {
		const database = firebase.firestore();
		const rigs = database.collection('Rig-Down').where('rigId', '==', this.state.report.rigId);
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				data.$key = doc.id;
				if (doc.exists && data.archive !== true) {
					if (data.loadcount === true) {
						temp.push(data);
					}
				}
			});
			this.setState({
				down_loadcount_notes: temp
			});
			this.totalLoadCount();
		});
	}

	totalLoadCount() {
		const current = this;
		const total_array = current.state.up_loadcount_notes.concat(current.state.down_loadcount_notes);
		current.setState({
			loadcount_notes: total_array
		});

		current.state.loadcount_notes.forEach(note => {
			const dateString = moment(note.date).format('YYYY-MM-DD');
			const index = current.state.index.indexOf(note.date);
			if (index === -1) {
				current.state.index.push(note.date);
				const newIndex = current.state.index.indexOf(note.date);
				current.state.notes[newIndex] = {
					title: dateString,
					count: 0
				};
				current.state.notes[newIndex].count++;
			}
			else {
				if (!current.state.notes[index]) {
					current.state.notes[index] = {
						title: dateString,
						count: 0
					};
				}
				current.state.notes[index].count++;
			}
		});

		current.state.notes.sort((a, b) => (a.title > b.title) ? -1 : 1);
	}

	getCompanies() {
		const selectedDate = moment(this.state.report.date).format('YYYY-MM-DD');


		const database = firebase.firestore();
		if (!this.state.report.endDate) {
			const rigs = database.collection('rigCompanies').where('rigId', '==', this.state.report.rigId).where('date', '==', selectedDate).orderBy('createdAt', 'asc');
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
					}
				});
				this.setState({
					companies: temp
				});
			});
		} else {
			const rigs = database.collection('rigCompanies').where('rigId', '==', this.state.report.rigId).where('date', '>=', selectedDate).where('date', '<=', this.state.report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
					}
				});
				this.setState({
					companies: temp
				});
			});
		}
	}

	getCurrentUser() {


		if (this.state.report.uid) {
			const db = firebase.firestore();
			const rigs = db.collection('users').where('uid', '==', this.state.report.uid);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.push(doc.data());
					}
				});
				this.setState({
					user: temp[0]
				});
			});
		}
	}

	getNoteNotification() {


		const database = firebase.firestore();
		// if(!this.state.report.endDate) {

		// based on selected date
		// const rigs = database.collection("Rig-Down").where('rigId', '==', this.state.report.rigId).where('date', '==', selectedDate).where('sendPushNotification', '==', true);
		// const rigs1 = database.collection("Rig-Up").where('rigId', '==', this.state.report.rigId).where('date', '==', selectedDate).where('sendPushNotification', '==', true);

		const rigs = database.collection('Rig-Down').where('rigId', '==', this.state.report.rigId).where('sendPushNotification', '==', true);
		const rigs1 = database.collection('Rig-Up').where('rigId', '==', this.state.report.rigId).where('sendPushNotification', '==', true);


		this.getAllNotifications(rigs, rigs1);
		// } else {
		//     const rigs = database.collection("Rig-Down").where('rigId', '==', this.state.report.rigId).where('date', '>=', selectedDate).where('date', '<=', this.state.report.endDate).where('sendPushNotification', '==', true);
		//     const rigs1 = database.collection("Rig-Up").where('rigId', '==', this.state.report.rigId).where('date', '>=', selectedDate).where('date', '<=', this.state.report.endDate).where('sendPushNotification', '==', true);
		//     this.getAllNotifications(rigs, rigs1);
		// }

	}

	getAllNotifications(rigs, rigs1) {

		const temp = [], temp1 = [];
		let temp2 = [];
		rigs.get().then((querySnapshot) => {
			// const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					temp.push(doc.data());
				}
			});
			// this.setState({
			//     notifications: temp
			// })

			// const rigs1 = database.collection("Rig-Up").where('rigId', '==', this.state.report.rigId).where('sendPushNotification', '==', true);
			rigs1.get().then((querySnapshot) => {
				// const temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						// temp.push(doc.data());
						temp1.push(doc.data());
					}
				});
				// this.setState({
				//     notifications: temp
				// })


				if (temp.length !== 0 && temp1.length !== 0) {
					temp2 = [...temp, ...temp1].slice(0, 5);
					temp2.sort((a, b) => {
						return b.createdAt - a.createdAt;
					});
					this.setState({
						notifications: temp2
					});
				} else if (temp.length !== 0) {
					this.setState({
						notifications: temp
					});
				} else {
					this.setState({
						notifications: temp1
					});
				}
			});
		});
	}

	rigmoveClock() {
		const { rig } = this.state;
		if ((rig.startClock && !rig.endClock) || (rig.startClock && rig.endClock === '')) {
			if (rig.startClock === 'startRigClock' && (rig.startRigClock === undefined || rig.startRigClock === '')) {
				this.noDateDiff();
			} else {
				const startDate = rig.startRigClock && moment(rig.startRigClock.replace('Z', ''));
				const currentDate = moment();
				this.calculateDateDiff(moment(startDate), currentDate, false);
			}
		}
		if (rig.startClock === 'startRigClock' && rig.endClock === 'stopRigClock') {
			if (rig.startRigClock && rig.stopRigClock && rig.startRigClock !== null && rig.stopRigClock !== '') {
				this.calculateDateDiff(rig.startRigClock, rig.stopRigClock, true);
			} else if (rig.stopRigClock === '') {
				const startDate = moment(rig.startRigClock);
				const currentDate = moment();
				this.calculateDateDiff(startDate, currentDate, true);
			} else {
				this.noDateDiff();
			}
		}
	}

	calculateDateDiff(date1, date2, utc) {
		const { date } = this.state;
		if (utc === true) {
			const release = moment(date1);
			const pickup = moment(date2);
			date.days = pickup.diff(release, 'days');
			date.hour = pickup.diff(release, 'hours') - (date.days * 24);
			date.minutes = +moment.utc(moment(pickup, 'HH:mm:ss').diff(moment(release, 'HH:mm:ss'))).format('mm');
			this.setState({ date });
		} else {
			const currentDate = moment(date2, 'MM-DD-YYYY HH:mm:ss').valueOf();
			const startDate = moment(date1, 'MM-DD-YYYY HH:mm:ss').valueOf();
			var temp = Math.abs(currentDate - startDate) / 1000;
			date.days = Math.floor(temp / 86400);
			date.hour = Math.floor(temp / 3600) % 24;
			date.minutes = Math.floor(temp / 60) % 60;
			this.setState({ date });
		}
	}

	noDateDiff() {
		const { date } = this.state;
		date.days = 0;
		date.hour = 0;
		date.minutes = 0;
		this.setState({ date });
	}

	rigmoveClockView() {
		return (
			<Col md={12} xl={12} span={24}>
				<div className="reportDetails">
					<h6 className="card-title">Rig Clock</h6>
					<div className="rigmoveClock">
						<p>
							{this.state.date.days > 0 && <span>{this.state.date.days} Days</span>}
							<span>{this.state.date.hour} Hrs {this.state.date.minutes} Mins</span>
						</p>
						<p>Elapsed since rig release on</p>
						{this.state.rig.releaseDate &&
							<p><Moment format='DD/MM/YYYY'>{this.state.rig.releaseDate}</Moment>
								<span> <Moment format='HH:mm'>{this.state.rig.releaseDate.replace('Z', '')}</Moment></span>
							</p>
						}
					</div>
				</div>
			</Col>
		);
	}

	render() {
		const { user, report } = this.state;
		if (this.state.rig && this.state.report) {
			return (
				<div className="card-body">
					<div className="report-header">
						{/* <div>
                            {this.state.rig ?
                                <Link to={{pathname:`/reportList/${this.state.rig.$key}`}}>
                                    <FaAngleLeft />
                                </Link>
                            : <FaAngleLeft />}
                        </div> */}
						<h3 className="text-center no-mb">Report Details</h3>
					</div>
					<div className="report-content">
						<div className="rig-logo">
							<img src={lightning_logo} alt="rigmove_img" />
						</div>
						<h3 className="report-title-name text-center">{this.state.rig.name}</h3>
						{/* Rig details defined in the particular rig */}
						<div className="details-part">
							<Row className="row-details">
								<Col md={24} xl={24} span={24}>
									<h6 className="card-title">RIG #{this.state.rig.number} Summary Report</h6>
									<div className="report-details dataTexts">
										{/* <p>Date: <Moment format="YYYY/MM/DD">{this.state.report.start}</Moment></p> */}
										<p className="data-texts"><b>Date:</b> <Moment format='DD-MMM-YYYY'>{this.state.report.date}</Moment></p>
										{this.state.rig.releaseDate ? <p className="data-texts"><b>Start Move:</b> <Moment format='DD-MMM-YYYY'>{this.state.rig.releaseDate}</Moment></p> : null}
										<p className="data-texts"><b>Status:</b> {this.state.rig.status !== 'Canceled' ? this.state.rig.status : 'Cancelled'}</p>
										{(this.state.report.reportType === 'los_report' || this.state.report.reportType === 'update_report') && this.state.rig.jobNumber ?
											<p className="data-texts"><b>Job Number:</b> {this.state.rig.jobNumber}</p> : null}
										<p className="data-texts"><b>Rig Name:</b> {this.state.rig.name}</p>
										<p className="data-texts"><b>Rig #:</b> {this.state.rig.number}</p>
										{this.state.rig.afeNumber ? <p className="data-texts"><b>AFE #:</b> {this.state.rig.afeNumber}</p> : null}
										<p className="data-texts"><b>Phone Number:</b> {this.state.rig.phoneNumber}</p>
										{this.state.rig.email ? <p className="data-texts"><b>Contact Email:</b> {this.state.rig.email}</p> : null}
										{this.state.rig.superintendent ? <p className="data-texts"><b>Superintendent:</b> {this.state.rig.superintendent}</p> : null}
										{this.state.rig.manager ? <p className="data-texts"><b>Manager:</b> {this.state.rig.manager}</p> : null}
										{this.state.rig.type ? <p className="data-texts"><b>Rig Type:</b> {this.state.rig.type}</p> : null}
										{this.state.rig.oldLocationName ? <p className="data-texts"><b>Old Location Name/Well name:</b> {this.state.rig.oldLocationName}</p> : null}
										{this.state.rig.oldLocation ? <p className="data-texts"><b>GPS Location:</b> {this.state.rig.oldLocation}</p> : null}
										{this.state.rig.newLocationName ? <p className="data-texts"><b>New Location Name/Well Name:</b> {this.state.rig.newLocationName}</p> : null}
										{this.state.rig.operator ? <p className="data-texts"><b>Operator:</b> {this.state.rig.operator}</p> : null}
										{this.state.rig.operatorEmail ? <p className="data-texts"><b>Operator Email:</b> {this.state.rig.operatorEmail}</p> : null}
										{this.state.rig.operatorPhone ? <p className="data-texts"><b>Operator Phone:</b> {this.state.rig.operatorPhone}</p> : null}
										{this.state.rig.operatorEngineer ? <p className="data-texts"><b>Operator Drilling Engineer:</b> {this.state.rig.operatorEngineer}</p> : null}
										{this.state.rig.moveType ? <p className="data-texts"><b>Move Type:</b> {this.state.rig.moveType}</p> : null}
										{this.state.rig.estMiles ? <p className="data-texts"><b>Est.miles to move:</b> {this.state.rig.estMiles}</p> : null}
										{this.state.rig.estDays ? <p className="data-texts"><b>Est. days to move:</b> {this.state.rig.estDays}</p> : null}
										{this.state.rig.actualDaysOfRigmove ? <p className="data-texts"><b>Actual move days:</b> {this.state.rig.actualDaysOfRigmove}</p> : null}
										{this.state.rig.NptHours ? <p className="data-texts"><b>NPT time:</b> {this.state.rig.NptHours}</p> : null}
										{this.state.rig.WoNptHours ?
											<p className="data-texts"><b>Move time W/O NPT:</b> {this.state.rig.WoNptHours} / {this.state.rig.WoNptDays}</p> : null}
										{this.state.rig.bumpPlug ? <p className="data-texts"><b>Bump the plug:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.bumpPlug}</Moment></p> : null}
										{this.state.rig.releaseDate ? <p className="data-texts"><b>Target Release Date:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.releaseDate}</Moment></p> : null}
										{this.state.rig.mastLowered ? <p className="data-texts"><b>Mast lowered:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.mastLowered}</Moment></p> : null}
										{this.state.rig.mastRaised ? <p className="data-texts"><b>Mast Raised:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.mastRaised}</Moment></p> : null}
										{this.state.rig.subsLowered ? <p className="data-texts"><b>Subs lowered:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.subsLowered}</Moment></p> : null}
										{this.state.rig.subsRaised ? <p className="data-texts"><b>Subs Raised:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.subsRaised}</Moment></p> : null}
										{this.state.rig.nippleUpBope ? <p className="data-texts"><b>Nipple up BOPE:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.nippleUpBope}</Moment></p> : null}
										{this.state.rig.beginBope ? <p className="data-texts"><b>Begin BOPE testing:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.beginBope}</Moment></p> : null}
										{this.state.rig.finishBope ? <p className="data-texts"><b>Finish BOPE testing:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.finishBope}</Moment></p> : null}
										{this.state.rig.totalBopeHours ? <p className="data-texts"><b>Total Bope Time:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.totalBopeHours}</Moment></p> : null}
										{this.state.rig.pickupBha ? <p className="data-texts"><b>Pickup BHA:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.pickupBha}</Moment></p> : null}
										{this.state.rig.spud ? <p className="data-texts"><b>Spud:</b> <Moment format="DD/MM/YY h:mm a">{this.state.rig.spud}</Moment></p> : null}
										{this.state.rig.mastPickupHours ? <p className="data-texts"><b>Mast up to pickup BHA:</b> {this.state.rig.mastPickupHours}</p> : null}
										{this.state.rig.releasePickupHours ? <p className="data-texts"><b>Release to pickup BHA:</b> {this.state.rig.releasePickupHours} / {this.state.rig.releasePickupDays}</p> : null}
										{this.state.rig.plugBumpHours ? <p className="data-texts"><b>Plug bump to pickup BHA:</b> {this.state.rig.plugBumpHours} / {this.state.rig.plugBumpDays}</p> : null}
									</div>
									<hr className="report-line" />
								</Col>
								{/* <!-- ---------------------------- Drilling Summary without endDate ---------------------------- --> */}
								{!this.state.report.endDate && this.state.drilling_summary.length !== 0 &&
									<div className='reportDetails'>
										<h6 className="card-title">Milestones - Drilling Rig Summary</h6>
										<div>
											<p><Moment format="YYYY-MM-DD">{this.state.report.date}</Moment></p>
											{this.state.drilling_summary.map((summary, index) => {
												return (
													<div key={index}>
														<span><Moment format="h:mm a">{summary.time}</Moment></span>
														<div>
															<span>{summary.description}</span>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								}
								{/* <!-- ---------------------------- Drilling Summary with endDate ---------------------------- --> */}
								{this.state.report.endDate && this.state.drilling_summary.length !== 0 &&
								<Col md={12} xl={12} span={24}>
									<div className="reportDetails">
										<h6 className="card-title">Milestones - Drilling Rig Summary</h6>
										{this.state.dates.map((date, index) => {
											return (
												<div key={index}>
													<p>{date}</p>
													{this.state.drilling_summary.map((summary, innerIndex) => {
														return (
															<div key={innerIndex}>
																<span><Moment format="h:mm a">{summary.time}</Moment></span>
																<div>
																	<span>{summary.description}</span>
																</div>
															</div>
														);
													})}
												</div>
											);
										})}
									</div>
								</Col>
								}
								{/* --------------------------- Companies --------------------------- */}
								{this.state.companies.length > 0 ?
									<Col md={12} xl={12} span={24}>
										<div className="reportDetails">
											<div className="company_details">
												{this.state.companies.length > 0 ? <p>Companies & Equipment</p> : null}
												{this.state.companies.length > 0 ?
													<div className="company_contains">
														{this.state.companies.map((company, index) => {
															return (
																<div key={index}>
																	<p>{company.name}</p>
																	<p className="company_role">{company.role}</p>
																	{company.equipment &&
																		<div>
																			<p><b>Equipment</b></p>
																			{company.equipment.map((equipment, ind) => {
																				return (
																					<div key={(company.$key + ind).toString()}>
																						<p>Date: {equipment.date}</p>
																						{equipment.day &&
																							<>
																								<p><b>Day:</b> {equipment.day}</p>
																								<div className='table-company'>
																									<Row className='table-head'>
																										<Col><p><b>Qty</b></p></Col>
																										<Col><p><b>Equipment Type</b></p></Col>
																										<Col><p><b>Day Usage</b></p></Col>
																									</Row>
																									{equipment.name.map((equi, i) => {
																										return (
																											<div className='table-body' key={i.toString()}>
																												{equi.quantity !== '' &&
																													<Row>
																														<Col>{equi.quantity}</Col>
																														<Col>{equi.type}</Col>
																														<Col>{equi.usage}</Col>
																													</Row>
																												}
																											</div>
																										);
																									})}
																								</div>
																							</>
																						}
																						{equipment.notes && <p>Notes: <span>{equipment.notes}</span></p>}
																					</div>
																				);
																			})}
																		</div>
																	}
																</div>
															);
														})}
													</div>
													: null}
											</div>
										</div>
									</Col>
									: null}
								{/* --------------------------- Rigmove Clock --------------------------- */}
								{this.state.rig && this.state.report.reportType !== 'update_report' && this.rigmoveClockView()}
								{/* --------------------------- LoadCount --------------------------- */}
								{this.state.rig &&
								<Col md={12} xl={12} span={24}>
									<div className="reportDetails">
										<h6 className="card-title">Total Load Count: {this.state.rig.loadcount ? this.state.rig.loadcount : 0}</h6>
										{this.state.notes.length !== 0 &&
											<>
												<p>Daily Load Count</p>
												<div>
													{this.state.notes.map((key, index) => {
														return (
															<p key={index}>{key.title} : <span>{key.count}</span></p>
														);
													})}
												</div>
											</>
										}
									</div>
								</Col>
								}
								{/* <---------------------------- RigDown without endDate ----------------------------> */}
								{this.state.rigdown_notes.length !== 0 && !this.state.report.endDate &&
								<Col md={24} xl={24} span={24}>
									<hr className="report-line" />
									<h6 className="card-title">Rig Down</h6>
									<div className="report-details dataTexts">
										<p className="data-texts"><Moment format="YYYY-MM-DD">{this.state.report.date}</Moment></p>
										{this.state.rigdown_notes.map((notes) => {
											return (
												<>
													<p className="data-texts"><Moment format="HH:mm">{notes.time}</Moment></p>
													{notes.sequenceNo && <p><b>Load #:</b> {notes.sequenceNo}</p>}
													{notes.companyName && <p className="data-texts"><b>Company:</b> {notes.companyName}</p>}
													{notes.truck && <p className="data-texts"><b>Truck #:</b> {notes.truck}</p>}
													{notes.load_type && <p className="data-texts"><b>Load Type (Truck):</b> {notes.load_type}</p>}
													{notes.part && <p className="data-texts"><b>Rig Part:</b> {notes.part.map((note, innerIndex) => {
														return (
															<span className="chips" key={innerIndex}>
																{note.name ? <span>{note.name}</span> : <span>{note}</span>}
															</span>
														);
													})}</p>}
													{notes.task && <p className="data-texts"><b>Task:</b> {notes.task.map((key, innerIndex) => {
														return (
															<span key={innerIndex}>{key}</span>
														);
													})}</p>}
													{notes.npt && <p className="data-texts"><b>Examine NPT:</b> {notes.npt}</p>}
													{notes && notes.dimensions && notes.dimensions.height && (notes.dimensions.height.feet || notes.dimensions.height.inches) &&
														<>
															<p className="data-texts"><b>Height:</b>
																{notes.dimensions.height.feet && <span>{notes.dimensions.height.feet} ft</span>}
																{notes.dimensions.height.inches && <span>{notes.dimensions.height.inches} inches</span>}
															</p>
														</>
													}
													{notes && notes.dimensions && notes.dimensions.width && (notes.dimensions.width.feet || notes.dimensions.width.inches) &&
														<>
															<p className="data-texts"><b>Width:</b>
																{notes.dimensions.width.feet && <span>{notes.dimensions.width.feet} ft</span>}
																{notes.dimensions.width.inches && <span>{notes.dimensions.width.inches} inches</span>}
															</p>
														</>
													}
													{notes && notes.dimensions && notes.dimensions.length && (notes.dimensions.length.feet || notes.dimensions.length.inches) &&
														<>
															<p className="data-texts"><b>Length:</b>
																{notes.dimensions.length.feet && <span>{notes.dimensions.length.feet} ft</span>}
																{notes.dimensions.length.inches && <span>{notes.dimensions.length.inches} inches</span>}
															</p>
														</>
													}
													{notes && notes.dimensions && notes.dimensions.truck && (notes.dimensions.truck.feet || notes.dimensions.truck.inches) &&
														<>
															<p className="data-texts"><b>Overhang:</b>
																{notes.dimensions.truck.feet && <span>{notes.dimensions.truck.feet} ft</span>}
																{notes.dimensions.truck.inches && <span>{notes.dimensions.truck.inches} inches</span>}
															</p>
														</>
													}
													{notes.axles && <p className="data-texts"><b># of Tractor Axles:</b> {notes.tractorAxles}</p>}
													{notes.axles && <p className="data-texts"><b># of Trailer Axles:</b> {notes.axles}</p>}
													{notes.timer && notes.timer.selectedTime && <p className="data-texts"><b>Time Start:</b> {notes.timer.selectedTime}</p>}
													{notes.timer && notes.timer.stoppedTime && <p className="data-texts"><b>Time Stop:</b> {notes.timer.stoppedTime}</p>}
													{!notes.timer.start && notes.timer.stoppedTime && <p className="data-texts"><b>Load Time:</b> {notes.timer.time}</p>}
													{notes.timer.start && notes.timer.stoppedTime && <p className="data-texts"><b>Clock:</b> <span>ON</span></p>}
													{notes.description && <p className="data-texts"><b>Description:</b>{notes.description}</p>}
												</>
											);
										})}
									</div>
								</Col>
								}
								{/* <---------------------------- RigDown with endDate ----------------------------> */}
								{this.state.rigdown_notes.length !== 0 && this.state.report.endDate &&
								<Col md={24} xl={24} span={24}>
									<hr className="report-line" />
									<div className="reportDetails">
										<p><Moment format="YYYY-MM-DD">{this.state.report.date}</Moment></p>
										<Row>
											{this.state.rigdown_notes.map((notes, index) => {
												return (
													<Col md={12} xl={12} span={24} key={index}>
														<div className="displayNotes">
															<p><Moment format="HH:mm">{notes.time}</Moment></p>
															{notes.sequenceNo && <p><b>Load #:</b> {notes.sequenceNo}</p>}
															{notes.companyName && <p><b>Company:</b> {notes.companyName}</p>}
															{notes.truck && <p><b>Truck #:</b> {notes.truck}</p>}
															{notes.load_type && <p><b>Load Type (Truck):</b> {notes.load_type}</p>}
															{notes.part && <p><b>Rig Part:</b> {notes.part.map((note, innerIndex) => {
																return (
																	<span className="chips" key={innerIndex}>
																		{note.name ? <span>{note.name}</span> : <span>{note}</span>}
																	</span>
																);
															})}</p>}
															{notes.task && <p><b>Task:</b> {notes.task.map((key, innerIndex) => {
																return (
																	<span key={innerIndex}>{key}</span>
																);
															})}</p>}
															{notes.npt && <p><b>Examine NPT:</b> {notes.npt}</p>}
															{notes && notes.dimensions && notes.dimensions.height && (notes.dimensions.height.feet || notes.dimensions.height.inches) &&
																<>
																	<p className="data-texts"><b>Height:</b>
																		{notes.dimensions.height.feet && <span>{notes.dimensions.height.feet} ft</span>}
																		{notes.dimensions.height.inches && <span>{notes.dimensions.height.inches} inches</span>}
																	</p>
																</>
															}
															{notes && notes.dimensions && notes.dimensions.width && (notes.dimensions.width.feet || notes.dimensions.width.inches) &&
																<>
																	<p className="data-texts"><b>Width:</b>
																		{notes.dimensions.width.feet && <span>{notes.dimensions.width.feet} ft</span>}
																		{notes.dimensions.width.inches && <span>{notes.dimensions.width.inches} inches</span>}
																	</p>
																</>
															}
															{notes && notes.dimensions && notes.dimensions.length && (notes.dimensions.length.feet || notes.dimensions.length.inches) &&
																<>
																	<p className="data-texts"><b>Length:</b>
																		{notes.dimensions.length.feet && <span>{notes.dimensions.length.feet} ft</span>}
																		{notes.dimensions.length.inches && <span>{notes.dimensions.length.inches} inches</span>}
																	</p>
																</>
															}
															{notes && notes.dimensions && notes.dimensions.truck && (notes.dimensions.truck.feet || notes.dimensions.truck.inches) &&
																<>
																	<p className="data-texts"><b>Overhang:</b>
																		{notes.dimensions.truck.feet && <span>{notes.dimensions.truck.feet} ft</span>}
																		{notes.dimensions.truck.inches && <span>{notes.dimensions.truck.inches} inches</span>}
																	</p>
																</>
															}
															{notes.axles && <p><b># of Tractor Axles:</b> {notes.tractorAxles}</p>}
															{notes.axles && <p><b># of Trailer Axles:</b> {notes.axles}</p>}
															{notes.timer && notes.timer.selectedTime && <p><b>Time Start:</b> {notes.timer.selectedTime}</p>}
															{notes.timer && notes.timer.stoppedTime && <p><b>Time Stop:</b> {notes.timer.stoppedTime}</p>}
															{!notes.timer.start && notes.timer.stoppedTime && <p><b>Load Time:</b> {notes.timer.time}</p>}
															{notes.timer.start && notes.timer.stoppedTime && <p><b>Clock:</b> <span>ON</span></p>}
															{notes.description && <p><b>Description:</b>{notes.description}</p>}
														</div>
													</Col>
												);
											})}
										</Row>
									</div>
								</Col>
								}
								{/* <---------------------------- RigUp without endDate ----------------------------> */}
								{this.state.rigup_notes.length !== 0 && !this.state.report.endDate &&
								<Col md={24} xl={24} span={24}>
									<hr className="report-line" />
									<h6 className="card-title">Rig Up</h6>
									<div className="report-details dataTexts">
										<p className="data-texts"><Moment format="YYYY-MM-DD">{this.state.report.date}</Moment></p>
										{this.state.rigup_notes.map((notes) => {
											return (
												<>
													<p className="data-texts"><Moment format="HH:mm">{notes.time}</Moment></p>
													{notes.sequenceNo && <p><b>Load #:</b> {notes.sequenceNo}</p>}
													{notes.companyName && <p className="data-texts"><b>Company:</b> {notes.companyName}</p>}
													{notes.truck && <p className="data-texts"><b>Truck #:</b> {notes.truck}</p>}
													{notes.load_type && <p className="data-texts"><b>Load Type (Truck):</b> {notes.load_type}</p>}
													{notes.part && <p className="data-texts"><b>Rig Part:</b> {notes.part.map((note, index) => {
														return (
															<span className="chips" key={index}>
																{note.name ? <span>{note.name}</span> : <span>{note}</span>}
															</span>
														);
													})}</p>}
													{notes.task && <p className="data-texts"><b>Task:</b> {notes.task.map((key, index) => {
														return (
															<span key={index}>{key}</span>
														);
													})}</p>}
													{notes.npt && <p className="data-texts"><b>Examine NPT:</b> {notes.npt}</p>}
													{notes && notes.dimensions && notes.dimensions.height && (notes.dimensions.height.feet || notes.dimensions.height.inches) &&
														<>
															<p className="data-texts"><b>Height:</b>
																{notes.dimensions.height.feet && <span>{notes.dimensions.height.feet} ft</span>}
																{notes.dimensions.height.inches && <span>{notes.dimensions.height.inches} inches</span>}
															</p>
														</>
													}
													{notes && notes.dimensions && notes.dimensions.width && (notes.dimensions.width.feet || notes.dimensions.width.inches) &&
														<>
															<p className="data-texts"><b>Width:</b>
																{notes.dimensions.width.feet && <span>{notes.dimensions.width.feet} ft</span>}
																{notes.dimensions.width.inches && <span>{notes.dimensions.width.inches} inches</span>}
															</p>
														</>
													}
													{notes && notes.dimensions && notes.dimensions.length && (notes.dimensions.length.feet || notes.dimensions.length.inches) &&
														<>
															<p className="data-texts"><b>Length:</b>
																{notes.dimensions.length.feet && <span>{notes.dimensions.length.feet} ft</span>}
																{notes.dimensions.length.inches && <span>{notes.dimensions.length.inches} inches</span>}
															</p>
														</>
													}
													{notes && notes.dimensions && notes.dimensions.truck && (notes.dimensions.truck.feet || notes.dimensions.truck.inches) &&
														<>
															<p className="data-texts"><b>Overhang:</b>
																{notes.dimensions.truck.feet && <span>{notes.dimensions.truck.feet} ft</span>}
																{notes.dimensions.truck.inches && <span>{notes.dimensions.truck.inches} inches</span>}
															</p>
														</>
													}
													{notes.axles && <p className="data-texts"><b># of Tractor Axles:</b> {notes.tractorAxles}</p>}
													{notes.axles && <p className="data-texts"><b># of Trailer Axles:</b> {notes.axles}</p>}
													{notes.timer && notes.timer.selectedTime && <p className="data-texts"><b>Time Start:</b> {notes.timer.selectedTime}</p>}
													{notes.timer && notes.timer.stoppedTime && <p className="data-texts"><b>Time Stop:</b> {notes.timer.stoppedTime}</p>}
													{!notes.timer.start && notes.timer.stoppedTime && <p className="data-texts"><b>Load Time:</b> {notes.timer.time}</p>}
													{notes.timer.start && notes.timer.stoppedTime && <p className="data-texts"><b>Clock:</b> <span>ON</span></p>}
													{notes.description && <p className="data-texts"><b>Description:</b>{notes.description}</p>}
												</>
											);
										})}
									</div>
								</Col>
								}
								{/* <---------------------------- RigUp with endDate ----------------------------> */}
								{this.state.rigup_notes.length !== 0 && this.state.report.endDate &&
								<Col md={24} xl={24} span={24}>
									<hr className="report-line" />
									<div className='reportDetails'>
										<p><Moment format="YYYY-MM-DD">{this.state.report.date}</Moment></p>
										<Row>
											{this.state.rigup_notes.map((notes, index) => {
												return (
													<Col md={12} xl={12} span={24} key={index}>
														<div className="displayNotes">
															<p><Moment format="HH:mm">{notes.time}</Moment></p>
															{notes.sequenceNo && <p><b>Load #:</b> {notes.sequenceNo}</p>}
															{notes.companyName && <p><b>Company:</b> {notes.companyName}</p>}
															{notes.truck && <p><b>Truck #:</b> {notes.truck}</p>}
															{notes.load_type && <p><b>Load Type (Truck):</b> {notes.load_type}</p>}
															{notes.part && <p><b>Rig Part:</b> {notes.part.map((note, innerIndex) => {
																return (
																	<span className="chips" key={innerIndex}>
																		{note.name ? <span>{note.name}</span> : <span>{note}</span>}
																	</span>
																);
															})}</p>}
															{notes.task && <p><b>Task:</b> {notes.task.map((key, index) => {
																return (
																	<span key={index}>{key}</span>
																);
															})}</p>}
															{notes.npt && <p><b>Examine NPT:</b> {notes.npt}</p>}
															{notes && notes.dimensions && notes.dimensions.height && (notes.dimensions.height.feet || notes.dimensions.height.inches) &&
																<>
																	<p className="data-texts"><b>Height:</b>
																		{notes.dimensions.height.feet && <span>{notes.dimensions.height.feet} ft</span>}
																		{notes.dimensions.height.inches && <span>{notes.dimensions.height.inches} inches</span>}
																	</p>
																</>
															}
															{notes && notes.dimensions && notes.dimensions.width && (notes.dimensions.width.feet || notes.dimensions.width.inches) &&
																<>
																	<p className="data-texts"><b>Width:</b>
																		{notes.dimensions.width.feet && <span>{notes.dimensions.width.feet} ft</span>}
																		{notes.dimensions.width.inches && <span>{notes.dimensions.width.inches} inches</span>}
																	</p>
																</>
															}
															{notes && notes.dimensions && notes.dimensions.length && (notes.dimensions.length.feet || notes.dimensions.length.inches) &&
																<>
																	<p className="data-texts"><b>Length:</b>
																		{notes.dimensions.length.feet && <span>{notes.dimensions.length.feet} ft</span>}
																		{notes.dimensions.length.inches && <span>{notes.dimensions.length.inches} inches</span>}
																	</p>
																</>
															}
															{notes && notes.dimensions && notes.dimensions.truck && (notes.dimensions.truck.feet || notes.dimensions.truck.inches) &&
																<>
																	<p className="data-texts"><b>Overhang:</b>
																		{notes.dimensions.truck.feet && <span>{notes.dimensions.truck.feet} ft</span>}
																		{notes.dimensions.truck.inches && <span>{notes.dimensions.truck.inches} inches</span>}
																	</p>
																</>
															}
															{notes.axles && <p><b># of Tractor Axles:</b> {notes.tractorAxles}</p>}
															{notes.axles && <p><b># of Trailer Axles:</b> {notes.axles}</p>}
															{notes.timer && notes.timer.selectedTime && <p><b>Time Start:</b> {notes.timer.selectedTime}</p>}
															{notes.timer && notes.timer.stoppedTime && <p><b>Time Stop:</b> {notes.timer.stoppedTime}</p>}
															{!notes.timer.start && notes.timer.stoppedTime && <p><b>Load Time:</b> {notes.timer.time}</p>}
															{notes.timer.start && notes.timer.stoppedTime && <p><b>Clock:</b> <span>ON</span></p>}
															{notes.description && <p><b>Description:</b>{notes.description}</p>}
														</div>
													</Col>
												);
											})}
										</Row>
									</div>
								</Col>
								}
								{/* --------------------------- Assets --------------------------- */}
								{this.state.assets ?
									<Col md={24} xl={24} span={24}>
										{this.state.assets.length > 0 ?
										<>
											<hr className="report-line" />
											<h6 className="card-title">Assets</h6>
											<div className="rig-pic-report">
												{this.state.dataAssets.map((dataPic) => {
													return (
														<>
															<h6 className="date-rig-pics"><Moment format="MMM DD, YYYY">{dataPic}</Moment></h6>
															<div className="rig-pics-row">
																{this.state.assets.map((pics, index) => {
																	return (
																		<div key={index} className="rig-box" style={{ 'display': (dataPic === pics.date) ? 'block' : 'none' }}>
																			{dataPic === pics.date ?
																				<div className="rig-box-inner">
																					<div className="image-part">
																						{/* Rig images attached in the report */}
																						{this.state.load ?
																							<img src={pics.url} alt="rigpics" />
																							:
																							<img src={rec} onLoad={this.onLoad} alt="no_rigpics" />
																						}
																					</div>
																					{/* description of rigpics in reports */}
																					<div className="detail-part">
																						{pics.title ? <p><b>Title:</b> {pics.title}</p> : null}
																						{pics.date ? <p><b>Date:</b> {pics.date}</p> : null}
																						{pics.companyName ? <p><b>Company:</b> {pics.companyName}</p> : null}
																						{pics.truck ? <p><b>Truck #:</b> {pics.truck}</p> : null}
																						{pics.part ? <p><b>Part:</b> {pics.part}</p> : null}
																						{pics.task ? <p><b>Task:</b> {pics.task}</p> : null}
																						{pics.description ? <p><b>Description:</b> {pics.description}</p> : null}
																						{pics.item_number ? <p><b>Item #:</b> {pics.item_number}</p> : null}
																						{pics.asset_number ? <p><b>Asset #:</b> {pics.asset_number}</p> : null}
																						{pics.po_number ? <p><b>P.O. #:</b> {pics.po_number}</p> : null}
																						{pics.quantity ? <p><b>Enter Quantity:</b> {pics.quantity}</p> : null}
																						{pics.height ? <p><b>Enter Height:</b> {pics.height}</p> : null}
																						{pics.width ? <p><b>Enter Width:</b> {pics.width}</p> : null}
																						{pics.length ? <p><b>Enter Length:</b> {pics.length}</p> : null}
																						{pics.weight ? <p><b>Enter Weight:</b> {pics.weight}</p> : null}
																						{pics.service ?
																							<p><b>Ready for service:</b>
																								{pics.service === true ?
																									<span> true</span> : <span> false </span>}
																							</p> : null}
																						{pics.repairs ?
																							<p><b>Needs repairs:</b>
																								{pics.repairs === true ?
																									<span> true</span> : <span> false </span>}
																							</p> : null}
																						{pics.repairDetails ? <p><b>Report Details:</b> {pics.repairDetails}</p> : null}
																					</div>

																				</div>
																				: null}
																		</div>
																	);
																})}
															</div>
														</>
													);
												})}
											</div>
										</>
											: null}
									</Col>
									: null}
								{/* --------------------------- Rigpics --------------------------- */}
								{this.state.rigpics ?
									<Col md={24} xl={24} span={24}>
										{this.state.rigpics.length > 0 ?
										<>
											<hr className="report-line" />
											<h6 className="card-title">Rig Pics</h6>
											<div className="rig-pic-report">
												{this.state.data.map((dataPic) => {
													return (
														<>
															<h6 className="date-rig-pics"><Moment format="MMM DD, YYYY">{dataPic}</Moment></h6>
															<div className="rig-pics-row">
																{this.state.rigpics.map((pics, index) => {
																	return (
																		<div key={index} className="rig-box" style={{ 'display': (dataPic === pics.date) ? 'block' : 'none' }}>
																			{dataPic === pics.date ?
																				<div className="rig-box-inner">
																					<div className="image-part">
																						{/* Rig images attached in the report */}
																						{this.state.load ?
																							<img src={pics.url} alt="rigpics" />
																							:
																							<img src={rec} onLoad={this.onLoad} alt="no_rigpics" />
																						}
																					</div>

																					{/* description of rigpics in reports */}
																					<div className="detail-part">
																						{pics.title ? <p><b>Title:</b> {pics.title}</p> : null}
																						{pics.date ? <p><b>Date:</b> {pics.date}</p> : null}
																						{pics.part ? <p><b>Part:</b> {pics.part}</p> : null}
																						{pics.task ? <p><b>Task:</b> {pics.task}</p> : null}
																						{pics.npt ?
																							<p><b>Possible Npt:</b>
																								{pics.npt === true ?
																									<span> true</span> : <span> false </span>}
																							</p> : null}
																						{pics.onSchedule ?
																							<p><b>On Schedule:</b>
																								{pics.onSchedule === true ?
																									<span> true</span> : <span> false </span>}
																							</p> : null}
																						{pics.description ? <p><b>Description:</b> {pics.description}</p> : null}
																					</div>
																				</div>
																				: null}
																		</div>
																	);
																})}
															</div>
														</>
													);
												})}
											</div>
										</>
											: null}
									</Col>
									: null}
								<Col md={24} xl={24} span={24}>
									<hr className="report-line" />
									{user &&
										<div className='reportDetails'>
											<p><b>Completed By:</b> {user.firstName} {user.lastName}</p>
											<p><b>Email address:</b> {user.email}</p>
											<p className='p-last-child'><b>Generated on:</b> <Moment format="MMM DD, YYYY">{report.generatedOn}</Moment> @ <Moment format='h:mm a'>{this.state.report.generatedOn}</Moment></p>
										</div>
									}
								</Col>
							</Row>
						</div>
						<div className="copy-right-content">
							<p>Copyright 2018 - all rights reserved by RigMoveApp</p>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(Report));
