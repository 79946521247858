import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../../services/firebase';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment';
import loader from '../../../../assets/imgs/loader.gif';
import { Table, Row, Col } from 'antd';
// import { AiOutlineClose } from "react-icons/ai";
import { FaCheck } from 'react-icons/fa';
import { isEmpty } from '../../../../utils/activity-logs';
import { checkWritePermissions } from '../../../../utils/native';


class LoadReports extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			id: this.props.match.params.id,
			// i: this.props.match.params.i,
			rig: '',
			report: '',
			rigpics: [],
			selectedDate: '',
			data: [],
			notes: [],
			companies: [],
			load: false,
			user: '',
			up_loadcount_notes: [],
			down_loadcount_notes: [],
			loadcount_notes: [],
			index: [],
			rigdown_notes: [],
			rigup_notes: [],
			dates: [],
			rigdown_dates: [],
			rigup_dates: [],
			currentPage: [1],
			total_notes: [],
			users: [],
			columns: [
				// {
				// 	title: "Load #",
				// 	dataIndex: "load",
				// 	key: "load",
				// 	render: (_, index) => console.info('index::: ', index)
				// },
				{
					title: 'Date & Time',
					dataIndex: 'time',
					key: 'time',
					render: (_, record) => moment(record.time).format('MMM DD, YYYY HH:mm')
				},
				{
					title: 'Created By',
					dataIndex: 'createdBy',
					key: 'createdBy',
					render: (_, record) => !isEmpty(record.createdBy) ? this.getUserDetails(record.createdBy) : ''
				},
				{
					title: 'Updated By',
					dataIndex: 'updatedBy',
					key: 'updatedBy',
					render: (_, record) => !isEmpty(record.updatedBy) ? this.getUserDetails(record.updatedBy) : ''
				},
				{
					title: 'Company',
					dataIndex: 'companyName',
					key: 'companyName',
					render: (_, record) => record.companyName
				},
				{
					title: 'Truck #',
					dataIndex: 'truck',
					key: 'truck',
					render: (_, record) => record.truck
				},
				{
					title: 'Driver Name',
					dataIndex: 'driverName',
					key: 'driverName',
					render: (_, record) => record.driverName
				},
				{
					title: 'Load Type',
					dataIndex: 'load_type',
					key: 'load_type',
					render: (_, record) => record.load_type
				},
				{
					title: 'Rig Part',
					dataIndex: 'part',
					key: 'part',
					render: (part) => part && part.map((parts) => parts.name).join(),
				},
				{
					title: 'Non-Permit',
					dataIndex: 'permitLoad',
					key: 'Non-Permit',
					render: (_, record) => record.permitLoad === false && (
						<FaCheck size={28} style={{ width: '60%' }} />
					)
				},
				{
					title: 'Permit',
					dataIndex: 'permitLoad',
					key: 'permitLoad',
					render: (_, record) => record.permitLoad === true && (
						<FaCheck size={28} style={{ width: '60%' }} />
					)
				},
				{
					title: 'Width',
					dataIndex: 'width',
					key: 'width',
					render: (_, record) => this.renderCustomCell(record.dimensions.width),
				},
				{
					title: 'Height',
					dataIndex: 'height',
					key: 'height',
					render: (_, record) => this.renderCustomCell(record.dimensions.height),
				},
				{
					title: 'Length',
					dataIndex: 'length',
					key: 'length',
					render: (_, record) => this.renderCustomCell(record.dimensions.length),
				},
				{
					title: 'Overhang',
					dataIndex: 'overhang',
					key: 'overhang',
					render: (_, record) => this.renderCustomCell(record.dimensions.truck),
				},
				{
					title: '# Tractor Axles',
					dataIndex: 'tractorAxles',
					key: 'tractorAxles',
					render: (_, record) => record.tractorAxles
				},
				{
					title: '# Trailer Axles',
					dataIndex: 'axles',
					key: 'axles',
					render: (_, record) => record.axles
				},
				{
					title: '# Stinger Axles',
					dataIndex: 'stingerAxles',
					key: 'stingerAxles',
					render: (_, record) => record.stingerAxles
				},
				{
					title: '# Jeep Axles',
					dataIndex: 'jeepAxles',
					key: 'jeepAxles',
					render: (_, record) => record.jeepAxles
				},
			]
		};
	}

	renderCustomCell(record) {
		return (
			record.feet && (
				<p>{record.feet} ft. {record.inches} inch.</p>
			)
		);
	}

	onLoad = () => {
		this.setState({
			load: true
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports') });
		}
	}

	componentDidMount() {
		const db = firebase.firestore();
		const rigs = db.collection('rigReports').where('$key', '==', this.state.id);
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists && doc.data().archive !== true) {
					temp.push(doc.data());
				}
			});
			this.setState({
				report: temp[0],
				selectedDate: temp.date
			});
			this.getReports();
			this.getRigDetails();
			this.getRigUpLoadCount();
			this.getRigDownLoadCount();
			this.getRigDownNotes();
			this.getRigUPNotes();
			this.getUsers();
		});
	}

	getRigDetails() {
		const { report } = this.state;
		const databse = firebase.firestore();
		if (this.state.report.rigId) {
			const rigsRef = databse.collection('rigs').doc(report.rigId);
			rigsRef.get().then((doc) => {
				if (doc.exists) {
					const response = doc.data();
					this.setState({
						rig: response,
					});
				}
			});
		}
	}

	getReports() {
		const db = firebase.firestore();
		const reportsRef = db.collection('rigReports').doc(this.state.id);
		this.setState({ isLoading: true });
		reportsRef.get().then((querySnapshot) => {
			if (querySnapshot.exists) {
				const res = querySnapshot.data();
				this.setState({
					rig: res
				});
			}
		});
	}

	/* ---------------------------- RigDown Notes ---------------------------- */
	getRigDownNotes() {
		const { report } = this.state;
		const database = firebase.firestore();
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		if (!this.state.report.endDate) {
			const rigs = database.collection('Rig-Down').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('time', 'desc');
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists && doc.data().archive !== true) {
						temp.push(doc.data());
					}
				});
				temp.sort((a, b) => (a.time > b.time) ? -1 : 1);
				this.setState({
					rigdown_notes: temp
				});
			});
		} else {
			const rigs = database.collection('Rig-Down').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists && doc.data().archive !== true) {
						temp.push(doc.data());
						this.state.rigdown_dates.push(doc.data().date);
					}
				});
				temp.sort((a, b) => (a.time > b.time) ? -1 : 1);
				this.setState({
					rigdown_notes: temp,
					rigdown_dates: this.state.rigdown_dates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
				this.state.rigdown_dates.sort((a, b) => (a.time > b.time) ? -1 : 1);
				this.state.rigdown_notes.sort((a, b) => (a.time > b.time) ? -1 : 1);
			});
		}
	}

	/* ---------------------------- RigUp Notes ---------------------------- */
	getRigUPNotes() {
		const { report } = this.state;
		const database = firebase.firestore();
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		if (!this.state.report.endDate) {
			const rigs = database.collection('Rig-Up').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('time', 'desc');
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists && doc.data().archive !== true) {
						temp.push(doc.data());
					}
				});
				temp.sort((a, b) => (a.time > b.time) ? -1 : 1);
				this.setState({
					rigup_notes: temp,
					total_notes: this.state.rigdown_notes.concat(temp)
				});
			});
		} else {
			const rigs = database.collection('Rig-Up').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists && doc.data().archive !== true) {
						temp.push(doc.data());
						this.state.rigup_dates.push(doc.data().date);
					}
				});
				temp.sort((a, b) => (a.time > b.time) ? -1 : 1);
				this.setState({
					rigup_notes: temp,
					rigup_dates: this.state.rigup_dates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					}),
					total_notes: this.state.rigdown_notes.concat(temp)
				});
				this.state.rigup_dates.sort((a, b) => (a.time > b.time) ? -1 : 1);
				this.state.rigup_notes.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1);
				this.state.total_notes.sort((a, b) => (a.time > b.time) ? -1 : 1);
			});
		}
	}

	getRigUpLoadCount() {
		const database = firebase.firestore();
		const rigs = database.collection('Rig-Up').where('rigId', '==', this.state.report.rigId).orderBy('time', 'desc');
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					if (doc.data().loadcount === true) {
						temp.push(doc.data());
					}
				}
			});
			this.setState({
				up_loadcount_notes: temp
			});
		});
	}

	getRigDownLoadCount() {
		const database = firebase.firestore();
		const rigs = database.collection('Rig-Down').where('rigId', '==', this.state.report.rigId);
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					if (doc.data().loadcount === true) {
						temp.push(doc.data());
					}
				}
			});
			this.setState({
				down_loadcount_notes: temp
			});
			this.totalLoadCount();
		});
	}

	totalLoadCount() {
		const current = this;
		const total_array = current.state.up_loadcount_notes.concat(current.state.down_loadcount_notes);
		current.setState({
			loadcount_notes: total_array
		});

		current.state.loadcount_notes.forEach(note => {
			const dateString = moment(note.date).format('YYYY-MM-DD');
			const index = current.state.index.indexOf(note.date);
			if (index === -1) {
				current.state.index.push(note.date);
				const newIndex = current.state.index.indexOf(note.date);
				current.state.notes[newIndex] = {
					title: dateString,
					count: 0
				};
				current.state.notes[newIndex].count++;
			}
			else {
				if (!current.state.notes[index]) {
					current.state.notes[index] = {
						title: dateString,
						count: 0
					};
				}
				current.state.notes[index].count++;
			}
		});

		current.state.notes.sort((a, b) => (a.title > b.title) ? -1 : 1);
	}

	getUsers() {
		const db = firebase.firestore();
		const rigs = db.collection('users');
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					temp.push(doc.data());
				}
			});
			this.setState({
				users: temp
			});
		});
	}

	getUserDetails(uid) {
		const { users } = this.state;
		const userIndex = users.map(user => user.uid).indexOf(uid);
		if (userIndex !== -1) {
			// data.updatedByUser = users[userIndex].firstName + ' ' + users[userIndex].lastName;
			return (users[userIndex].firstName + ' ' + users[userIndex].lastName);
		}
		return null;
	}

	render() {
		const { rig, columns, total_notes } = this.state;
		if (rig && total_notes) {
			return (
				<div className='reportContent'>
					{/* <header className='report_header site_header'>
						<h1>Load Report Details</h1>
					</header> */}


					<div className="card-body">
						{rig &&
							<div className="output-table-header">
								<Row>
									<Col md={12} xl={12} span={24}>
										<p><span>Rig Name: </span> {rig.name}</p>
									</Col>
									<Col md={12} xl={12} span={24}>
										<p><span>Rig #: </span> {rig.number}</p>
									</Col>
									<Col md={12} xl={12} span={24}>
										<p><span>Job #: </span> {rig.jobNumber}</p>
									</Col>
									<Col md={12} xl={12} span={24}>
										<p><span>Load Count: </span> {rig.loadcount ? rig.loadcount : 0}</p>
									</Col>
								</Row>
							</div>
						}
						{total_notes.length > 0 &&
							<Table className='loadTable' columns={columns} dataSource={total_notes} rowKey={record => record.$key} />
						}


						{/* <footer className='load_footer site_footer'>
							<p>Copyright 2018 - all rights reserved by RigMoveApp</p>
						</footer> */}
					</div>
				</div>
			);
		} else {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(LoadReports));
