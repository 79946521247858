import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './error.css';
import 'antd/dist/antd.css';

class ErrorPage extends Component {
	render() {
		return (
			<div>
				<div className="card-body">
					<h4 className="card-title-error">You don&apos;t have permission of this page</h4>
				</div>
			</div>
		);
	}
}
export default withRouter(ErrorPage);