import React, { Component } from 'react';
import { Checkbox } from 'antd';
import './mobile-preview.css';

class MobileTask extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { details } = this.props;
		return (
			<>
				<div className="mobile-field">
					<div className="m-box-header checkbox">
						<p className="title">
							{details.required &&
								<span className="require-symbol">*</span>
							}
							<Checkbox className="custom-checkbox round">
								<span style={{ width: '100%', display: 'block', color: 'var(--tertiaryColor)' }}>{details.label}</span>
								<span className="subtitle">{details.description}</span>
							</Checkbox>
						</p>
					</div>
				</div>
			</>
		);
	}
}
export default MobileTask;