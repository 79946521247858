import React, { Component } from 'react';
import { Layout } from 'antd';
import dotenv from 'dotenv';
import RouterComponent from './route/index.js';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import './App.css';
dotenv.config();
const { Content } = Layout;

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: []
		};
	}

	render() {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Layout className="main-layout">
						<Content className="main-content">
							<RouterComponent />
						</Content>
					</Layout>
				</PersistGate>
			</Provider>
		);
	}
}


export default App;
