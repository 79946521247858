import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import SiderLayout from '../layout/Sider';
import HeaderBar from '../layout/Header';
import ErrorPage from '../pages/error-page';
// const { Header } = Layout;

const PrivateRoute = ({ component: Component, path, authed, isPermitted }) => {
	return (
		<Route
			path={path}
			exact={true}
			render={(props) => authed === true
				?
				<Layout>
					<HeaderBar />
					<Layout>
						<SiderLayout />
						<Layout className="main-dashboard-layout">
							{/* <Component /> */}
							{isPermitted === true ?
								<Component />
								:
								<ErrorPage />
							}
						</Layout>
					</Layout>
				</Layout>
				: <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
		/>
	);
};

export default (PrivateRoute);
