import React, { Component } from 'react';
import { Input } from 'antd';
import './mobile-preview.css';

class MobileNumber extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { details } = this.props;
		return (
			<>
				<div className="mobile-field">
					<div className="m-box-header">
						<p className="title">
							{details.required &&
								<span className="require-symbol">*</span>
							}
							{details.label}
							<span className="subtitle">{details.description}</span>
						</p>
					</div>
					<div className="m-box-content">
						<Input type="number" style={{ width: '100%', fontSize: '12px' }} placeholder="Type here" min={1} defaultValue="" />
					</div>
				</div>
			</>
		);
	}
}
export default MobileNumber;